<template>
	<div class="language-selector-container">
		<div class="language-selector" @click="activeSheet = true">
			<div class="language-name">{{ getString('change_language') }}</div>
			<div class="language-icon"><img :src="'https://img.qromo.io/img/lang/new/'+menusStore.currentLanguage.language_code+'.svg'"></div>
		</div>
		<AppBottomSheet id="bottom-sheet-language" :force-width="bottomSheetWidth" :active="activeSheet" @toggling-popup="toggleSheet">
			<div class="language-data-container" v-if="activeSheetDelayed">
				<div class="section-title"><div class="title">{{ getString('change_language') }}</div></div>
				<div class="loader-container" v-if="loadingLanguage">
					<AppLoader :color="'#'+menusStore.business.color" />
				</div>
				<div class="language-list-container" v-else>
					<div class="language-item" v-for="language in menusStore.languages" @click="clickLanguage(language)" :class="{selected: this.menusStore.currentLanguage.language_id === language.language_id}">
						<div class="language-name">{{ language.language_nativeName }}</div>
						<div class="language-icon"><img :src="'https://img.qromo.io/img/lang/new/'+language.language_code+'.svg'"></div>
					</div>
				</div>
			</div>
		</AppBottomSheet>
	</div>
</template>

<script>
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import {useEvsStore, useMenusStore, useTranslationStore} from "@/new-menu/stores";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import AppLoader from "@/shared/components/AppLoader.vue";
import {useLanguageStore, useNotificationStore} from "@/shared/stores";

export default {
	setup() {
		let menusStore = useMenusStore();
		let translationStore = useTranslationStore();
		let languageStore = useLanguageStore();
		let notificationsStore = useNotificationStore();
		return {menusStore, translationStore, languageStore, getString: languageStore.getString, notificationsStore}
	},
	name: "LanguageSelector",
	components: {AppLoader, AppBottomSheet},
	mixins: [WindowWidth],
	data() {
		return {
			activeSheet: false,
			loadingLanguage: false,
            activeSheetDelayed: false,
		}
	},
	computed: {
		bottomSheetWidth() {
			if(this.windowWidth < 550) {
				return "100%";
			} else if(this.windowWidth < 768) {
				return "550px";
			} else if(this.windowWidth < 1080) {
				return "720px";
			} else if(this.windowWidth < 1440) {
				return "960px";
			} else {
				return "1280px";
			}
		},
	},
	methods: {
		toggleSheet(isOpen) {
			if(!isOpen) {
				this.activeSheet = false;
                setTimeout(() => {
                    this.activeSheetDelayed = false;
                }, 500);
			} else {
                this.activeSheetDelayed = true;
            }
		},
		clickLanguage(language) {
			if(this.menusStore.currentLanguage.language_id === language.language_id) {
				return;
			}
			this.loadingLanguage = true;
			this.languageStore.setLang(language.language_code);

			if(language.language_id !== this.menusStore.businessLanguage.language_id) {
				useEvsStore().enqueueEvent('translate', language.language_code, "requested");
				this.translationStore.translateMenus(language, (result) => {
					this.menusStore.currentLanguageId = language.language_id;
					this.loadingLanguage = false;
					if(result === "OK") {
						this.activeSheet = false;
					} else if(result === "menu_too_big") {
						if(this.menusStore.business.type === 'other') {
							this.notificationsStore.showNotification('too_big_to_translate_catalog');
						} else {
							this.notificationsStore.showNotification('too_big_to_translate');
						}
						this.activeSheet = false;
					} else {
						this.notificationsStore.showNotification('generic_fail');
					}
				});
			} else {
				this.translationStore.resetTranslations();
				this.menusStore.currentLanguageId = language.language_id;
				this.loadingLanguage = false;
				this.activeSheet = false;
			}
		}
	}
}
</script>

<style lang="scss">
#bottom-sheet-language {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>

<style scoped lang="scss">
.language-selector-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: var(--padding-vertical) var(--padding-horizontal);

	.language-selector {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		padding: var(--padding-vertical) var(--padding-horizontal-s);
		cursor: pointer;
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner);

		.language-name {
			font-family: var(--font-family);
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			margin-right: 10px;
		}

		.language-icon {
			width: 20px;
			height: 20px;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				border-radius: var(--rounded-corner);
			}
		}
	}

}

.language-data-container {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal);

	.section-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		margin-bottom: var(--padding-vertical);

		.title {
			font-family: var(--font-family);
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			@include truncateString();
		}

	}

	.loader-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.language-list-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		--gap: 8px;
		gap: var(--gap);

		.language-item {
			--columns: 4;
			width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 4px 8px;
			cursor: pointer;
			background-color: var(--theme-bg2-color);
			border-radius: var(--rounded-corner);
			box-shadow: var(--theme-box-shadow-null);
			transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

			@media (max-width: 1080px) {
				--columns: 3;
			}

			@media (max-width: 768px) {
				--columns: 2;
			}

			@media (max-width: 400px) {
				--columns: 1;
			}

			.language-name {
				font-family: var(--font-family);
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				@include truncateString();
				transition: color 0.2s ease-in-out;
			}

			.language-icon {
				width: 16px;
				height: 16px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					transition: box-shadow 0.2s ease-in-out;
					box-shadow: 0 0 0 1px rgba(255, 255, 255, 0);
					border-radius: var(--rounded-corner);
				}
			}

			&:hover {
				box-shadow: var(--theme-box-shadow-light);
			}

			&.selected {
				background-color: var(--business-color);

				.language-name {
					color: var(--business-text-color);
				}

				.language-icon {
					img {
						box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
						border-radius: var(--rounded-corner);
					}
				}
			}
		}
	}

}
</style>