<template>
	<div ref="menu-container" class="menu-container" :style="menuStyle">
		<canvas ref="heat-map-canvas" style="position: absolute; top: 0; left: 0;z-index: 1000;"></canvas>
		<div class="menu-banner" :class="{noPicture: !menu.picture, heatmapOpacity: menusStore.heatMap}"><img v-if="menu.picture" :src="menu.picture"></div>
		<div class="menu-title" :class="{heatmapOpacity: menusStore.heatMap}">{{ menu.nameTranslation }}</div>
		<div class="items-counter-container" :class="{shown: selectedQuantity > 0}">
			<div class="items-counter">{{ selectedQuantity }}</div>
		</div>
	</div>
</template>

<script>
import {isTextColorDark} from "@/shared/helpers/ui";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {drawHeatMapClusters} from "@/new-menu/helpers/heatmap";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		return {cartStore, menusStore}
	},
	name: "SingleMenu",
	props: {
		menuId: {
			type: Number,
			required: true
		}
	},
	mixins: [WindowWidth],
	data() {
		return {
			heatMapBool: !!this.menusStore.heatMapData
		}
	},
	computed: {
		menu() {
			return this.menusStore.getMenuById(this.menuId);
		},
		menuStyle() {
			let menuC = this.menu.color ? "#"+this.menu.color : "#"+this.menusStore.business.color;
			let menuColor = "--menu-color: "+menuC+";";
			let menuColorAlpha20 = "--menu-color-alpha20: "+menuC+"33;";
			let menuColorAlpha40 = "--menu-color-alpha40: "+menuC+"66;";
			let menuColorAlpha80 = "--menu-color-alpha80: "+menuC+"cc;";
			let textColor = "--menu-text-color: "+(isTextColorDark(menuC) ? "#000000" : "#ffffff")+";";
			return menuColor + menuColorAlpha80 + menuColorAlpha40 + menuColorAlpha20 + textColor;
		},
		selectedQuantity() {
			return this.cartStore.getMenuQuantity(this.menu);
		},
	},
	methods: {
		drawHeatMap() {
			const container = this.$refs['menu-container'];
			const canvas = this.$refs['heat-map-canvas'];
			if(canvas) {
				const ctx = canvas.getContext('2d');

				const rect = container.getBoundingClientRect();
				canvas.width = rect.width;
				canvas.height = rect.height;

				let menuData = this.menusStore.heatMapData && this.menusStore.heatMapData.hasOwnProperty("m"+this.menuId) ? this.menusStore.heatMapData["m"+this.menuId] : null;
				if(menuData && this.menusStore.heatMapData.hasOwnProperty("maxPoints")) {
					drawHeatMapClusters(ctx, menuData, this.menusStore.heatMapData.maxPoints, rect);
				}
			}

		}
	},
	mounted() {
		if(this.menusStore.heatMapData) {
			this.drawHeatMap();
		}
	},
	watch: {
		'menusStore.heatMapData': {
			handler(newVal) {
				this.heatMapBool = !!newVal;
				if(newVal) {
					this.$nextTick(() => {
						this.drawHeatMap();
					});
				}
			},
			deep: true
		},
		windowWidth() {
			if(this.menusStore.heatMapData) {
				this.drawHeatMap();
			}
		}
	}
}
</script>

<style scoped lang="scss">
.menu-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: calc(50% - (var(--padding-vertical) / 2));
	height: 150px;
	border-radius: var(--rounded-corner);
	cursor: pointer;
	box-shadow: var(--theme-box-shadow);

	canvas {
		border-radius: var(--rounded-corner);
	}

	@media (max-width: 768px) {
		width: calc(50% - (var(--padding-vertical) / 2));
	}

	@media (max-width: 675px) {
		width: 100%;
	}

	.menu-banner {
		width: 100%;
		height: 100%;
		border-radius: var(--rounded-corner);
		background-color: var(--menu-color);

		&.noPicture {
			background-color: var(--menu-color-alpha40);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: var(--rounded-corner);
		}

	}

	.menu-title {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom-left-radius: var(--rounded-corner);
		border-bottom-right-radius: var(--rounded-corner);
		background: var(--menu-color-alpha80);
		background: linear-gradient(0deg, var(--menu-color) 0%, var(--menu-color-alpha20) 100%);
		color: var(--menu-text-color);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		font-size: var(--font-size-xl);
		line-height: var(--line-height-xl);
		@include truncateString();
	}

	.items-counter-container {
		position: absolute;
		bottom: calc(var(--padding-vertical) + 40px);
		right: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		transition: transform 0.25s ease;
		transform: scaleX(0);
		transform-origin: right;

		&.shown {
			transform: scaleX(1);
		}

		.items-counter {
			background-color: var(--menu-color);
			color: var(--menu-text-color);
			position: relative;
			z-index: 10;
			text-align: right;
			padding: 4px 8px 4px 0;
			min-width: 20px;
			font-size: var(--font-size);
			line-height: var(--line-height);
			font-weight: 700;

			&:before {
				content: '';
				height: 100%;
				display: block;
				background: inherit;
				position: absolute;
				top: 0;
				left: -7px;
				width: 16px;
				border-radius: var(--rounded-corner-s);
				transform: skewX(-10deg) translateX(0px);
				z-index: -1;
			}
		}

	}

}
</style>