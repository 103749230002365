<template>
	<a :href="'https://maps.google.com/?q='+encodeURIComponent(menusStore.business.address.address)" target="_blank">
		<div class="container-address">
			<div class="container-address-icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.89 16.559">
					<path d="M300.771,412.775a6.447,6.447,0,0,0-11.007,4.556c0,4.345,3.8,8.248,5.437,9.723a1.5,1.5,0,0,0,2.017,0c1.634-1.464,5.436-5.345,5.436-9.728A6.4,6.4,0,0,0,300.771,412.775Zm.636,4.556c0,3.88-3.512,7.446-5.022,8.8h0a.254.254,0,0,1-.349,0c-1.511-1.365-5.026-4.952-5.026-8.8a5.2,5.2,0,1,1,10.4,0Z" transform="translate(-289.764 -410.886)" class="fill"/>
					<path d="M299.122,415.647a3.307,3.307,0,0,0-2.89-.558,3.211,3.211,0,0,0-2.248,2.2,3.3,3.3,0,0,0,.974,3.414,3.263,3.263,0,0,0,2.173.816,3.558,3.558,0,0,0,1.4-.29,3.2,3.2,0,0,0,1.877-2.985A3.256,3.256,0,0,0,299.122,415.647Z" transform="translate(-290.682 -411.804)" class="fill"/>
				</svg>
			</div>
			<div class="container-address-text"><p>{{ addressName }}</p></div>
		</div>
	</a>
</template>

<script>
import {useLanguageStore} from "@/shared/stores";
import {useMenusStore} from "@/new-menu/stores";

export default {
	setup() {
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		return {menusStore, getString: languageStore.getString}
	},
	name: "MenuHeaderAddress",
	computed: {
		addressName() {
			let via = this.menusStore.business.address.via;
			let civico = this.menusStore.business.address.civico || "0";
			let comune = this.menusStore.business.address.comune;
			let provincia = this.menusStore.business.address.provincia_codice;
			if(!via || !comune || !provincia) {
				return this.menusStore.business.address.address;
			} else {
				return via + " " + civico + ", " + comune + " (" + provincia + ")";
			}
		}
	}
}
</script>

<style scoped lang="scss">
.container-address {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.container-address-icon {
		margin-right: 8px;

		svg {
			height: 16px;

			.fill {
				fill: var(--business-color);
			}
		}
	}

	.container-address-text {
		overflow: hidden;

		p {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			@include truncateString();
		}
	}
}
a {
	text-decoration: none;
	color: inherit;

	&:after {
		display: none;
	}
}
</style>