<template>
	<div class="couriers-list">
		<div class="loader-container" v-if="loading">
			<AppLoader :color="'#'+menusStore.business.color" :size="25" />
		</div>
		<div class="list-container" v-else>
			<div class="no-couriers" v-if="couriers.length === 0">
				<p>{{ noCouriersReason }}</p>
			</div>
			<div class="couriers" v-else>
				<div class="courier" v-for="courier in couriers" @click="selectCourier(courier)" :class="{selected: cartStore.courierRate && (cartStore.courierRate.rate_id === courier.rate_id)}">
					<div class="courier-info">
						<div class="courier-icon"><img :src="'https://img.qromo.io/img/public/couriers/'+courierIcon(courier)"></div>
						<div class="courier-service"><span class="courier-time">{{ courierTime(courier) }}</span><span class="service-info">{{ courierName(courier)+' '+courier.service }}</span></div>
					</div>
					<div class="courier-price">{{ courierPrice(courier) }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore} from "@/shared/stores";
import {formatDateOnly} from "@/shared/helpers/dates";
import AppLoader from "@/shared/components/AppLoader.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";

export default {
	components: {AppLoader},
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let evsStore = useEvsStore();
		return {cartStore, getString: languageStore.getString, readableEurValue: languageStore.readableEurValue, menusStore, formatDateOnly, evsStore}
	},
	name: "CouriersList",
	mixins: [WindowWidth],
	data() {
		return {
			loading: false,
			couriers: [],
			currentIssue: null,
			refreshDelayer: null,
			cartSnapshot: null,
		}
	},
	computed: {
		noCouriersReason() {
			if (this.cartStore.cart.length === 0) {
				return this.getString('couriers_no_cart');
			} else if (!this.cartStore.customerAddress) {
				return this.getString('couriers_not_loaded');
			} else if (!this.cartStore.customerName || !this.cartStore.customerPhone) {
				return this.getString('missing_order_data');
			} else {
				if(this.currentIssue === "account_no_data") {
					return this.getString('missing_order_data');
				} else if(["address_sender", "account_sender_no_data"].includes(this.currentIssue)) {
					return this.getString('missing_sender_data', [this.menusStore.business.name]);
				} else if(this.currentIssue === "address") {
					return this.getString('customer_address_incomplete');
				}
				return this.getString('no_couriers');
			}
		}
	},
	methods: {
		refreshCouriers() {

			this.loading = true;
			this.couriers = [];
			this.currentIssue = null;
			this.cartStore.courierRate = null;

			if(this.refreshDelayer) {
				clearTimeout(this.refreshDelayer);
			}

			this.refreshDelayer = setTimeout(() => {
				this.refreshDelayer = null;

				this.cartStore.getCouriers((success, result) => {
					this.loading = false;
					if(success) {
						this.couriers = this.preprocessCouriers(result);
					} else {
						if(result) {
							this.currentIssue = result;
						}
						this.couriers = [];
					}
				});
			}, 750);

		},
		preprocessCouriers(couriers) {
			let couriersList = [];
			let dhlServices = [];
			for(let i = 0; i < couriers.length; i++) {
				let courier = couriers[i];
				if(courier.carrier === "DHLExpress" || courier.carrier === "MyDHL") {
					dhlServices.push(courier);
					continue;
				}
				couriersList.push(courier);
			}

			//filter the 2 least expensive DHL services
			if(dhlServices.length > 0) {
				dhlServices.sort((a, b) => {
					return parseFloat(a.rate) - parseFloat(b.rate);
				});
				couriersList.push(dhlServices[0]);
				if(dhlServices.length > 1) {
					couriersList.push(dhlServices[1]);
				}
			}

			return couriersList;
		},
		courierTime(courier) {
			let deliveryDaysString = this.getString("days",[courier.delivery_days]);
			if(courier.delivery_days === 1 || courier.delivery_days === "1") {
				deliveryDaysString = this.getString("day",[courier.delivery_days]);
			} else if(courier.delivery_days === 0 || courier.delivery_days === "0" || courier.delivery_days === "0-0") {
				deliveryDaysString = this.getString("same_day");
			}
			//Exception for Stuart (ShippyPro fault)
			if(courier.carrier === "Stuart") {
				deliveryDaysString = this.getString("same_day");
			}
			return deliveryDaysString;
		},
		courierIcon(courier) {
			let iconCourier = "generic.png";
			if(courier.carrier === "DHLExpress" || courier.carrier === "MyDHL") {
				iconCourier = "dhl.jpg";
			} else if(courier.carrier === "Glovo" || courier.carrier === "Glovov2") {
				iconCourier = "glovo.jpg";
			} else if(courier.carrier === "Stuart") {
				iconCourier = "stuart.jpg";
			} else if(courier.carrier === "FEDEX") {
				iconCourier = "fedex.jpg";
			} else if(courier.carrier === "PosteDeliveryBusiness") {
				iconCourier = "poste.jpg";
			}
			return iconCourier;
		},
		courierName(courier) {
			let nameCourier = courier.carrier;
			if(courier.carrier === "DHLExpress") {
				nameCourier = "DHL";
			} else if(courier.carrier === "MyDHL") {
				nameCourier = "DHL";
			} else if(courier.carrier === "Glovo") {
				nameCourier = "Glovo";
			} else if(courier.carrier === "Glovov2") {
				nameCourier = "Glovo";
			} else if(courier.carrier === "Stuart") {
				nameCourier = "Stuart";
			} else if(courier.carrier === "FEDEX") {
				nameCourier = "FedEx";
			} else if(courier.carrier === "PosteDeliveryBusiness") {
				nameCourier = "Poste Italiane";
			}
			return nameCourier;
		},
		courierPrice(courier) {
			if(this.menusStore.business.options.delivery_courier_free_shipping !== null && this.cartStore.totalItems >= this.menusStore.business.options.delivery_courier_free_shipping) {
				return this.getString('free');
			} else {
				return this.readableEurValue(parseFloat(courier.rate)*1.22);
			}
		},
		selectCourier(courier) {
			this.cartStore.courierRate = courier;
			this.evsStore.enqueueEvent("click", "select-courier", courier.rate_id);
		},
		getCartSnapshot(cart) {
			return JSON.stringify(this.cartStore.cart.map(item => {
				return {
					id: item.id,
					quantity: item.quantity,
				}
			}));
		}
	},
	mounted() {
		setTimeout(() => {
			if(this.cartStore.cart && this.cartStore.cart.length > 0 && this.cartStore.customerAddress && this.cartStore.customerName && this.cartStore.customerPhone && this.cartStore.openCart) {
				this.refreshCouriers();
			}
		}, 300);
	},
	beforeUnmount() {
		if(this.refreshDelayer) {
			clearTimeout(this.refreshDelayer);
		}
	},
	watch: {
		'cartStore.cart': {
			handler: function (val) {
				let newSnapshot = this.getCartSnapshot(val);
				if (val && val.length > 0 && this.cartStore.customerAddress && this.cartStore.customerName && this.cartStore.customerPhone && this.cartStore.openCart) {
					if(newSnapshot !== this.cartSnapshot) {
						this.refreshCouriers();
					}
				}
				this.cartSnapshot = newSnapshot;
			},
			deep: true,
		},
		'cartStore.customerAddress': {
			handler: function (val) {
				if (val && this.cartStore.cart && this.cartStore.cart.length > 0 && this.cartStore.customerName && this.cartStore.customerPhone) {
					this.refreshCouriers();
				}
			},
			deep: true
		},
		'cartStore.customerName': {
			handler: function (val, oldVal) {
				if (val && !oldVal) {
					if(this.cartStore.cart && this.cartStore.cart.length > 0 && this.cartStore.customerAddress && this.cartStore.customerPhone) {
						this.refreshCouriers();
					}
				} else if (!val) {
					this.couriers = [];
				}
			},
			deep: true
		},
		'cartStore.customerPhone': {
			handler: function (val, oldVal) {
				if (val && !oldVal) {
					if(this.cartStore.cart && this.cartStore.cart.length > 0 && this.cartStore.customerAddress && this.cartStore.customerName) {
						this.refreshCouriers();
					}
				} else if (!val) {
					this.couriers = [];
				}
			},
			deep: true
		},
	},
}
</script>

<style scoped lang="scss">
.couriers-list {
	display: flex;
	flex-direction: column;
	width: 100%;

	.loader-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: var(--padding-vertical) 0;
	}

	.list-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: var(--padding-vertical) 0;

		.no-couriers {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt1-color);
			}
		}

		.couriers {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px;

			.courier {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 6px var(--padding-horizontal-s);
				background-color: var(--theme-bg2-color);
				border-radius: var(--rounded-corner-s);
				box-shadow: var(--theme-box-shadow-light);
				cursor: pointer;
				transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
				border: 2px solid transparent;

				&:active {
					box-shadow: var(--theme-box-shadow-null);
				}

				&.selected {
					border: 2px solid var(--business-color);
				}

				.courier-info {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					overflow: hidden;

					.courier-icon {
						width: 28px;
						height: 28px;
						margin-right: 8px;
						border-radius: var(--rounded-corner-s);
						flex-shrink: 0;

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
							border-radius: var(--rounded-corner-s);
						}
					}

					.courier-service {
						padding-right: 8px;
						display: flex;
						flex-direction: column;
						overflow: hidden;

						.courier-time {
							font-size: var(--font-size-s);
							line-height: var(--line-height-s);
							color: var(--theme-txt1-color);
							@include truncateString();
							font-weight: 500;
						}

						.service-info {
							font-size: var(--font-size-s);
							line-height: var(--line-height-s);
							color: var(--theme-txt2-color);
							@include truncateString();
						}
					}
				}

				.courier-price {
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt1-color);
					flex-shrink: 0;
				}
			}
		}
	}
}
</style>