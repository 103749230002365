<template>
	<div class="container-cookies" v-if="menusStore.acceptCookies === null && menusStore.forceMenuId === null" :class="{menuOnly: menusStore.orderMode === 'none'}">
		<div class="row-cookies">
			<div class="container-image-cookies">
				<img src="https://img.qromo.io/img/icon-cookies.svg" alt="icon cookies">
			</div>
			<div class="content-cookies">
				<div class="row-content-cookies">
					<p>{{ getString("use_cookies_title") }}</p>
					<AppDropdown :collapsed="collapsedCookies" >
						<p class="cookies-explain"><span>{{ getString('click_here_to_view')+" " }}</span><a target="_blank" :href="urlCookiePolicy">{{ getString('the_cookie_policy')+". " }}</a><span>{{ getString('cookie_info') }}</span></p>
					</AppDropdown>
				</div>
				<div class="container-arrow-search" @click="collapsedCookies = !collapsedCookies"><AppChevronIcon :direction="collapsedCookies ? 'down' : 'up'" :color="'#'+menusStore.business.color" /></div>
			</div>
			<div class="inputs-cookies">
				<div id="container-cta-cards" class="container-button container-button-cta  container-button-cta-cookies  container-button-cta-cookies-negative">
					<div class="input-button" @click="chooseCookie(false)">{{ getString('no') }}</div>
				</div>
				<div id="container-cta-cards" class="container-button container-button-cta container-button-cta-cookies">
					<div class="input-button" @click="chooseCookie(true)">{{ getString('ok') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {useLanguageStore, useSvgStore} from "@/shared/stores";
import AppChevronIcon from "@/shared/components/AppChevronIcon.vue";
import {useMenusStore} from "@/new-menu/stores";
import AppDropdown from "@/shared/components/AppDropdown.vue";

export default {
	setup() {
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		return {svgStore, menusStore, getString: languageStore.getString}
	},
	components: {AppDropdown, AppChevronIcon},
	name: "BannerCookie",
	data() {
		return {
			collapsedCookies: true,
		}
	},
	computed: {
		urlCookiePolicy() {
			return URL_HTML+"/cookies/";
		}
	},
	methods: {
		chooseCookie(accept) {
			this.menusStore.acceptCookies = accept;
			this.menusStore.saveData();

			if(typeof zaraz !== "undefined") {
				if(accept) {
					zaraz.consent.setAll(accept);
					zaraz.consent.sendQueuedEvents();
				} else {
					zaraz.consent.setAll(!accept);
				}
			}
		}
	},
	created() {

		document.addEventListener("zarazConsentAPIReady", () => {
			zaraz.consent.setAll(true);
		});

	}
}
</script>

<style scoped lang="scss">
.container-cookies {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 40px;
	right: calc((100% / 3) + (var(--padding-horizontal) * 2));
	width: calc(65% - 65px);
	background-color: var(--theme-bg1-color);
	border-radius: var(--rounded-corner);
	z-index: 999999;
	box-shadow: var(--theme-box-shadow);
	padding: 6px 20px;
	box-sizing: border-box;
	max-width: 650px;
	margin-left: 0;
	opacity: 1;
	transition: opacity 0.5s ease, transform 0.3s ease;
	transform: translateX(0);

	&.menuOnly {
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	@media (max-width: 1024px) {
		left: unset;
		right: calc(var(--padding-horizontal) * 2);
		width: calc(100% - (var(--padding-horizontal) * 4));
	}

	@media (max-width: 450px) {
		padding: 6px 12px;
	}

	&.invisible {
		opacity: 0;
		transform: translateX(50px);
		visibility: hidden;
	}

	.row-cookies {
		display: flex;
		justify-content: space-between;

		.container-image-cookies {
			position: absolute;
			top: -25px;
			left: -25px;
			width: 46px;
			height: 46px;
			flex-shrink: 0;

			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}

			@media (max-width: 450px) {
				top: -24px;
				left: -15px;
				width: 35px;
				height: 35px;
			}
		}

		.content-cookies {
			display: flex;
			align-items: center;
			flex-grow: 1;

			.row-content-cookies {
				display: flex;
				flex-direction: column;
				flex-grow: 1;

				&:deep(.dropdown-content) {
					background-color: transparent;
					padding: 0;
					margin-top: 4px;
				}

			}

			p {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				padding-right: 20px;
				margin: 0;
				color: var(--theme-txt2-color);

			}

			.container-arrow-search {
				width: 35px;
				min-width: 35px;
				height: 35px;
				border-radius: var(--rounded-corner);
				transition: transform .15s ease;
				cursor: pointer;
				background-color: var(--theme-bg2-color);
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					height: 16px;
				}
			}
		}

		.inputs-cookies {
			display: flex;
			box-sizing: border-box;
			align-items: center;
			justify-content: center;
			gap: 8px;
			margin-left: 8px;

			@media (max-width: 420px) {
				flex-direction: column;
				gap: 2px;
			}

			.container-button-cta-cookies {
				cursor: pointer;

				.input-button {
					padding: 7px 15px;
					font-size: var(--font-size);
					line-height: var(--line-height);
					background-color: var(--business-color);
					color: var(--business-text-color);
					border-radius: var(--rounded-corner-s);
					box-shadow: var(--theme-box-shadow-xlight);

					@media (max-width: 420px) {
						padding: 3px 14px;
					}
				}

				&-negative .input-button {
					background-color: transparent !important;
					color: var(--business-color) !important;
					box-shadow: none;

					@media (max-width: 420px) {
						order: 2;
					}
				}

			}
		}
	}
}
.cookies-explain {

	span,a {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		color: var(--theme-txt2-color);
	}
}
</style>