<template>
	<div class="banner-spacer" :class="{hiddenBack}"></div>
	<div class="menu-back-container menu-back-container-block">
		<div class="first-row-back">
			<div class="multi-back-menu-container" v-if="menusStore.menusAvailable.length > 1 || (menusStore.payBillAvailable && actionsStore.payBillAmount > 0)">
				<div class="multi-menu-back-button" v-if="menusStore.menusAvailable.length > 1" :class="{hidingBack, hiddenBack}" @click="backToMultimenu"><span><AppChevronIcon direction="left" color="var(--business-text-color)" :size="10" /></span><p>{{ menusStore.selectedMenu ? menusStore.selectedMenu.nameTranslation : getString('back') }}</p></div>
				<div v-else></div>
				<div class="custom-button" v-if="menusStore.payBillAvailable && actionsStore.payBillAmount > 0" @click="cartStore.payTableSheetActive = true">
					<div class="custom-button-inner">
						<p>{{ getString('pay_the_bill') }}</p>
						<div class="icon-container" v-html="getIcon('iconPayments')"></div>
					</div>
				</div>
			</div>
			<div v-else></div>
		</div>
	</div>
	<div class="menu-back-container menu-back-container-absolute" :class="{hidingBack, hiddenBack, visible: currentScroll > ($parent.minScroll+$parent.maxScroll)/3, menuOnly: menusStore.orderMode === 'none'}">
		<div class="menu-back-container-background">
			<div class="first-row-back">
				<div class="multi-menu-back-button" @click="backToMultimenu" v-if="menusStore.menusAvailable.length > 1"><span><AppChevronIcon direction="left" color="var(--business-text-color)" :size="10" /></span><p>{{ menusStore.selectedMenu ? menusStore.selectedMenu.nameTranslation : getString('back') }}</p></div>
				<div v-else></div>
				<div class="options-container">
					<div class="search-container" v-if="menusStore.business.options.enabled_search" :class="{open: searchOpen, visible: currentScroll > $parent.minScroll}">
						<div class="icon-search icon-search-open" v-html="getIcon('iconSearch')" @click="toggleSearch"></div>
						<div class="icon-search icon-search-close" v-html="getIcon('iconX')" @click="toggleSearch"></div>
						<MenuTextInput ref="menu-text-input" :placeholder="getString('search')" @changed-text="changeSearch" @pressed-enter="goToNextSearchResult(null)" :value="menusStore.search ? menusStore.search : ''" />
						<div class="results-num" :class="{visible: menusStore.searchResults.length > 0}">{{ resultsString }}</div>
					</div>
					<div class="read-mode-container" :class="{active: menusStore.readMode}" v-if="menusStore.business.options.read_mode">
						<div class="icon-read-mode" v-html="getIcon('iconGlasses')" @click="menusStore.toggleReadMode()"></div>
					</div>
				</div>
			</div>
			<div class="second-row-wizard" :class="{visible: true}" v-if="menusStore.menuHasWizard">
				<div class="wizard">
					<div class="wizard-scroll">
						<div :ref="'wizard-cat-'+category.menu_category_id" class="wizard-item" v-for="category in menusStore.selectedMenu.categories" @click="clickWizard(category)">{{ category.nameTranslation }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="third-row-out-wizard">
			<div class="custom-button" v-if="menusStore.payBillAvailable && actionsStore.payBillAmount > 0" @click="cartStore.payTableSheetActive = true">
				<div class="custom-button-inner">
					<p>{{ getString('pay_the_bill') }}</p>
					<div class="icon-container" v-html="getIcon('iconPayments')"></div>
				</div>
				<div class="button-expander" :class="{collapsed: payBillAmountDelta !== 0}">
					<div class="line"></div>
					<div class="button-expander-inner">
						<p>{{ getString('amount_changed') }}</p>
						<div v-html="getIcon('iconSmallArrow')" class="arrow-icon" :class="{up: payBillAmountDelta > 0, disappearing: payBillAmountDelta === 0}"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import AppChevronIcon from "@/shared/components/AppChevronIcon.vue";
import {useActionsStore, useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore, useSvgStore} from "@/shared/stores";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let svgStore = useSvgStore();
		let languageStore = useLanguageStore();
		let evsStore = useEvsStore();
		const actionsStore = useActionsStore();
		return {cartStore, menusStore, evsStore, getIcon: svgStore.get, getString: languageStore.getString, readableEurValue: languageStore.readableEurValue, actionsStore}
	},
	name: "MenuTopBar",
	components: {AppChevronIcon, MenuTextInput},
	data() {
		return {
			searchOpen: false,
			currentScroll: 0,
			hiddenBack: this.menusStore.selectedMenuId === null,
			hidingBack: this.menusStore.selectedMenuId === null,
			freezeWizard: false,
			payBillAmountDelta: 0
		};
	},
	computed: {
		resultsString() {
			return (this.menusStore.searchResultIndex+1)+"/"+this.menusStore.searchResultsCurrent.length;
		},
	},
	methods: {
		clickWizard(category) {
			this.menusStore.scrollToCategory(category, false);
			this.freezeWizard = true;
			setTimeout(() => {
				this.freezeWizard = false;
				this.updateWizard();
			}, 600);
		},
		backToMultimenu() {
			this.$router.push("/");
			this.menusStore.backToMultimenu();
		},
		toggleSearch() {
			this.searchOpen = !this.searchOpen;
			if(this.searchOpen) {
				setTimeout(() => {
					this.$refs["menu-text-input"].focus();
				}, 100);
			} else {
				this.menusStore.updateSearch(null);
			}
		},
		changeSearch(text) {
			if(text.length > 0) {
				this.menusStore.updateSearch(text);
				if(!this.searchOpen) {
					this.toggleSearch();
				}
				this.goToNextSearchResult(0);
			} else {
				this.menusStore.updateSearch(null);
			}
		},
		goToNextSearchResult(forceIndex = null) {
			if(!this.menusStore.search || this.menusStore.searchResultsCurrent.length === 0) {
				return;
			}
			let nextResult = null;
			let nextIndex = null;
			if(forceIndex !== null) {
				nextResult = this.menusStore.searchResultsCurrent.length > forceIndex ? this.menusStore.searchResultsCurrent[forceIndex] : null;
				nextIndex = forceIndex;
			} else {
				let searchResultsLength = this.menusStore.searchResultsCurrent.length;
				let currentIndex = this.menusStore.searchResultIndex;
				nextIndex = (currentIndex + 1) % searchResultsLength;
				nextResult = this.menusStore.searchResultsCurrent[nextIndex];
			}
			if(!nextResult) {
				return;
			}
			let el = null;
			if(nextResult.type === "category") {
				el = document.getElementById("cat-head-"+nextResult.category.menu_category_id);
			} else if(nextResult.type === "item") {
				el = document.getElementById("item-"+nextResult.item.menu_item_id);
			}
			if(el) {
				el.scrollIntoView({behavior: "smooth", block: "start"});
			}
			this.menusStore.searchResultIndex = nextIndex;
		},
		updateWizard() {
			if(this.freezeWizard || !this.menusStore.selectedMenu) {
				return;
			}
			//Find first category visible vertically in the viewport and scroll smoothly the wizard horizontally to it
			let found = false;
			for(let i = 0; i < this.menusStore.selectedMenu.categories.length; i++) {
				let cat = this.menusStore.selectedMenu.categories[i];
				let wizardCatEl = this.$refs["wizard-cat-"+cat.menu_category_id];
				if(found) {
					if(wizardCatEl && wizardCatEl.length > 0) {
						wizardCatEl[0].classList.remove("wizard-item-selected");
					}
					continue;
				}
				if(i < this.menusStore.selectedMenu.categories.length - 1) {
					let nextIndex = i + 1;
					let nextCat = this.menusStore.selectedMenu.categories[nextIndex];
					let el = document.getElementById("cat-head-"+nextCat.menu_category_id);
					if(el && wizardCatEl && wizardCatEl.length > 0) {
						let rect = el.getBoundingClientRect();
						if(rect.top >= 110) {
							wizardCatEl[0].scrollIntoView({behavior: "smooth", inline: "center"});
							wizardCatEl[0].classList.add("wizard-item-selected");
							found = true;
						} else {
							wizardCatEl[0].classList.remove("wizard-item-selected");
						}
					}
				} else {
					if(wizardCatEl && wizardCatEl.length > 0) {
						wizardCatEl[0].classList.add("wizard-item-selected");
						wizardCatEl[0].scrollIntoView({behavior: "smooth", inline: "center"});
					}
				}

			}
		},
		handleScroll() {
			this.currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			this.updateWizard();
		},
		showBackButton() {
			this.hiddenBack = false;
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.hidingBack = false;
					this.hiddenBack = false;
				});
			});
		},
		hideBackButton() {
			this.hidingBack = true;
			setTimeout(() => {
				this.hiddenBack = true;
				this.hidingBack = true;
			}, 530);
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	created() {
		let chosenMenuId = null;
		if(this.menusStore.menusAvailable.length === 1) {
			chosenMenuId = this.menusStore.menusAvailable[0].menu_id;
			this.menusStore.goToMenu(chosenMenuId, true);
			this.showBackButton();
		} else {
			if(this.menusStore.forceMenuId) {
				chosenMenuId = this.menusStore.forceMenuId;
			} else if(this.$route.params.menuId) {
				chosenMenuId = this.$route.params.menuId;
			}
			if(chosenMenuId) {
				let menu = this.menusStore.menusAvailable.find(menu => menu.slug === chosenMenuId);
				menu = menu ? menu : this.menusStore.menusAvailable.find(menu => menu.menu_id === Number(chosenMenuId));
				if(menu) {
					this.menusStore.goToMenu(menu.menu_id, true);
					this.showBackButton();
					if(this.$route.params.itemSlug) {
						let itemSlug = this.menusStore.getItemFromSlug(this.$route.params.itemSlug);
						this.menusStore.selectedItemId = itemSlug ? itemSlug.menu_item_id : null;
					}
				} else {
					this.$router.push("/");
				}
			}
		}

		setTimeout(() => {
			this.evsStore.updatePageVt(chosenMenuId);
		}, 1500);

	},
	watch: {
		$route(to, from) {
			if (to.params.menuId) {
				if(to.params.menuId !== from.params.menuId) {
					this.evsStore.updatePageVt(to.params.menuId);
				}
				this.showBackButton();
			} else {
				if(to.params.menuId !== from.params.menuId) {
					this.evsStore.updatePageVt(null);
				}
				this.hideBackButton();
			}
		},
		'actionsStore.payBillAmount': function (newVal, oldVal) {
			if(oldVal === 0) return;
			this.payBillAmountDelta = newVal - oldVal;
			setTimeout(() => {
				this.payBillAmountDelta = 0;
			}, 3000);
		}
	}
}
</script>

<style scoped lang="scss">
.banner-spacer {
	width: 100%;
	height: calc(var(--image-size) - 40px - 57px); // 40px = corners svg height, 57px = menu-back-container height
	flex-shrink: 0;
	background-color: transparent;

}

.menu-back-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	opacity: 1;
	transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
	margin-left: 0;
	min-height: 57px;
	pointer-events: none;

	&-block {

		.first-row-back {
			.multi-back-menu-container {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;

				.multi-menu-back-button {
					opacity: var(--opacity-animated-reverse);
					pointer-events: all;
					transition: opacity .3s ease-in-out;

					&.hiddenBack {
						visibility: hidden;
					}

					&.hidingBack {
						opacity: 0;
					}
				}

			}
		}

		&.hidingBack {
			margin-left: 20px;
		}

	}

	&-absolute {
		position: fixed;
		top: -150px;
		z-index: 200;
		transition: opacity .3s ease-in-out, top .2s ease-in-out;
		width: calc((100% / 3) * 2);
		right: 33.3333333%;
		max-width: 1080px;
		left: unset;

		@media (max-width: 1024px) {
			width: 100%;
			right: 0;
		}

		&.menuOnly {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		&.visible {
			top: 0;
			transition: opacity .3s ease-in-out, top .4s ease-in-out;
		}

		.first-row-back {
			.multi-menu-back-button {
				opacity: var(--opacity-animated);
			}
		}
	}

	.menu-back-container-background {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		background-color: var(--theme-bg1-color-animated);
		backdrop-filter: blur(var(--blur-animated));
		-webkit-backdrop-filter: blur(var(--blur-animated));
		box-shadow: 4px 4px 10px -3px var(--theme-dark-animated);
		border-bottom-left-radius: var(--rounded-corner);
		border-bottom-right-radius: var(--rounded-corner);
		width: 100%;
		pointer-events: all;
	}

	.first-row-back {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 8px var(--padding-horizontal);

		.multi-menu-back-button {
			padding: 18px 12px;
			background-color: var(--business-color-alpha80);
			border-radius: var(--rounded-corner-s);
			cursor: pointer;
			height: 36px;
			display: flex;
			align-items: center;

			span {
				margin-right: 6px;
			}

			p {
				color: var(--business-text-color);
				font-size: var(--font-size);
				line-height: var(--line-height);
				max-width: 140px;
				@include truncateString();

				@media (max-width: 600px) {
					max-width: 100px;
				}

				@media (max-width: 360px) {
					max-width: 60px;
				}
			}
		}

		.options-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;

			.search-container {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				background: var(--theme-bg2-color);
				border-radius: var(--rounded-corner);
				opacity: var(--opacity-animated);
				pointer-events: none;

				.icon-search {
					display: flex;
					align-items: center;
					opacity: 0;
					width: 0;
					height: 0;
					padding: 0;
					transition: width 0.3s linear, height 0.3s linear, padding 0.3s linear, opacity 0.3s linear;

					&:deep(svg) {
						width: 100%;
						height: 100%;

						.fill {
							fill: var(--business-color);
						}

						.stroke {
							stroke: var(--business-color);
						}

					}
				}

				.icon-search-open {
					width: 36px;
					height: 36px;
					padding: 8px 10px;
					opacity: 1;
				}

				.menu-text-input {

					&:deep(input) {
						width: 0;
						padding: 0;
						overflow: hidden;
						transition: width 0.2s linear, padding 0.2s linear;

						@media (max-width: 450px) {
							width: 100px !important;
						}

						@media (max-width: 360px) {
							width: 60px !important;
						}
					}

				}

				.results-num {
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--business-color);
					font-weight: 700;
					padding-right: 0;
					max-width: 0;
					overflow: hidden;
					transition: max-width 0.2s linear, padding 0.2s linear;
					text-align: right;
					@include truncateString();
				}

				&.visible {
					pointer-events: all;

					.icon-search {
						cursor: pointer;
					}
				}

				&.open {
					opacity: 1;
					pointer-events: all;

					.results-num {
						&.visible {
							max-width: 50px;
							padding-right: 10px;
						}
					}

					.icon-search {
						cursor: pointer;
					}

					.icon-search-close {
						padding: 6px 10px;
						height: 32px;
						width: 32px;
						opacity: 1;
					}

					.icon-search-open {
						width: 0;
						height: 0;
						padding: 0;
						opacity: 0;
					}

					.menu-text-input {

						&:deep(input) {
							width: 160px;
							padding-right: 10px;
						}

					}

				}

			}

			.read-mode-container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				background: var(--theme-bg2-color);
				border-radius: var(--rounded-corner);
				opacity: var(--opacity-animated);
				margin-left: 8px;
				transition: background-color 0.2s ease-in-out;

				.icon-read-mode {
					display: flex;
					align-items: center;
					width: 36px;
					height: 36px;
					padding: 8px 6px;
					cursor: pointer;

					&:deep(svg) {
						width: 100%;
						height: 100%;

						.fill {
							fill: var(--business-color);
						}

						.stroke {
							stroke: var(--business-color);
						}

					}

				}

				&.active {
					background: var(--business-color);
					.icon-read-mode {

						&:deep(svg) {
							.fill {
								fill: var(--business-text-color);
							}
							.stroke {
								stroke: var(--business-text-color);
							}
						}

					}
				}

			}
		}

	}

	&.hiddenBack {
		display: none;
	}

	&.hidingBack {
		opacity: 0;
	}

	.second-row-wizard {
		width: 100%;
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease-in-out;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		opacity: var(--opacity-animated);
		padding: 4px var(--padding-horizontal);
		padding-top: 0;

		&.visible {
			height: 40px;
		}

		.wizard {
			border-radius: var(--rounded-corner-s);
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			@include hideScrollbar();
			overflow-x: auto;

			.wizard-scroll {
				gap: 8px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.wizard-item {
					padding: 6px 8px;
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt2-color);
					background-color: var(--theme-bg1-alpha80-color);
					border: 1px solid var(--business-color);
					border-radius: var(--rounded-corner-s);
					max-width: 150px;
					@include truncateString();
					cursor: pointer;
					transition: background-color 0.2s ease, color 0.2s ease;

					&.wizard-item-selected {
						background-color: var(--business-color-alpha80);
						color: var(--business-text-color);
					}
				}

			}
		}

	}

	.third-row-out-wizard {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 8px var(--padding-horizontal);
		opacity: var(--opacity-animated);
		pointer-events: none;
	}

	.custom-button {
		display: flex;
		flex-direction: column;
		background-color: var(--business-color-alpha80);
		border-radius: var(--rounded-corner-s);
		padding: 8px 12px;
		cursor: pointer;
		transition: background-color 0.2s ease;
		pointer-events: all;

		.custom-button-inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}

		.button-expander {
			display: flex;
			flex-direction: column;
			width: 100%;
			transition: max-height 0.3s ease;
			max-height: 0;
			overflow: hidden;

			.line {
				margin-top: 4px;
				margin-bottom: 2px;
				height: 1px;
				width: 100%;
				background-color: var(--business-text-color);
				opacity: 0.4;
			}

			.button-expander-inner {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				p {
					font-size: var(--font-size-s);
					opacity: 0.8;
					margin-right: 8px;
				}

				.arrow-icon {
					width: 10px;
					opacity: 0.8;

					&.up {
						transform: rotate(180deg) translateY(-2px);
					}

					&:deep(svg) {
						width: 100%;
						height: 100%;

						.fill {
							fill: var(--business-text-color);
						}

						.stroke {
							stroke: var(--business-text-color);
						}

					}

					&.disappearing {
						opacity: 0;
					}
				}
			}

			&.collapsed {
				max-height: 80px;
			}
		}

		p {
			color: var(--business-text-color);
			font-size: var(--font-size);
			line-height: var(--line-height);
			margin-right: 20px;
		}

		.icon-container {
			width: 18px;
			height: 18px;
			&:deep(svg) {
				width: 100%;
				height: 100%;
				.fill {
					fill: var(--business-text-color);
				}
				.stroke {
					stroke: var(--business-text-color);
				}
			}
		}
	}

}
</style>