<template>
  <div class="menu-header" :class="{heatmapOpacity: menusStore.heatMap}">
	  <div class="logo-container" :class="{noLogo: !menusStore.business.logo}">
		  <img v-show="!!menusStore.business.logo" :src="menusStore.business.logo" :alt="getString('logo')">
	  </div>
	  <div class="header-row logo-container-spacer" :class="{noLogo: !menusStore.business.logo}"></div>
	  <div class="header-row container-title"><h1 class="title">{{ menusStore.business.name }}</h1></div>
	  <div class="header-row container-description" v-if="menusStore.business.description"><div class="description">{{ menusStore.business.descriptionTranslation }}</div></div>
	  <div class="header-row" v-if="menusStore.business.address"><MenuHeaderAddress /></div>
	  <div class="header-row" v-if="showRating"><MenuHeaderRating /></div>
	  <div class="header-line" v-if="shouldShowModeSwitch || showAskForAssistance || showAllergensButton"></div>
	  <div class="header-row allergens-calls-row">
		  <div v-if="showAskForAssistance" :title="getString('ask_for_assistance')" :class="{active: activeCall, 'full-width': !showAllergensButton}" @click="clickAskAssistance" class="container-allergens">
			  <p>{{ getString(activeCall ? 'incoming_assistance' : 'ask_for_assistance') }}</p>
			  <div class="filter-icon-container">
				  <div class="bell-icon" v-html="getIcon('iconBell')"></div>
			  </div>
		  </div>
		  <div v-if="showAllergensButton" :title="menusStore.business.options.allergens_filter ? getString('allergens_filter') : getString('allergens_info')" :class="{active: menusStore.allergensOpen, 'full-width': !showAskForAssistance}" class="container-allergens" @click="menusStore.allergensOpen = true">
			  <p>{{ menusStore.business.options.allergens_filter ? getString('allergens_filter') : getString('allergens_info') }}</p>
			  <div class="filter-icon-container" :class="{activeFilter: menusStore.allergensFilterNum > 0}">
				  <div class="filtered-number" v-show="menusStore.allergensFilterNum > 0">{{ menusStore.allergensFilterNum }}</div>
				  <div class="filter-icon" v-html="menusStore.business.options.allergens_filter ? getIcon('iconFilter') : getIcon('iconInfo')"></div>
			  </div>
		  </div>
	  </div>
	  <div class="header-row" v-if="shouldShowModeSwitch">
		  <MenuTabs :items="switchModeItems" @switched="switchOrderMode" :active-item="menusStore.forceTakeaway ? 1 : 0" />
	  </div>
  </div>
</template>

<script>
import MenuHeaderAddress from "@/new-menu/components/MenuHeaderAddress.vue";
import MenuHeaderRating from "@/new-menu/components/modals/MenuHeaderRating.vue";
import {useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore, useNotificationStore, useSvgStore} from "@/shared/stores";
import MenuTabs from "@/new-menu/components/generic/MenuTabs.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import API from "@/shared/helpers/API";

export default {
	setup() {
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let notificationStore = useNotificationStore();
		let evsStore = useEvsStore();
		return {getIcon: svgStore.get, menusStore, notificationStore, evsStore, getString: languageStore.getString}
	},
	name: "MenuHeader",
	data() {
		return {
			activeCall: false,
			loadingAssistance: false
		}
	},
	components: {MenuHeaderRating, MenuHeaderAddress, MenuTabs},
	mixins: [WindowWidth],
	computed: {
		showRating() {
			if(this.menusStore.business.average_rating === null || this.menusStore.business.average_rating <= 350) {
				return false;
			}
			return !this.menusStore.business.options.disable_feedbacks && !this.menusStore.business.options.private_feedbacks;
		},
		switchModeItems() {
			let deliveryWord = this.menusStore.business.type === "other" ? this.getString('delivery_other') : this.getString('delivery');
			let takeawayWord = this.menusStore.business.type === "other" ? this.getString('takeaway_other') : this.getString('takeaway');
			return [deliveryWord, takeawayWord];
		},
		shouldShowModeSwitch() {
			return this.menusStore.shouldShowModeSwitch && this.windowWidth <= 1024;
		},
		showAskForAssistance() {
			return this.menusStore.enabledRoomTableCalls;
		},
		showAllergensButton() {
			return this.menusStore.business.type !== 'other';
		}
	},
	methods: {
		switchOrderMode(index) {
			this.menusStore.forceTakeaway = index === 1;
			this.evsStore.enqueueEvent("click", this.menusStore.forceTakeaway ? "toggle-takeaway" : "toggle-delivery");
		},
		clickAskAssistance() {
			if(this.loadingAssistance) {
				return
			}
			this.loadingAssistance = true;
			let asking = !this.activeCall;
			this.activeCall = !this.activeCall;
			this.evsStore.enqueueEvent("click", asking ? "call-assistance" : "cancel-assistance");
			let qrVersion = this.menusStore.qrVersion;

			API.init()[asking ? 'askForAssistance' : 'cancelAssistance'](this.menusStore.business.business_id, this.menusStore.roomTableId, this.menusStore.roomId, qrVersion, resp=>{

				if(resp && resp?.result === 'OK') {
					if(asking) {
						this.notificationStore.showNotification("ask_for_assistance_info", [this.menusStore.business.name]);
					}
				} else {
					this.activeCall = !this.activeCall;
					let notificationName = resp && resp?.details && ['time', 'token'].includes(resp.details) ? "long_ago_assistance" : "generic_fail";
					this.notificationStore.showNotification(notificationName);
				}
				this.loadingAssistance = false;
			});
		}
	},
	watch: {
		"menusStore.fetchedRoomTableCallId"(newTableCallId) {
			this.activeCall = !!newTableCallId;
		}
	}
}
</script>

<style scoped lang="scss">
.menu-header {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--theme-bg2-color);
	padding: var(--padding-vertical) var(--padding-horizontal);
	border-bottom-left-radius: var(--rounded-corner);
	border-bottom-right-radius: var(--rounded-corner);
	box-shadow: 0px -10px 10px -6px rgba(0, 0, 0, 0.3);

	.logo-container {
		position: absolute;
		height: 44px;
		width: 44px;
		top: -24px;
		left: calc(var(--padding-horizontal) * 2);
		box-shadow: var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner-l);
		z-index: 10;

		&.noLogo {
			box-shadow: none;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: var(--rounded-corner-l);
		}
	}

	.header-row {
		padding-bottom: var(--padding-vertical);

		&:last-child {
			padding-bottom: 0;
		}
	}

	.header-line {
		width: 100%;
		height: 1px;
		background-color: var(--theme-txt2-color);
		margin-bottom: var(--padding-vertical);
		opacity: 0.3;
	}

	.logo-container-spacer {
		height: 0;
		padding-bottom: calc(var(--padding-vertical) * 1.5);

		&.noLogo {
			padding-bottom: var(--padding-vertical);
		}
	}

	.container-title .title {
		font-size: var(--font-size-xxxl);
		line-height: var(--line-height-xxxl);
		color: var(--theme-txt1-color);
		font-weight: 700;
		font-style: normal;
		@include truncateString(2);
	}

	.container-description .description {
		font-size: var(--font-size);
		line-height: var(--line-height);
		color: var(--theme-txt2-color);
	}

	.allergens-calls-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.container-allergens {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		background-color: var(--theme-bg1-color);
		width: calc(50% - var(--padding-horizontal) / 2);
		border-radius: var(--rounded-corner);
		padding: 8px 12px;
		transition: background 0.3s ease;

		&.full-width {
			width: 100%;
		}

		p {
			font-size: var(--font-size);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			@include truncateString(1);
			max-width: calc(100% - 25px);
			transition: color 0.3s ease;
		}

		@media (max-width: 600px) {
			padding: 8px;
			p {
				max-width: calc(100% - 20px);
				font-size: var(--font-size-s);
			}
		}
		@media (max-width: 400px) {
			padding: 8px 6px;
		}

		.filter-icon-container {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			border-radius: var(--rounded-corner);
			padding: 2px;
			transition: background 0.3s ease;

			.filtered-number {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--business-text-color);
				font-weight: 700;
				padding: 0 6px 0 4px;
			}

			.filter-icon, .bell-icon {
				display: flex;
				align-items: center;
				justify-content: center;

				&:deep(svg) {
					width: 20px;
					height: 20px;

					@media (max-width: 600px) {
						width: 20px;
						height: 20px;
					}

					@media (max-width: 400px) {
						width: 18px;
						height: 18px;
					}

					.fill {
						fill: var(--business-color);
					}

					.stroke {
						stroke: var(--business-color);
					}
				}
			}

			&.activeFilter {
				background: var(--business-color);
				padding: 4px 10px 4px 8px;

				.filter-icon {
					&:deep(svg) {
						width: 16px;

						.fill {
							fill: var(--business-text-color);
						}
						.stroke {
							stroke: var(--business-text-color);
						}
					}
				}

			}
		}

		&.active {
			background-color: var(--business-color);
			p { color: var(--business-text-color); }
			.filter-icon-container {
				.filtered-number {
					background-color: var(--theme-bg1-color);
				}
				.filter-icon, .bell-icon {
					&:deep(svg) {
						.fill {
							fill: var(--theme-bg1-color);
						}

						.stroke {
							stroke: var(--theme-bg1-color);
						}
					}
				}
				.bell-icon{
					animation: shakePulse .5s 2 ease-in-out;

					@keyframes shakePulse {
						0% { transform: scale(1) rotate(0deg); }
						20% { transform: scale(1.1) rotate(5deg); }
						40% { transform: scale(1.3) rotate(-15deg); }
						60% { transform: scale(1.1) rotate(5deg); }
						80% { transform: scale(1) rotate(-5deg); }
						100% { transform: scale(1) rotate(0deg); }
					}
				}
			}
		}
	}

}
</style>