<template>
  <div class="menu-body" ref="menu-body" id="menu-body">
	  <AppPagination no-navigation-buttons hide-dots hide-header change-height :pages="pages" ref="multi-menu-pagination" :page-index="initialPageIndex">
		  <template v-slot:menus="{item}">
			  <div class="menus-container">
				  <SingleMenu :menu-id="menu.menu_id" @click="ev => clickMenu(ev, menu)" v-for="menu in menusStore.menusAvailable" :ref="'single-menu-'+menu.menu_id" :key="menu.menu_id" v-if="menusStore.menusAvailable.length > 0" />
				  <div class="no-menus-container" v-else>
					  <div class="no-menus">
						  <div class="no-menus-title">{{ getString('no_menus_available') }}</div>
						  <div class="no-menus-image"></div>
					  </div>
				  </div>
			  </div>
		  </template>
		  <template v-slot:single-menu="{item}">
			  <div class="menu-categories" v-if="menusStore.selectedMenu && menusStore.selectedMenu.categories.length > 0">
				  <MenuCategory v-for="category in menusStore.selectedMenu.categories" :key="category.menu_category_id" :category-id="category.menu_category_id" :carousel="category.carousel" />
			  </div>
			  <div class="no-menus-container no-menus-container-categories" v-else>
				  <div class="no-menus">
					  <div class="no-menus-title">{{ getString('no_menus_available') }}</div>
					  <div class="no-menus-image"></div>
				  </div>
			  </div>
		  </template>
	  </AppPagination>
	  <MenuPopup
		  ref="popup-item-filtered-add"
		  :title="getString('product_filtered')"
		  :body-text="getString('product_filtered_confirm')"
		  :buttons="[{text: getString('no'), negative: true, callback: dontAddFilteredProduct}, {text: getString('yes'), callback: addFilteredProduct}]" />
	  <MenuPopup
		  ref="popup-category-filtered"
		  :title="getString('category_filtered')"
		  :body-text="getString('category_filtered_confirm')"
		  :buttons="[{text: getString('no'), negative: true, callback: dontRemoveFiltersCategory}, {text: getString('yes'), callback: disableCategoryAllergens}]" />
	  <MenuPopup
		  ref="popup-category-searched"
		  :title="getString('category_filtered')"
		  :body-text="getString('category_filtered_search_confirm')"
		  :buttons="[{text: getString('no'), negative: true, callback: dontRemoveSearchedCategory}, {text: getString('yes'), callback: disableCategorySearch}]" />
  </div>
</template>

<script>
import MenuCategory from "@/new-menu/components/MenuCategory.vue";
import AppPagination from "@/shared/components/AppPagination.vue";
import SingleMenu from "@/new-menu/components/SingleMenu.vue";
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore} from "@/shared/stores";
import MenuPopup from "@/new-menu/components/generic/MenuPopup.vue";

export default {
	setup() {
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let cartStore = useCartStore();
		let evsStore = useEvsStore();
		return {menusStore, getString: languageStore.getString, cartStore, evsStore};
	},
	name: "MenuBody",
	components: {SingleMenu, MenuCategory, AppPagination, MenuPopup},
	data() {
		return {
			pages: [
				{id: 'menus', slotName: 'menus'},
				{id: 'single', slotName: 'single-menu'},
			],
			initialPageIndex: this.menusStore.menusAvailable.length > 1 ? 0 : 1,
		}
	},
	methods: {
		clickMenu(ev, menu) {
			this.goToMenu(menu);
			this.clickEv(ev, menu.menu_id);
		},
		clickEv(event, menuId) {

			if(event && this.$refs['single-menu-' + menuId]) {
				let itemElement = this.$refs['single-menu-' + menuId][0].$el;
				let rect = itemElement.getBoundingClientRect();
				let x = event.clientX - rect.left;
				let y = event.clientY - rect.top;
				let xPercent = (x / rect.width) * 100;
				let yPercent = (y / rect.height) * 100;

				this.evsStore.enqueueEvent('click', 'menu', JSON.stringify({
					id: Number(menuId),
					x: Number(xPercent.toFixed(2)),
					y: Number(yPercent.toFixed(2)),
				}));
			}

		},
		goToMenu(menu) {
			let menuSlug = menu.slug ? menu.slug : menu.menu_id;
			this.$router.push("/" + menuSlug + "/");
		},
		addFilteredProduct() {
			let item = this.menusStore.getItemById(this.menusStore.filteredProductToAdd);
			this.cartStore.triggerItem(item, this.menusStore.getProductCategory(item), this.$router, this.$route);
			let itemAllergens = item.allergens.map(allergen => allergen.allergen_id);
			this.menusStore.allergens.forEach(allergen => {
				if(itemAllergens.includes(allergen.allergen_id)) {
					allergen.active = true;
				}
			});
			this.menusStore.saveAllergensFilter();
			this.$refs['popup-item-filtered-add'].toggleStatus(false);
			this.menusStore.filteredProductToAdd = null;
		},
		dontAddFilteredProduct() {
			this.$refs['popup-item-filtered-add'].toggleStatus(false);
			this.menusStore.filteredProductToAdd = null;
		},
		disableCategoryAllergens() {
			let category = this.menusStore.getCategoryById(this.menusStore.filteredCategoryToShow);
			let categoryAllergens = category.items.map(item => item.allergens.map(allergen => allergen.allergen_id)).flat();
			this.menusStore.allergens.forEach(allergen => {
				if(categoryAllergens.includes(allergen.allergen_id)) {
					allergen.active = true;
				}
			});
			this.menusStore.saveAllergensFilter();
			this.$refs['popup-category-filtered'].toggleStatus(false);
			this.menusStore.filteredCategoryToShow = null;
		},
		dontRemoveFiltersCategory() {
			this.$refs['popup-category-filtered'].toggleStatus(false);
			this.menusStore.filteredCategoryToShow = null;
		},
		dontRemoveSearchedCategory() {
			this.$refs['popup-category-searched'].toggleStatus(false);
			this.menusStore.searchedCategoryToShow = null;
		},
		disableCategorySearch() {
			this.$refs['popup-category-searched'].toggleStatus(false);
			this.menusStore.searchedCategoryToShow = null;
			this.menusStore.updateSearch(null);
		}
	},
	mounted() {
		if(this.$route.params.menuId) {
			this.$refs['multi-menu-pagination'].changeSlide(1, false);
		}
	},
	watch: {
		$route(to, from) {
			if(to.params.menuId === from.params.menuId) {
				return;
			}
			if(to.params.menuId) {
				this.$refs['multi-menu-pagination'].changeSlide(1);
				let menuSlug = to.params.menuId;
				let menu = this.menusStore.menusAvailable.find(menu => menu.slug === menuSlug);
				menu = menu ? menu : this.menusStore.menusAvailable.find(menu => menu.menu_id === Number(menuSlug));
				if(menu) {
					this.menusStore.goToMenu(menu.menu_id);
					if(to.params.itemSlug) {
						let itemSlug = this.menusStore.getItemFromSlug(to.params.itemSlug);
						this.menusStore.selectedItemId = itemSlug ? itemSlug.menu_item_id : null;
					} else {
						this.menusStore.selectedItemId = null;
					}
				}
			} else {
				this.$refs['multi-menu-pagination'].changeSlide(0);
				this.menusStore.backToMultimenu();
			}
		},
		"menusStore.filteredProductToAdd": {
			handler() {
				if(this.menusStore.filteredProductToAdd) {
					this.$refs['popup-item-filtered-add'].toggleStatus(true);
				}
			}
		},
		"menusStore.filteredCategoryToShow": {
			handler() {
				if(this.menusStore.filteredCategoryToShow) {
					this.$refs['popup-category-filtered'].toggleStatus(true);
				}
			}
		},
		"menusStore.searchedCategoryToShow": {
			handler() {
				if(this.menusStore.searchedCategoryToShow) {
					this.$refs['popup-category-searched'].toggleStatus(true);
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.menu-body {
	scroll-margin-top: 85px;

	.menus-container {
		display: flex;
		flex-wrap: wrap;
		gap: var(--padding-vertical);
		margin-top: var(--padding-vertical);
		padding: var(--padding-vertical) var(--padding-horizontal);
	}

	.no-menus-container {
		width: 100%;
		display: flex;
		align-items: center;

		&.no-menus-container-categories {
			margin-top: var(--padding-vertical);
		}

		.no-menus {
			height: 80px;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			border-radius: var(--rounded-corner);
			overflow: hidden;

			.no-menus-title {
				width: calc((100% / 3) * 2);
				height: 100%;
				display: flex;
				align-items: center;
				padding: var(--padding-vertical) var(--padding-horizontal);
				text-align: left;
				font-size: var(--font-size-l);
				line-height: var(--line-height-l);
				font-weight: 500;
				color: var(--business-color);
				background-color: var(--theme-bg2-color);
				position: relative;


				&:before {
					content: '';
					height: 100%;
					display: block;
					background: inherit;
					position: absolute;
					top: 0;
					right: -17px;
					width: 36px;
					border-radius: var(--rounded-corner);
					transform: skewX(-10deg) translateX(0px);
					z-index: -1;
				}
			}

			.no-menus-image {
				width: calc(100% / 3);
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--theme-bg2-alpha40-color);
			}
		}
	}

	.menu-categories {
		display: flex;
		flex-direction: column;
		padding: var(--padding-vertical) var(--padding-horizontal);

		.menu-category {
			margin-top: var(--padding-vertical);
		}
	}
}
</style>