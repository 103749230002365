import {createApp} from 'vue'
import {createPinia} from 'pinia'
import "core-js/stable";
import App from '@/new-menu/App.vue'
import router from '@/new-menu/router';
import { setupCalendar, DatePicker } from 'v-calendar';
import Vue3Sanitize from "vue-3-sanitize";

import '@/shared/global';
import '@/new-menu/global';
import '@/new-menu/assets/scss/main.scss';
import sharedMixin from '@/shared/mixins';
import 'v-calendar/style.css';

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.mixin(sharedMixin);
app.use(router);
app.use(Vue3Sanitize, {allowedTags: []});
app.use(setupCalendar, {});
app.component('DatePicker', DatePicker);
app.mount('#app');
