<template>
  	<div class="menu-text-input">
		<textarea
			ref="input"
			:placeholder="placeholder"
			:value="currentValue"
			@focus="emitFocus"
			@focusout="emitFocusout"
			@input="handleInput"
			@keyup.enter="handleEnter" >
		</textarea>
	</div>
</template>

<script>
export default {
	name: "MenuTextInput",
	props: {
		placeholder: {type: String, default: ""},
		value: {type: String, default: null},
	},
	data() {
		return {
			currentValue: this.value,
			changingTextTimeout: null,
		}
	},
	methods: {
		emitFocus(e) {
			this.$emit("focus", e, this.currentValue);
		},
		emitFocusout(e) {
			this.$emit("focusout-input", e, this.currentValue);
		},
		handleInput(e) {
			this.currentValue = e.target.value;
			this.$emit("changing-text", e.target.value);

			if(this.changingTextTimeout) {
				clearTimeout(this.changingTextTimeout);
			}
			this.changingTextTimeout = setTimeout(() => {
				this.$emit("changed-text", e.target.value);
				this.changingTextTimeout = null;
			}, 500);
		},
		handleEnter(e) {
			e.preventDefault();
			this.$emit("pressed-enter", this.currentValue);
		},
		focus() {
			if(this.$refs && this.$refs.input) {
				this.$refs.input.focus();
			}
		},
	},
	emits: ["changing-text", "changed-text", "pressed-enter", "focusout-input", "focus"],
	watch: {
		value(newValue) {
			this.currentValue = newValue;
		}
	}
}
</script>

<style scoped lang="scss">
.menu-text-input {
	display: flex;
	width: 100%;

	textarea {
		width: 100%;
		height: 100px;
		border: none;
		background: var(--theme-bg2-color);
		color: var(--theme-txt1-color);
		padding: 4px 10px;
		border-radius: var(--rounded-corner);
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		resize: none;
		@include smallScrollbar;
		user-select: auto;
		--webkit-user-select: auto;
		--moz-user-select: auto;
		--ms-user-select: auto;
		--o-user-select: auto;

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: var(--theme-txt2-alpha60-color);
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: var(--theme-txt2-alpha60-color);
			opacity: 1;
		}
	}

}
</style>