<template>

	<Teleport to="#popups">
		<div class="overlay-wrapper" :class="finalClass" @click.self="clickOverlay" :style="zIndex ? 'z-index: '+zIndex : ''" :id="id">
			<div class="popup-container">
				<h3 v-show="title">{{ title }}</h3>
				<div class="popup-body">
					<slot></slot>
					<p class="popup-body-text" v-show="bodyText">{{ bodyText }}</p>
				</div>
				<div class="popup-footer">
					<MenuButton
						v-for="(btn, index) in buttons"
						:title="btn.text"
						:disabled="!!btn.disabled"
						:outline="!!btn.negative"
						:key="index"
						@click="btnCallback(btn.callback)"
						class="button" />
				</div>
			</div>
		</div>
	</Teleport>

</template>

<script>
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";

export default {
	name: "MenuPopup",
	components: {MenuButton},
	emits: ["toggling-popup"],
	data() {
		return {
			active: false,
			xPos: 0,
			yPos: 0,
		}
	},
	props: {
		title: {type: String, default: null},
		bodyText: {type: String, default: null},
		buttons: {type: Array, default: [{text: "Ok"}], validator(value) {
			return !value.length || value.every(btn => btn.text.length > 0);
		}},
		zIndex: {type: Number, default: null},
		preventOverlayDismiss: {type: Boolean, default: false},
		id: {type: String, default: null},
	},
	computed: {
		finalClass() {
			return this.active ? '' : 'v-n';
		},
	},
	methods: {
		toggleStatus(newStatus = !this.active) {
			this.active = newStatus;
			let methodName = this.active ? 'addEventListener' : 'removeEventListener';
			this.setPagePosition();
			window[methodName]('scroll', this.preventDocumentScroll);
			this.$emit("toggling-popup", this.active);
		},
		btnCallback(callback = null) {
			if (callback) {
				callback();
			} else {
				this.toggleStatus();
			}
		},
		clickOverlay() {
			if (!this.preventOverlayDismiss) {
				this.toggleStatus(false);
			}
		},
		preventDocumentScroll() {
			window.scroll(this.xPos, this.yPos);
		},
		setPagePosition() {
			this.xPos = window.scrollX || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
			this.yPos = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
		},
	},
	created() {
		if(!document.getElementById("popups")) {
			let popups = document.createElement("div");
			popups.id = "popups";
			document.body.appendChild(popups);
		}
	}
}
</script>

<style lang="scss" scoped>
.overlay-wrapper {
	z-index: 1000;
	@include centered;
	position: fixed;
	top: 0;
	left: 0;
	width: calc(100vw - (100vw - 100%));
	height: 100vh;
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	visibility: visible;
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	.popup-container {
		display: flex;
		@include columnFlex;
		@include justifyFlex(between);
		@include alignFlex(center);
		width: 96%;
		max-width: 600px;
		border-radius: var(--rounded-corner);
		max-height: 90%;
		background-color: var(--theme-bg1-color);
		padding: 30px;
		transition: opacity .2s $transition .1s, transform .2s $transition .1s;
		opacity: 1;
		transform: translateY(0);

		.popup-body {
			width: 100%;
			overflow-y: auto;
			margin: 20px 0;
			@include smallScrollbar;
			display: flex;
			align-items: flex-start;
			justify-content: center;

			.popup-body-text {
				text-align: center;
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--theme-txt2-color);
			}
		}

		h3 {
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			text-align: center;
			color: var(--theme-txt1-color);
			font-weight: 700;
		}
	}

	&.v-n {
		visibility: hidden;
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);

		.popup-container {
			opacity: 0;
			transform: translateY(-100px);
			transition: opacity .2s $transition, transform .2s $transition;
		}
	}

	.popup-footer {
		display: flex;
		@include justifyFlex(center);
		width: 100%;

		.button:not(:nth-of-type(1)) {
			margin-left: 20px;
		}

		@media (max-width: 500px) {
			@include columnFlex;
			@include alignFlex(center);
			.button:not(:nth-of-type(1)) {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}


	@media (max-height: 320px) {

		.popup-container {
			min-height: unset;
		}

	}
}
</style>