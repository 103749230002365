<template>
	<div class="hour-picker">
		<div class="slots-container">
			<div class="single-slot" v-for="(slot, index) in slots" :key="index" v-if="slots.length > 0 && slots.length <= 8">
				<div class="slot-time" :class="{active: cartStore.notInPlaceOrderTime === slot.time, available: slot.available}" @click="clickSlot(slot)">{{ slot.time }}</div>
			</div>
			<div class="hour-row" v-for="(slotsHour, hourIndex) in slotsPerHour" :key="hourIndex" v-else-if="slots.length > 8 && slotsPerHour.length <= 6 && slotsPerHourDense">
				<div class="single-slot" v-for="(slot, index) in slotsHour" :key="index">
					<div class="slot-time" :class="{active: cartStore.notInPlaceOrderTime === slot.time, available: slot.available}" @click="clickSlot(slot)">{{ slot.time }}</div>
				</div>
			</div>
			<div class="bottom-sheet-chooser" v-else-if="slots.length > 8" :class="{timePicked: cartStore.notInPlaceOrderTime !== null}">
				<MenuButton outline :title="cartStore.notInPlaceOrderTime === null ? getString('choose_time') : getString('time_x', [cartStore.notInPlaceOrderTime])" @click="activeSheet = true" />
			</div>
			<div class="no-slots" v-else>
				<p>{{ getString('no_slots') }}</p>
			</div>
		</div>
		<AppBottomSheet id="bottom-sheet-slots" :force-width="bottomSheetWidth" :active="activeSheet" @toggling-popup="toggleSheet">
			<div class="bottom-slots-container" v-if="activeSheetDelayed">
				<div class="section-title"><div class="title">{{ getString('order_time') }}</div></div>
				<div class="hour-row" v-for="(slotsHour, hourIndex) in slotsPerHour" :key="hourIndex">
					<div class="single-slot" v-for="(slot, index) in slotsHour" :key="index">
						<div class="slot-time" :class="{active: cartStore.notInPlaceOrderTime === slot.time, available: slot.available}" @click="clickSlot(slot)">{{ slot.time }}</div>
					</div>
				</div>
			</div>
		</AppBottomSheet>
	</div>
</template>

<script>
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore} from "@/shared/stores";
import {formatDateOnly, doubleFigure, getLocalDateFromServer, formatDateTime} from "@/shared/helpers/dates";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import StarsRating from "@/order/components/StarsRating.vue";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";

export default {
	components: {AppBottomSheet, StarsRating, MenuButton},
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		return {cartStore, getString: languageStore.getString, menusStore}
	},
	mixins: [WindowWidth],
	name: "HourPicker",
	props: {
		date: {
			type: Date,
			required: true
		},
		times: {
			type: Array,
			required: true,
			validator(times) {
				return times.every(time => time.date_week && time.hour_start && time.hour_end && time.minute_start && time.minute_end && time.hasOwnProperty("num_orders"));
			}
		}
	},
	data() {
		return {
			activeSheet: false,
            activeSheetDelayed: false,
		}
	},
	computed: {
		bottomSheetWidth() {
			if(this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
		slots() {

			let slots = [];

			let today = new Date();
			let todayDelayed = new Date();
			let delayTime = Number(this.menusStore.delivery_time || 0);
			todayDelayed.setMinutes(todayDelayed.getMinutes() + delayTime);
			let changedDayWithDelay = formatDateOnly(todayDelayed) !== formatDateOnly(today);
			let isToday = formatDateOnly(this.date) === formatDateOnly(today);
			let currentMoment = todayDelayed.getHours()*60 + todayDelayed.getMinutes();

			//if is today and delay time causes to skip to the next day -> empty slots
			if(!(isToday && changedDayWithDelay)) {
				for(let i in this.times) {
					if((parseInt(this.times[i].date_week)+1)%7 === this.date.getDay()) {

						const slotHourStart = parseInt(this.times[i].hour_start);
						const slotMinuteStart = parseInt(this.times[i].minute_start);
						const slotMinuteEnd = parseInt(this.times[i].minute_end);
						const slotHourEnd = parseInt(this.times[i].hour_end);
						const slotMomentEnd = slotHourEnd*60 + slotMinuteEnd;
						const slotMomentStart = slotHourStart*60 + slotMinuteStart;

						if(isToday) {
							if(slotMomentEnd > currentMoment) {
								let earlierMoment = slotMomentStart > currentMoment ? slotMomentStart : currentMoment;
								let targetMinuteStart = earlierMoment === slotMomentStart ? slotMinuteStart : todayDelayed.getMinutes();
								let targetHourStart = slotMomentStart > currentMoment ? slotHourStart : todayDelayed.getHours();
								for(let h = targetHourStart; h <= slotHourEnd; h++) {
									let minuteStart = h === targetHourStart ? targetMinuteStart : '00';
									let minuteEnd = h === slotHourEnd ? slotMinuteEnd : '59';
									slots = slots.concat(getSlotsFromSameHour(h, minuteStart, minuteEnd, this.times[i].num_orders));
								}
							}
						} else {

							for(let h = slotHourStart; h <= slotHourEnd; h++) {
								let minuteStart = h === slotHourStart ? slotMinuteStart : '00';
								let minuteEnd = h === slotHourEnd ? slotMinuteEnd : '59';
								slots = slots.concat(getSlotsFromSameHour(h, minuteStart, minuteEnd, this.times[i].num_orders));
							}

						}

					}
				}
			}

			return arraySlotsUnique(slots);
		},
		slotsPerHour() {
			let slotsPerHour = [];
			for(let i in this.slots) {
				let slot = this.slots[i];
				let hour = slot.hour;
				let findHour = slotsPerHour.find(hourSlot => hourSlot[0].hour === hour);
				if(findHour) {
					findHour.push(slot);
				} else {
					slotsPerHour.push([slot]);
				}
			}
			return slotsPerHour;
		},
		slotsPerHourDense() {
			let averageSlotsPerHour = this.slotsPerHour.reduce((a, b) => a + b.length, 0) / this.slotsPerHour.length;
			return averageSlotsPerHour >= 2;
		},
	},
	methods: {
		toggleSheet(isOpen) {
			if(!isOpen) {
				this.activeSheet = false;
                setTimeout(() => {
                    this.activeSheetDelayed = false;
                }, 500);
			} else {
                this.activeSheetDelayed = true;
            }
		},
		clickSlot(slot) {
			if(!slot.available) {
				return;
			}
			this.cartStore.notInPlaceOrderTime = slot.time;
			if(this.activeSheet) {
				this.activeSheet = false;
			}
		},
		checkSlotsAvailability() {

			this.menusStore.checkTimesDay(this.menusStore.orderMode, this.cartStore.notInPlaceOrderDate, (times) => {
				for (let y in times) {
					let localTime = getLocalDateFromServer(times[y].date);
					let timeRangeOnly = (formatDateTime(localTime, false).split(" ")[1]).split(":");
					timeRangeOnly = timeRangeOnly[0]+":"+timeRangeOnly[1];
					for(let i in this.slots) {
						let slot = this.slots[i];
						if(slot.orders === null) {
							continue;
						}
						if(slot.time === timeRangeOnly) {
							if(parseInt(times[y].orders_count) >= parseInt(slot.orders)) {
								slot.available = false;
								if(this.cartStore.notInPlaceOrderTime === slot.time) {
									this.cartStore.notInPlaceOrderTime = null;
								}
							}
							break;
						}
					}
				}
			});
		}
	}
}

function arraySlotsUnique(array) {
	let a = array.concat();
	for(let i=0; i<a.length; ++i) {
		for(let j=i+1; j<a.length; ++j) {
			if(a[i].time === a[j].time)
				a.splice(j--, 1);
		}
	}

	return a;
}

function getSlotsFromSameHour(commonHour, minuteStart, minuteEnd, maxOrders, interval = 15) {

	let slots = [];
	const divisions = 60 / interval;

	let startMinuteDivision = Math.floor(parseInt(minuteStart) / interval);
	let endMinuteDivision = Math.floor(parseInt(minuteEnd) / interval);

	if(Number(minuteEnd || 0) === 0) {
		return slots;
	}

	for(let i = startMinuteDivision; i <= endMinuteDivision; i++) {
		if(i === divisions-1) {
			const nextHour = (parseInt(commonHour) + 1);
			if(nextHour < 24) {
				slots.push({time: doubleFigure(nextHour) + ":00", orders: maxOrders, hour: nextHour, available: true});
			} else {
				slots.push({time: "00:00", orders: maxOrders, hour: 24, available: true});
			}
		} else {
			slots.push({time: doubleFigure(commonHour) + ":" + doubleFigure((i+1) * interval), orders: maxOrders, hour: commonHour, available: true});
		}
	}

	return slots;
}


</script>


<style lang="scss">
#bottom-sheet-slots {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>
<style scoped lang="scss">
.hour-picker {
	display: flex;
	flex-direction: column;
	align-items: center;

	.slots-container {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: center;
		gap: 8px;
		width: 100%;

		.bottom-sheet-chooser {
			width: 100%;

			.menu-button {
				width: 100%;
				padding: 6px var(--padding-horizontal);
				border: 2px solid var(--business-color);
				transition: box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out, border 0.2s ease-in-out, background-color 0.2s ease-in-out;

				&:deep(.menu-button-text) {
					font-size: var(--font-size);
					line-height: var(--line-height);
				}
			}

			&.timePicked {
				.menu-button {
					border: 2px solid var(--theme-txt2-color);
					background-color: var(--business-color);

					&:deep(.menu-button-text) {
						color: var(--business-text-color);
					}
				}
			}
		}

		.no-slots {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt1-color);
			}
		}

	}
}
.bottom-slots-container {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal);
	gap: 8px;

	.section-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		margin-bottom: var(--padding-vertical);

		.title {
			font-family: var(--font-family);
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			@include truncateString();
		}

	}
}
.hour-row {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
}
.single-slot {
	width: calc(25% - 8px);
	display: flex;
	justify-content: center;
	align-items: center;

	.slot-time {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		color: var(--theme-txt1-color);
		border: 2px solid var(--theme-txt2-color);
		border-radius: var(--rounded-corner);
		font-weight: 500;
		padding: 4px 8px;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.3s ease-in-out;
		opacity: 0.5;
		pointer-events: none;
		min-width: 58px;
		text-align: center;

		&:hover {
			background-color: var(--theme-bg2-color);
		}

		&.active {
			background-color: var(--business-color);
			color: var(--business-text-color);
		}

		&.available {
			cursor: pointer;
			opacity: 1;
			pointer-events: all;
		}
	}

}
</style>