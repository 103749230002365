<template>
	<div class="banner-rounded-corners" :class="{squared: menusStore.business.options.round_level === 'squared' || menusStore.heatMap}">
		<div class="left-corner" v-html="leftSvg"></div>
		<div class="right-corner" v-html="rightSvg"></div>
	</div>
</template>

<script>
import {useMenusStore} from "@/new-menu/stores";

export default {
	setup() {
		let menusStore = useMenusStore();
		return {menusStore}
	},
	name: "BannerRoundedCorners",
	data() {
		return {
			leftRoundSvg: '<svg height="100%" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(1.07764,0,0,1.07764,-0.538822,0)"><path d="M0.5,0L0.5,37.118L37.618,37.118C17.256,37.118 0.5,20.362 0.5,0" fill="var(--theme-bg2-color)" style="fill-rule:nonzero;"/></g></svg>',
			rightRoundSvg: '<svg height="100%" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(1,0,0,1,4.35082e-31,2.882)"><g transform="matrix(-4.12584e-16,-1.07764,1.07764,-4.12584e-16,-4.35082e-31,37.118)"><path d="M0,0L0,37.118L37.118,37.118C16.756,37.118 -0,20.362 -0,0" fill="var(--theme-bg2-color)" style="fill-rule:nonzero;"/></g></g></svg>',
			leftLightSvg: '<svg height="100%" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(2.53174e-16,-0.661273,-0.661273,-2.53174e-16,24.5451,40)"><g><path d="M0,0L0,37.118L37.118,37.118C16.756,37.118 -0,20.362 -0,0" fill="var(--theme-bg2-color)" style="fill-rule:nonzero;"/></g></g></svg>',
			rightLightSvg: '<svg height="100%" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(-2.53174e-16,-0.661273,0.661273,-2.53174e-16,15.4547,40)"><g><path d="M0,0L0,37.118L37.118,37.118C16.756,37.118 -0,20.362 -0,0" fill="var(--theme-bg2-color)" style="fill-rule:nonzero;"/></g></g></svg>'
		}
	},
	computed: {
		leftSvg() {
			if(this.menusStore.business.options.round_level === "light") {
				return this.leftLightSvg;
			}
			return this.leftRoundSvg;
		},
		rightSvg() {
			if(this.menusStore.business.options.round_level === "light") {
				return this.rightLightSvg;
			}
			return this.rightRoundSvg;
		}
	},
}
</script>

<style scoped lang="scss">
.banner-rounded-corners {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-shrink: 0;
	z-index: 10;

	&.squared {
		opacity: 0;
	}

	.left-corner, .right-corner {
		height: 40px;

		svg {
			height: 100%;
		}
	}

	.left-corner svg {
		filter: drop-shadow( 3px -4px 4px rgba(0, 0, 0, .2));
	}

	.right-corner svg {
		filter: drop-shadow( -3px -4px 4px rgba(0, 0, 0, .2));
	}

}
</style>