
export function clusterizeData(data, k) {
	let dataSections = {};
	data.forEach(d => {
		let dData = JSON.parse(d);
		Object.entries(dData).forEach(([type, datapoint]) => {
			Object.entries(datapoint).forEach(([id, values]) => {
				Object.entries(values).forEach(([coords, value]) => {
					let coordsSplit = coords.split('-');
					if(coordsSplit.length !== 2) return;

					if (!dataSections[type+id]) {
						dataSections[type+id] = [];
					}
					for (let i = 0; i < value; i++) {
						dataSections[type+id].push({
							x: parseInt(coordsSplit[0]),
							y: parseInt(coordsSplit[1]),
						});
					}
				});
			});
		});
	});

	let maxPoints = 1;
	Object.entries(dataSections).forEach(([key, sectionData]) => {
		let clusterResult = clusterizeSection(sectionData, k);
		dataSections[key].clusters = clusterResult.clusters;
		dataSections[key].maxPoints = clusterResult.maxPoints;
		maxPoints = Math.max(maxPoints, clusterResult.maxPoints);
	});

	dataSections.maxPoints = maxPoints;
	return dataSections;
}

function clusterizeSection(data, k) {
	let clusters = [];

	let kmeans = new KMeans(k, data);
	let clusteredData = kmeans.cluster();

	let maxPoints = 1;
	for (let i = 0; i < k; i++) {
		let clusterSize = clusteredData.filter(p => p.cluster === i).length;
		maxPoints = Math.max(maxPoints, clusterSize);
	}

	for (let centroid of kmeans.centroids) {
		let clusterSize = clusteredData.filter(p => p.cluster === centroid.cluster).length;
		clusters.push({
			x: centroid.x,
			y: centroid.y,
			size: clusterSize,
		});
	}

	return {
		clusters,
		maxPoints,
	}
}

export function drawHeatMapClusters(ctx, dataSection, maxPoints, rect) {
	let widthCent = rect.width / 100;
	let heightCent = rect.height / 100;
	let pointsScaled = dataSection.clusters.map(p => {
		return {
			x: p.x * widthCent,
			y: p.y * heightCent,
			size: p.size,
		}
	});

	let windowWidth = window.innerWidth;
	let baseClusterSize = (windowWidth / 450) * 24;
	baseClusterSize = Math.max(16, Math.min(30, baseClusterSize));
	let maxClusterSize = (windowWidth / 450) * 60;
	maxClusterSize = Math.max(48, Math.min(78, maxClusterSize));

	for(let point of pointsScaled) {
		let maxAvg = (dataSection.maxPoints + dataSection.maxPoints + maxPoints) / 3;
		let clusterSize = baseClusterSize + ((maxClusterSize - baseClusterSize) * (Math.log(point.size) / Math.log(maxAvg)));
		clusterSize = isNaN(clusterSize) ? baseClusterSize : clusterSize;
		clusterSize = Math.max(baseClusterSize, Math.min(maxClusterSize, clusterSize));
		drawHeatMapCluster(ctx, point.x - clusterSize / 2, point.y - clusterSize / 2, clusterSize, clusterSize, point.size, maxPoints*0.75);
	}

}

function drawHeatMapCluster(ctx, x, y, w, h, value, max) {
	let minOpacity = 0.7;
	let maxOpacity = 0.85;
	let opacity = minOpacity + (maxOpacity - minOpacity) * Math.log(value) / Math.log(max);
	opacity = isNaN(opacity) ? minOpacity : opacity;
	opacity = Math.max(minOpacity, Math.min(maxOpacity, opacity));

	let [r, g, b] = getClusterColor(value, max);

	let gradient = ctx.createRadialGradient(x + w / 2, y + h / 2, 0, x + w / 2, y + h / 2, w / 2);
	gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, ${opacity})`);
	gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`);

	ctx.beginPath();
	ctx.fillStyle = gradient;
	ctx.fillRect(x, y, w, h);
	ctx.closePath();
}

function getClusterColor(value, max) {
	let yellow = [255, 225, 53];
	let yellowOrange = [255, 171, 40];
	let orange = [255, 117, 27];
	let orangeRed = [255, 62, 13];
	let red = [255, 8, 0];

	let ratio = value / max;

	if (ratio <= 0.25) {
		return interpolateColor(yellow, orange, ratio * 2);
	} else if (ratio <= 0.5) {
		return interpolateColor(yellowOrange, orange, (ratio - 0.25) * 2);
	} else if (ratio <= 0.75) {
		return interpolateColor(orange, orangeRed, (ratio - 0.5) * 2);
	} else {
		return interpolateColor(orangeRed, red, (ratio - 0.75) * 2);
	}
}

function interpolateColor(color1, color2, ratio) {
	let r = color1[0] + (color2[0] - color1[0]) * ratio;
	let g = color1[1] + (color2[1] - color1[1]) * ratio;
	let b = color1[2] + (color2[2] - color1[2]) * ratio;
	return [Math.round(r), Math.round(g), Math.round(b)];
}


class KMeans {

	constructor(k, data) {
		this.k = k;
		this.data = data;
		this.centroids = this.initializeCentroids();
	}

	// Step 1: Initialize centroids
	initializeCentroids() {
		let centroids = [];
		for (let i = 0; i < this.k; i++) {
			const randomIndex = Math.floor(Math.random() * this.data.length);
			centroids.push(this.data[randomIndex]);
		}
		return centroids;
	}

	// Calculate the Euclidean distance between two points
	distance(p1, p2) {
		return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
	}

	// Step 2: Assign each data point to the nearest centroid
	assignToNearestCentroid() {
		return this.data.map(point => {
			let minDistance = Infinity;
			let assignedCluster = -1;
			for (let i = 0; i < this.centroids.length; i++) {
				let dist = this.distance(point, this.centroids[i]);
				if (dist < minDistance) {
					minDistance = dist;
					assignedCluster = i;
				}
			}
			return { ...point, cluster: assignedCluster };
		});
	}

	// Step 3: Update centroids
	updateCentroids(clusteredData) {
		let newCentroids = [];
		for (let i = 0; i < this.k; i++) {
			let clusterPoints = clusteredData.filter(p => p.cluster === i);
			if (clusterPoints.length === 0) continue;  // If no points are assigned to a centroid, we skip it
			let xSum = clusterPoints.reduce((sum, p) => sum + p.x, 0);
			let ySum = clusterPoints.reduce((sum, p) => sum + p.y, 0);
			newCentroids.push({ x: xSum / clusterPoints.length, y: ySum / clusterPoints.length, cluster: i });
		}
		return newCentroids;
	}

	// Main clustering function
	cluster() {
		let prevCentroids = [];
		let iteration = 0;
		while (JSON.stringify(this.centroids) !== JSON.stringify(prevCentroids) && iteration < 200) {
			prevCentroids = [...this.centroids];
			let clusteredData = this.assignToNearestCentroid();
			this.centroids = this.updateCentroids(clusteredData);
			iteration++;
		}
		return this.assignToNearestCentroid();
	}
}