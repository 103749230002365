<template>
	<div class="menu-switch" :class="{active}" @click="toggle">
		<div class="selector"></div>
		<div class="switch-left" v-html="getIcon(iconLeft)"></div>
		<div class="switch-right" v-html="getIcon(iconRight)"></div>
	</div>
</template>

<script>
import {useSvgStore} from "@/shared/stores";

export default {
	setup() {
		const svgStore = useSvgStore();
		return {getIcon: svgStore.get}
	},
	name: "MenuSwitch",
	emits: ["toggle"],
	props: {
		iconLeft: {type: String, default: "iconX"},
		iconRight: {type: String, default: "iconV"},
		active: {type: Boolean, default: false},
	},
	methods: {
		toggle() {
			this.$emit("toggle", !this.active);
		}
	},
}
</script>

<style scoped lang="scss">
.menu-switch {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: 2px solid var(--business-color);
	border-radius: var(--rounded-corner);
	background: var(--theme-bg2-color);

	.selector {
		position: absolute;
		top: 2px;
		left: 2px;
		width: 50%;
		height: calc(100% - 4px);
		background-color: var(--business-color);
		border-radius: var(--rounded-corner);
		transition: left 0.2s ease-in-out;

	}

	.switch-left, .switch-right {
		width: 50%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		padding: 7px;

		&:deep(svg) {
			width: 14px;
			height: 9px;

			.fill {
				fill: var(--business-color);
				transition: fill 0.4s ease-in-out;
			}

			.stroke {
				stroke: var(--business-color);
				transition: stroke 0.4s ease-in-out;
			}

		}
	}

	.switch-left {
		padding-right: 3px;

		&:deep(svg) {
			.fill {
				fill: var(--business-text-color);
			}
			.stroke {
				stroke: var(--business-text-color);
			}
		}
	}

	.switch-right {
		padding-left: 3px;
	}

	&.active {
		.selector {
			left: calc(50% - 2px);
		}

		.switch-left {
			&:deep(svg) {
				.fill {
					fill: var(--business-color);
				}
				.stroke {
					stroke: var(--business-color);
				}
			}
		}

		.switch-right {
			&:deep(svg) {
				.fill {
					fill: var(--business-text-color);
				}
				.stroke {
					stroke: var(--business-text-color);
				}
			}
		}

	}

}
</style>