<template>
    <div class="cross-selling-popup-container">
        <div class="cross-selling-content">
            <div class="cross-selling-title">{{ getString('we_recommend') }}</div>
            <div class="cross-selling-category">
                <MenuCategoryItem cross-sell-popup :grid="true" v-for="item in suggestedItems" :item-id="item.menu_item_id" :category-id="item.category_id" />
            </div>
        </div>
        <div class="continue-container">
            <MenuButton :title="getString('continue')" @click="clickContinue" />
        </div>
    </div>
</template>

<script>
import {useLanguageStore} from "@/shared/stores";
import MenuCategoryItem from "@/new-menu/components/MenuCategoryItem.vue";
import {useCartStore} from "@/new-menu/stores";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";

export default {
    setup() {
        const languageStore = useLanguageStore();
        const cartStore = useCartStore();
        return {getString: languageStore.getString, cartStore};
    },
    components: {MenuButton, MenuCategoryItem},
    name: "CrossSellingContainer",
    emits: ["close"],
    data() {
        return {
            suggestedItems: []
        }
    },
    mounted() {
        this.suggestedItems = this.cartStore.getSuggestedItems();
    },
    methods: {
        clickContinue(e) {
            this.$emit("close");
        }
    }
}
</script>

<style scoped lang="scss">
.cross-selling-popup-container {
    display: flex;
    flex-direction: column;
    padding: var(--padding-vertical) var(--padding-horizontal);

    .cross-selling-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;

        .cross-selling-title {
            font-size: var(--font-size-xxl);
            line-height: var(--font-size-xxl);
            font-weight: 700;
            margin-bottom: var(--padding-vertical);
            margin-top: 8px;
            color: var(--theme-txt1-color);
        }

        .cross-selling-category {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: var(--padding-vertical);
            padding: var(--padding-vertical) var(--padding-horizontal-s);
            box-shadow: inset var(--theme-box-shadow-xlight);
            background-color: var(--theme-bg1-color);
        }

    }


    .continue-container {
        width: 100%;
        padding: var(--padding-vertical) var(--padding-horizontal);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        background-color: var(--theme-bg2-color);
        border: 2px solid var(--business-color);
        border-radius: var(--rounded-corner-l);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;

        .menu-button {
            width: 100%;
        }
    }
}
</style>