<template>
	<div class="menu-checkbox">
		<div class="checkbox" :class="{checked}" @click.stop="toggle"></div>
	</div>
</template>

<script>
export default {
	name: "MenuCheckbox",
	props: {
		checked: {
			type: Boolean,
			default: false
		}
	},
	emits: ["toggle"],
	methods: {
		toggle() {
			this.$emit("toggle", !this.checked);
		}
	}
}
</script>

<style scoped lang="scss">
.menu-checkbox {
	display: flex;
	align-items: center;
	justify-content: center;

	.checkbox {
		width: 16px;
		height: 16px;
		border-radius: var(--rounded-corner);
		border: 2px solid var(--business-color);
		cursor: pointer;
		position: relative;

		&:after {
			content: "";
			display: block;
			border-radius: var(--rounded-corner);
			background-color: var(--business-color);
			opacity: 0;
			transform: scale(0);
			transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
			position: absolute;
			top: 17%;
			left: 17%;
			width: 66%;
			height: 66%;
		}

		&.checked {

			&:after {
				opacity: 1;
				transform: scale(1);
			}

		}
	}
}
</style>