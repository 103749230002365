<template>
	<div class="phone-prefix-input-container">
		<div class="phone-prefix-input" @click="active = true">
			<img v-if="menusStore.currentCountry" :src="'https://img.qromo.io/img/lang/'+menusStore.currentCountry.file_name">
			<div class="prefix">{{ menusStore.currentCountry ? menusStore.currentCountry.prefix_number : '' }}</div>
		</div>
		<AppBottomSheet id="bottom-sheet-prefix" :forceWidth="bottomSheetWidth" :active="active" @toggling-popup="togglingSheet" >
			<div class="phones-container" v-if="activeDelayed">
				<div class="section-title"><div class="title">{{ getString('phone_country_code') }}</div></div>
				<div class="phone-list-container">
					<div class="phone-item" v-for="country in menusStore.countries" @click="clickCountry(country)" :class="{selected: menusStore.currentCountryId === country.country_id}">
						<div class="phone-name">{{ country.key_name+" ("+country.prefix_number+")" }}</div>
						<div class="phone-icon"><img :src="'https://img.qromo.io/img/lang/'+country.file_name"></div>
					</div>
				</div>
			</div>
		</AppBottomSheet>
	</div>
</template>

<script>
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {useLanguageStore} from "@/shared/stores";
import {useMenusStore} from "@/new-menu/stores";

export default {
	setup() {
		let languageStore = useLanguageStore();
		let menusStore = useMenusStore();
		return {getString: languageStore.getString, menusStore}
	},
	name: "PhonePrefixInput",
	components: {AppBottomSheet},
	mixins: [WindowWidth],
	data() {
		return {
			active: false,
            activeDelayed: false,
		}
	},
	computed: {
		bottomSheetWidth() {
			if(this.windowWidth < 550) {
				return "100%";
			} else if(this.windowWidth < 768) {
				return "550px";
			} else if(this.windowWidth < 1080) {
				return "720px";
			} else if(this.windowWidth < 1440) {
				return "960px";
			} else {
				return "1280px";
			}
		},
	},
	methods: {
		togglingSheet(isOpen) {
			if(!isOpen) {
				this.active = false;
                setTimeout(() => {
                    this.activeDelayed = false;
                }, 500);
			} else {
                this.activeDelayed = true;
            }
		},
		clickCountry(country) {
			this.menusStore.currentCountryId = country.country_id;
			this.active = false;
		}
	},
}
</script>

<style lang="scss">
#bottom-sheet-prefix {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>

<style scoped lang="scss">
.phone-prefix-input {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background: var(--theme-bg1-color);
	border-radius: var(--rounded-corner);
	padding: 0 8px;
	cursor: pointer;
	height: 100%;

	img {
		width: 18px;
		height: 18px;
		margin-right: 4px;
		border-radius: var(--rounded-corner);
	}

	.prefix {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		color: var(--theme-txt1-color);
	}
}

.phones-container {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal);

	.section-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		margin-bottom: var(--padding-vertical);

		.title {
			font-family: var(--font-family);
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			@include truncateString();
		}

	}


	.phone-list-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		--gap: 8px;
		gap: var(--gap);

		.phone-item {
			--columns: 4;
			width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 4px 8px;
			cursor: pointer;
			background-color: var(--theme-bg2-color);
			border-radius: var(--rounded-corner);
			box-shadow: var(--theme-box-shadow-null);
			transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

			@media (max-width: 1080px) {
				--columns: 3;
			}

			@media (max-width: 768px) {
				--columns: 2;
			}

			@media (max-width: 400px) {
				--columns: 1;
			}

			.phone-name {
				font-family: var(--font-family);
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				@include truncateString();
				transition: color 0.2s ease-in-out;
			}

			.phone-icon {
				width: 16px;
				height: 16px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					transition: box-shadow 0.2s ease-in-out;
					box-shadow: 0 0 0 1px rgba(255, 255, 255, 0);
					border-radius: var(--rounded-corner);
				}
			}

			&:hover {
				box-shadow: var(--theme-box-shadow-light);
			}

			&.selected {
				background-color: var(--business-color);

				.phone-name {
					color: var(--business-text-color);
				}

				.phone-icon {
					img {
						box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
					}
				}
			}
		}
	}
}
</style>