<template>
	<div class="menu-footer" :class="{heatmapOpacity: menusStore.heatMap}">
		<LanguageSelector class="language-sel" :class="{marginBottom: menusStore.socials.length === 0}" />
		<div class="socials-container" v-if="menusStore.socials.length > 0">
			<div class="social-container" v-for="social in menusStore.socials">
				<a :href="social.link" target="_blank" v-html="getIcon(social.type)"></a>
			</div>
		</div>
		<div class="line"></div>
		<div class="logo-container">
			<a :href="URL_HTML" target="_blank"><img src="https://img.qromo.io/img/logo.png" alt="Qromo Logo"></a>
		</div>
		<div class="cookie-container">
			<a href="/cookies/" target="_blank">{{ getString('cookie_policy') }}</a>
		</div>
		<div class="cookie-container">
			<a :href="linkPrivacy" target="_blank">{{ getString('privacy_policy') }}</a>
		</div>
		<div class="cookie-container" v-if="menusStore.business.options.custom_terms">
			<a :href="menusStore.business.options.custom_terms" target="_blank">{{ getString('terms_conditions') }}</a>
		</div>
	</div>
</template>

<script>
import LanguageSelector from "@/new-menu/components/modals/LanguageSelector.vue";
import {useLanguageStore, useSvgStore} from "@/shared/stores";
import {useMenusStore} from "@/new-menu/stores";

export default {
	setup() {
		let menusStore = useMenusStore();
		let svgStore = useSvgStore();
		return {getString: useLanguageStore().getString, getIcon: svgStore.get, menusStore}
	},
	name: "MenuFooter",
	components: {LanguageSelector},
	data() {
		return {
			cookieLink: "/cookies/",
			URL_HTML: URL_HTML,
		}
	},
	computed: {
		linkPrivacy() {
			return this.menusStore.business.options.custom_privacy ? this.menusStore.business.options.custom_privacy : "/privacy/";
		}
	}
}
</script>

<style scoped lang="scss">
.menu-footer {
	display: flex;
	flex-direction: column;

	.language-sel {

		&.marginBottom {
			margin-bottom: var(--padding-vertical);
		}

	}

	.socials-container {
		margin-bottom: var(--padding-vertical);
		display: flex;
		align-items: center;
		justify-content: center;
		gap: var(--padding-horizontal-s);

		.social-container {
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				display: flex;
				padding: 2px;

				&:deep(svg) {
					width: 25px;
					height: 25px;
					object-fit: contain;
					border-radius: var(--rounded-corner);

					.fill {
						fill: var(--business-color);
					}

					.stroke {
						stroke: var(--business-color);
					}
				}

				&:after {
					display: none;
				}
			}
		}
	}

	.line {
		width: calc(100% - (var(--padding-horizontal) * 2));
		height: 1px;
		background-color: var(--theme-bg2-color);
		margin: 0 var(--padding-horizontal) var(--padding-vertical);
	}

	.logo-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: var(--padding-vertical);

		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
		}

	}

	.cookie-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: var(--padding-vertical);

	}

	a {
		font-family: var(--font-family);
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		color: var(--theme-txt2-color);
		text-decoration: none;

		&:after {
			display: none;
		}
	}
}
</style>