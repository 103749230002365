<template>
	<AppNotification force-color="var(--business-color)" v-for="notification in activeNotifications" :key="notification.ref" :type="typeFromObject(notification)" :title="getString(notification.title)" :body-text="getString(notification.body, notification.bodyParams ? notification.bodyParams : [])" :duration="notification.duration ? notification.duration : 4300" :ref="notification.ref" :id="notification.ref" />
</template>

<script>
import AppNotification from "@/shared/components/AppNotification.vue";
import {useLanguageStore, useNotificationStore} from "@/shared/stores";
import {useUserStore} from "@/console-new/stores";

export default {
	setup() {
		const notificationStore = useNotificationStore();
		const languageStore = useLanguageStore();
		const userStore = useUserStore();
		return {notificationStore, getString: languageStore.getString, userStore};
	},
	name: "NotificationManager",
	components: {AppNotification},
	data() {
		return {
			activeNotifications: [],
		}
	},
	computed: {
		notifications() {
			return [
				{ref: 'generic_success', title: 'saved', body: 'changes_saved'},
				{ref: 'generic_fail', title: 'error', body: 'try_later_error'},
				{ref: 'max_choice_list_single', title: 'error', body: 'max_choice_list_single'},
				{ref: 'max_choice_list', title: 'error', body: 'max_choice_list'},
				{ref: 'too_big_to_translate_catalog', title: 'error', body: 'too_big_to_translate_catalog'},
				{ref: 'too_big_to_translate', title: 'error', body: 'too_big_to_translate'},
				{ref: 'payment_method_unaccepted', title: 'error', body: 'payment_method_unaccepted'},
				{ref: 'min_order', title: 'error', body: 'min_order'},
				{ref: 'no_orders_now', title: 'error', body: 'no_orders_now', duration: 5000},
				{ref: 'invalid_amount_type', title: 'error', body: 'invalid_amount_type', duration: 5000},
				{ref: 'far_away_explain', title: 'error', body: 'far_away_explain', duration: 6500},
				{ref: 'order_failed', title: 'error', body: 'order_failed', duration: 4500},
				{ref: 'captcha_failed', title: 'error', body: 'captcha_failed', duration: 6500},
				{ref: 'long_ago', title: 'error', body: 'long_ago_explain', duration: 7000},
				{ref: 'long_ago_pay_bill', title: 'error', body: 'long_ago_pay_bill_explain', duration: 7000},
				{ref: 'long_ago_assistance', title: 'long_ago', body: 'long_ago_assistance_explain', duration: 7000, type: -2},
				{ref: 'courier_no_rate', title: 'error', body: 'courier_no_rate'},
				{ref: 'payment_method_issue', title: 'error', body: 'payment_method_issue'},
				{ref: 'delivery_not_accepted', title: 'error', body: 'delivery_not_accepted'},
				{ref: 'takeaway_not_accepted', title: 'error', body: 'takeaway_not_accepted'},
				{ref: 'stock_invalid', title: 'error', body: 'stock_invalid'},
				{ref: 'products_unavailable', title: 'error', body: 'products_unavailable'},
				{ref: 'product_unavailable_warehouse', title: 'error', body: 'product_unavailable_warehouse', duration: 5500},
				{ref: 'product_unavailable_other_cart', title: 'error', body: 'product_unavailable_other_cart', duration: 6500},
				{ref: 'product_unavailable', title: 'error', body: 'product_unavailable', duration: 5000},
				{ref: 'cover_charge_invalid', title: 'error', body: 'cover_charge_invalid'},
				{ref: 'courier_payment_explain', title: 'error', body: 'courier_payment_explain', duration: 5500},
				{ref: 'conditions_not_matched_list', title: 'error', body: 'conditions_not_matched_list'},
				{ref: 'conditions_not_matched_multiple_lists', title: 'error', body: 'conditions_not_matched_multiple_lists'},
				{ref: 'item_not_available_quantity', title: 'not_available', body: 'item_not_available_quantity', duration: 5000},
				{ref: 'category_not_available_quantity', title: 'not_available', body: 'category_not_available_quantity', duration: 5000},
				{ref: 'item_not_available', title: 'not_available', body: 'item_not_available', duration: 4500},
				{ref: 'ask_for_assistance_info', title: 'call_placed', type: -1, body: 'ask_for_assistance_info', duration: 6500},
				{ref: 'missing_phone_number', title: 'error', body: 'missing_phone_number', duration: 6500},
				{ref: 'invalid_phone', title: 'error', body: 'invalid_phone', duration: 6500},
				{ref: 'missing_table_number', title: 'error', body: 'missing_table_number'},
				{ref: 'missing_customer_email', title: 'error', body: 'missing_customer_email'},
				{ref: 'missing_order_date', title: 'error', body: 'missing_order_date'},
				{ref: 'missing_order_time', title: 'error', body: 'missing_order_time'},
				{ref: 'fee_greater_than_total', title: 'error', body: 'fee_greater_than_total'},
				{ref: 'cannot_be_zero', title: 'error', body: 'cannot_be_zero'},
				{ref: 'order_failed_later', title: 'error', body: 'order_failed_later'},
				{ref: 'missing_courier', title: 'error', body: 'missing_courier'},
				{ref: 'invalid_email', title: 'error', body: 'invalid_email'},
				{ref: 'invalid_captcha', title: 'error', body: 'invalid_captcha'},
				{ref: 'coupon_invalid', title: 'error', body: 'coupon_invalid'},
				{ref: 'coupon_duplicate', title: 'error', body: 'coupon_duplicate'},
				{ref: 'coupon_min_order_value', title: 'error', body: 'coupon_min_order_value'},
				{ref: 'coupon_cant_stack', title: 'error', body: 'coupon_cant_stack'},
				{ref: 'coupon_already_used', title: 'error', body: 'coupon_already_used'},
				{ref: 'coupon_mode', title: 'error', body: 'coupon_mode'},
				{ref: 'no_linked_products', title: 'error', body: 'no_linked_products'},
				{ref: 'missing_customer_name', title: 'error', body: 'missing_customer_name'},
				{ref: 'loading_stripe', title: 'error', body: 'loading_stripe'},
				{ref: 'empty_cart', title: 'error', body: 'empty_cart'},
				{ref: 'missing_address', title: 'error', body: 'missing_address', duration: 5500},
				{ref: 'max_items', title: 'error', body: 'max_items', duration: 6500},
			]
		}
	},
	methods: {
		typeFromObject(notificationObject) {
			if(notificationObject.type) {
				return notificationObject.type;
			}
			if (notificationObject.title === 'error') {
				return -2;
			} else if (notificationObject.title === 'warning') {
				return -1;
			} else if (notificationObject.title === 'saved' || notificationObject.title === 'success') {
				return 1;
			}
			return 0;

		},
		openNotification(ref, bodyParams) {
			let notification = this.notifications.find(notification => notification.ref === ref);
			if(notification) {
				if(bodyParams) {
					notification.bodyParams = bodyParams;
				}
				if(notification.hasOwnProperty('exitTimeout')) {
					clearTimeout(notification.exitTimeout);
				}
				let alreadyActive = this.activeNotifications.find(n => n.ref === ref);
				if(!alreadyActive) {
					this.activeNotifications.push(notification);
				}
				this.$nextTick(() => {
					setTimeout(() => {
						if(this.$refs && this.$refs[ref] && notification) {
							let notificationComponent = this.$refs[ref].length ? this.$refs[ref][0] : this.$refs[ref];
							if(notificationComponent.openNotification) {
								let duration = notificationComponent.duration;
								notificationComponent.openNotification();
								notification.exitTimeout = setTimeout(() => {
									if(notification) {
										this.activeNotifications = this.activeNotifications.filter(n => n.ref !== ref);
									}
								}, duration+1000);
							}
						}
					}, 30);
				});
			}
		}
	},
	mounted () {
		for(let i = 0; i < this.notifications.length; i++) {
			this.notificationStore.notifications.push({
				id: this.notifications[i].ref,
				open: (bodyParams) => this.openNotification(this.notifications[i].ref, bodyParams),
			});
		}
	},
}
</script>

<style lang="scss">
.notification-container {

	.notification-wrapper {
		border-radius: var(--rounded-corner) !important;
		background-color: var(--theme-bg1-color) !important;
		box-shadow: var(--theme-box-shadow-strong) !important;

		.progress-container {
			border-radius: var(--rounded-corner-s) !important;
		}

		p {
			color: var(--theme-txt2-color) !important;
			font-size: var(--font-size) !important;
			line-height: var(--line-height) !important;
		}

		.notification-inner {
			.close-btn {
				svg {
					.fill {
						fill: var(--theme-txt2-color) !important;
					}
					.stroke {
						stroke: var(--theme-txt2-color) !important;
					}
				}
			}

			.notification-header {
				&:after {
					background-color: var(--theme-bg2-color) !important;
				}

				h3 {
					font-size: var(--font-size-s) !important;
					line-height: var(--line-height-s) !important;
				}
			}

			.progress-container {
				background-color: var(--theme-bg2-alpha40-color) !important;
			}
		}
	}

}
</style>