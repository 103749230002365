<template>
	<div class="menu-category-item" ref="menu-category-item" @click="clickAdd" :class="{inCart: selectedQuantity > 0 && !crossSell, grid, carouselItem: carousel, crossSell, crossSellPopup, noPicture, noOrder, notRelevant: filtered || !searched}" :id="'item-'+item.menu_item_id" :style="customColor">
		<canvas v-if="menusStore.heatMapData && !item.crossSell" ref="heat-map-canvas" style="position: absolute; top: 0; left: 0;z-index: 1000;"></canvas>
		<div class="item-data" :class="{heatmapOpacity: menusStore.heatMap}">
			<div class="item-data-delete" :class="{hiddenBin: crossSell}" v-html="getIcon('iconBin')" @click.stop="clickRemove"></div>
			<div class="item-data-content">
				<div class="item-data-top">
					<div class="title"><span class="item-counter-inline" v-if="noPicture && selectedQuantity > 0">x{{ selectedQuantity }}</span><span :class="{crossSell}" v-html="itemNameSanitized"></span></div>
					<div class="description" v-if="item.ingredients && !crossSell && !crossSellPopup">{{ item.ingredientsTranslation }}</div>
				</div>
				<div class="item-data-bottom" :class="{twoLines: twoLinesBottom}">
					<div class="allergens" v-if="item.allergens.length > 0 && !crossSell">
						<div class="allergen-icon" v-for="allergen in item.allergens" :key="allergen.allergen_id" :style="'background-color: #'+allergen.color+';'"><img :src="allergen.image_link" :alt="getString(allergen.name_key)"></div>
					</div>
					<div class="price-container" :class="{twoLines: twoLinesBottomPrice}">
						<div v-if="tag && !crossSell" class="tag">{{ tag }}</div>
						<div v-if="!noPrice" class="price" :class="{withFullPrice: !!item.full_price && item.full_price !== item.price}"><span class="full-price" v-if="item.full_price && item.full_price !== item.price">{{ readableEurValue(item.full_price/100) }}</span>{{ readableEurValue(item.price/100) }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="item-image-container" :class="{heatmapOpacity: menusStore.heatMap}" v-if="!noPicture">
			<div class="item-data-delete" v-html="getIcon('iconBin')" @click.stop="clickRemove"></div>
			<div class="item-image">
				<div class="item-image-sizer" @click.stop="clickZoom">
					<img :src="picture" :alt="item.nameTranslation" :style="'background-color:#'+pictureColors.bgColor.replace('#', '')">
					<div class="item-counter" :class="{active: selectedQuantity > 0}">{{ selectedQuantity }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore, useNotificationStore, useSvgStore} from "@/shared/stores";
import {isContrastEnoughForText, isTextColorDark} from "@/shared/helpers/ui";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {drawHeatMapClusters} from "@/new-menu/helpers/heatmap";

export default {
	setup() {
		let cartStore = useCartStore();
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let notificationStore = useNotificationStore();
		let evsStore = useEvsStore();
		return {cartStore, getIcon: svgStore.get, menusStore, readableEurValue: languageStore.readableEurValue, getString: languageStore.getString, notificationStore, evsStore}
	},
	name: "MenuCategoryItem",
	mixins: [WindowWidth],
	props: {
		grid: {
			type: Boolean,
			default: false
		},
		carousel: {
			type: Boolean,
			default: false
		},
		itemId: {
			type: Number,
			required: true
		},
		categoryId: {
			type: Number,
			default: null
		},
		noPicture: {
			type: Boolean,
			default: false
		},
		crossSell: {
			type: Boolean,
			default: false
		},
        crossSellPopup: {
            type: Boolean,
            default: false
        }
	},
	computed: {
		twoLinesBottom() {
			return !this.crossSell && (this.tag !== null || !!this.item.full_price);
		},
		twoLinesBottomPrice() {
			return !this.crossSell && this.tag !== null && !!this.item.full_price;
		},
		tag() {
			if(this.menusStore.selectedMenuMostPopularItemsIds.includes(this.item.menu_item_id)) {
				return this.getString("popular");
			}/* else if(this.menusStore.business.options.cross_selling && this.cartStore.recommendedItemsIdsSelectedMenu.includes(this.item.menu_item_id)) {
				return this.getString("recommended");
			}*/ else {
				return null;
			}
		},
		customColor() {
			if(this.item.color) {
				let color = this.item.color;
				let textColor = isTextColorDark(color) ? "#000000" : "#FFFFFF";
				return {
					"--business-color": "#"+color,
					"--business-color-alpha20": "#"+color+"33",
					"--business-color-alpha40": "#"+color+"66",
					"--business-color-alpha60": "#"+color+"99",
					"--business-color-alpha80": "#"+color+"CC",
					"--business-text-color": textColor,
				}
			} else {
				return "";
			}
		},
		noOrder() {
			return this.item.orderability === "disabled" || this.item.orderability === "no_price";
		},
		noPrice() {
			return this.item.orderability === "no_price" || this.menusStore.business.options.hide_prices;
		},
		item() {
			return this.menusStore.getItemById(this.itemId);
		},
		category() {
			if(this.categoryId) {
				return this.menusStore.getCategoryById(this.categoryId);
			} else {
				return null;
			}
		},
		itemNameSanitized() {
			if(this.menusStore.search === null || this.menusStore.search === "") {
				return this.$sanitize(this.item.nameTranslation);
			} else {
				return this.getSanitizedStringFromSearch(this.item.nameTranslation);
			}
		},
		picture() {
			if(this.item.picture) {
				return this.item.picture;
			} else {
				if(this.category.taxonomy) {
					return URL_HTML+'/img/categories/'+this.category.taxonomy+'.php?c='+this.pictureColors.color.replace('#', '')+'&b='+this.pictureColors.bgColor.replace('#', '');
				} else {
					return URL_HTML+'/img/categories/icon-generic-food-drink-1.php?c='+this.pictureColors.color.replace('#', '')+'&b='+this.pictureColors.bgColor.replace('#', '');
				}
			}
		},
		pictureColors() {
			return {
				bgColor: this.menusStore.theme.bg_1,
				color: this.menusStore.business.color,
			};
		},
		selectedQuantity() {
			return this.cartStore.getItemQuantity(this.item);
		},
		filtered() {
			return this.item.allergens.length !== 0 && this.item.allergens.some(allergen => !allergen.active);
		},
		searched() {
			if(!this.menusStore.search) {
				return true;
			}
			if(this.item.searched) {
				return true;
			}
			if(this.category && this.category.searched && this.category.searchedFullMatch) {
				return true;
			}

			return false;
		}
	},
	methods: {
		getSanitizedStringFromSearch(str) {
			let search = this.menusStore.search.toLowerCase();
			let lowerStr = str.toLowerCase();
			let index = lowerStr.indexOf(search);
			if(index !== -1) {
				let start = str.substring(0, index);
				let end = str.substring(index + search.length);
				let searchPart = str.substring(index, index + search.length);
				return this.$sanitize(start) + "<span class='highlight'>" + this.$sanitize(searchPart) + "</span>" + this.$sanitize(end);
			} else {
				return this.$sanitize(str);
			}
		},
		clickZoom(event) {
            this.clickEv(event);
			if(this.item.picture && !this.item.deepen && !this.item.composite && !this.item.ingredientsFull && !this.crossSellPopup) {
				this.menusStore.imageZoomItem = this.item;
				this.evsStore.enqueueEvent('view', "image", this.item.menu_item_id);
			} else {
				this.clickAdd(null);
			}
		},
		clickAdd(event) {

            this.clickEv(event);

			if(this.noOrder && !this.item.deepen && !this.item.composite && !this.item.ingredientsFull) {
				this.notificationStore.showNotification("item_not_available", [this.item.nameTranslation]);
				return;
			}
			if(this.filtered) {
				this.menusStore.filteredProductToAdd = this.item.menu_item_id;
				return;
			}
			this.cartStore.triggerItem(this.item, this.category, this.$router, this.$route);
		},
		clickRemove(event) {
            this.clickEv(event);
			this.cartStore.removeItemQuantity(this.item);
		},
		clickEv(event) {

            if(event) {
                let itemElement = this.$refs['menu-category-item'];
                let rect = itemElement.getBoundingClientRect();
                let x = event.clientX - rect.left;
                let y = event.clientY - rect.top;
                let xPercent = (x / rect.width) * 100;
                let yPercent = (y / rect.height) * 100;

                this.evsStore.enqueueEvent('click', 'item', JSON.stringify({
                    id: Number(this.itemId),
                    x: Number(xPercent.toFixed(2)),
                    y: Number(yPercent.toFixed(2)),
                }))
            }

		},
		drawHeatMap() {
			const container = this.$refs['menu-category-item'];
			const canvas = this.$refs['heat-map-canvas'];
			if(canvas) {
				const ctx = canvas.getContext('2d');

				const rect = container.getBoundingClientRect();
				canvas.width = rect.width;
				canvas.height = rect.height;

				let itemData = this.menusStore.heatMapData && this.menusStore.heatMapData.hasOwnProperty("i"+this.itemId) ? this.menusStore.heatMapData["i"+this.itemId] : null;
				if(itemData && this.menusStore.heatMapData.hasOwnProperty("maxPoints")) {
					drawHeatMapClusters(ctx, itemData, this.menusStore.heatMapData.maxPoints, rect);
				}
			}

		},
	},
	mounted() {
		if(this.menusStore.heatMapData && !this.item.crossSell) {
			this.drawHeatMap();
		}
	},
	watch: {
		'menusStore.heatMapData': {
			handler(newVal) {
				if(newVal) {
                    this.$nextTick(() => {
                        this.drawHeatMap();
                    });
				}
			},
			deep: true
		},
		windowWidth() {
			if(this.menusStore.heatMapData) {
				this.drawHeatMap();
			}
		}
	}
}
</script>

<style scoped lang="scss">
.menu-category-item {
	--item-padding-l: var(--padding-horizontal-s);
	--item-padding: 4px;
	background-color: var(--theme-bg2-color);
	border-radius: var(--rounded-corner);
	padding: var(--item-padding-l) var(--item-padding-l);
	display: flex;
	align-items: stretch;
	cursor: pointer;
	box-shadow: var(--theme-box-shadow-null);
	position: relative;
	--columns: 2;
	--width-overflow: 0;
	--gap: var(--padding-vertical);
	width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
	opacity: 1;
	transition: box-shadow .2s ease-in-out, padding .2s linear, opacity .2s linear;
	scroll-margin-top: 90px;

	canvas {
		border-radius: var(--rounded-corner);
	}

	&.notRelevant {
		opacity: 0.5;
	}

	&.carouselItem {
		height: auto;
		flex-shrink: 0;
		--width-overflow: -10%;
		width: calc((100% / var(--columns)) + (var(--width-overflow) / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
	}

	&.noPicture {
		--columns: 3;
	}

	&.crossSell {
		--columns: 2;
		--width-overflow: -5%;
		--item-padding-l: 8px;
		--item-padding: 2px;
	}

	@media (max-width: 1275px) {
		&.crossSell {
			--columns: 2;
			--width-overflow: 35%;
		}
	}

	@media (max-width: 1080px) {

		&.noPicture {
			--columns: 2;
		}
	}

	@media (max-width: 1024px) {

		&.carouselItem {
			--width-overflow: -20%;
		}

		&.crossSell {
			--columns: 2;
			--width-overflow: -10%;
		}

	}

	@media (max-width: 768px) {
		&.carouselItem {
			--width-overflow: 10%;
		}

		&.crossSell {
			--width-overflow: -15%;
		}
	}

	@media (max-width: 600px) {
		--columns: 1;

		&.carouselItem {
			--width-overflow: -15%;
		}
	}

	@media (max-width: 550px) {

		&.noPicture {
			--columns: 1;
		}

		&.crossSell {
			--columns: 2;
		}

	}

	@media (max-width: 450px) {

		&.crossSell {
			--width-overflow: 15%;
		}

	}


	@media (max-width: 370px) {

		&.crossSell {
			--columns: 1;
			--width-overflow: -15%;
		}

	}

	@media (max-width: 340px) {

		&.carouselItem {
			--width-overflow: -5%;
		}

		&.crossSell {
			--columns: 1;
			--width-overflow: -10%;
		}

	}

	&:hover {
		box-shadow: var(--theme-box-shadow-light);
	}

	&:active {
		box-shadow: var(--theme-box-shadow-null);
	}

	&:before {
		content: "";
		position: absolute;
		top: 15%;
		left: 0;
		width: 0;
		height: 70%;
		background-color: var(--business-color);
		border-top-right-radius: var(--rounded-corner-s);
		border-bottom-right-radius: var(--rounded-corner-s);
		z-index: 1;
		transition: width .2s ease-in-out;
	}

	&.noOrder {
		box-shadow: inset var(--theme-box-shadow-light);

		&:hover {
			box-shadow: inset var(--theme-box-shadow-light);
		}

		&:active {
			box-shadow: inset var(--theme-box-shadow-light);
		}
	}

	.item-data-delete {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: width .2s linear;
		overflow: hidden;

		&:deep(svg) {
			width: 28px;
			height: 28px;
			padding: 5px 3px 5px 5px;

			.fill {
				fill: var(--business-color);
			}

			.stroke {
				stroke: var(--business-color);
			}
		}
	}

	.item-data {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		padding-right: var(--item-padding-l);
		overflow: hidden;

		.item-data-delete {
			width: 0;
		}

		.item-data-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			transition: width .2s linear, padding-left .2s linear;

			.item-data-top {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				.title {
					margin-bottom: 2px;
					display: flex;

					span {
						font-size: var(--font-size);
						line-height: var(--line-height);
						color: var(--theme-txt1-color);
						margin-bottom: var(--item-padding);
						font-weight: 500;
						@include truncateString(3);

						&.crossSell {
							font-size: var(--font-size-s);
							line-height: var(--line-height-s);
							@include truncateString(2);
						}

						&:deep(.highlight) {
							font-size: var(--font-size);
							line-height: var(--line-height);
							color: var(--theme-txt1-color);
							background: var(--business-color-alpha20);
							position: relative;

							&:after {
								content: "";
								position: absolute;
								bottom: 0;
								left: 0;
								width: 100%;
								height: 2px;
								background-color: var(--business-color);
								border-radius: 1px;
							}
						}
					}

					.item-counter-inline {
						font-size: var(--font-size);
						line-height: var(--line-height);
						color: var(--business-color);
						font-weight: 700;
						margin-right: 6px;
						flex-shrink: 0;
					}
				}

				.description {
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt2-color);
					margin-bottom: 6px;
					overflow: hidden;
					text-overflow: ellipsis;
				}


			}

			.item-data-bottom {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.allergens {
					display: flex;
					flex-direction: row;
					align-items: center;
					flex-wrap: wrap;
					margin-bottom: var(--item-padding);
					gap: var(--item-padding);

					.allergen-icon {
						width: 22px;
						height: 22px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: var(--allergen-color);
						border-radius: var(--rounded-corner);

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 50%;
						}
					}

				}

				.price-container {
					flex-shrink: 0;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					margin-left: auto;
					padding-left: 6px;
					overflow: hidden;
					flex-wrap: wrap;

					.tag {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--business-color);
						text-align: right;
						position: relative;

						&:after {
							content: "";
							position: absolute;
							top: calc(50% - 0.5px);
							right: -6px;
							width: 3px;
							height: 3px;
							background-color: var(--business-color);
							border-radius: var(--rounded-corner);
						}
					}

					.price {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--theme-txt2-color);
						display: flex;
						justify-content: flex-end;
						align-items: center;
						text-align: right;
						margin-left: 10px;

						.full-price {
							text-decoration: line-through;
							text-decoration-thickness: 1.5px;
							margin-right: 6px;
							font-size: var(--font-size-s);
							line-height: var(--line-height-s);
							color: var(--theme-txt2-color);
						}

						&.withFullPrice {
							color: var(--business-color);
						}
					}

					&.twoLines {
						flex-direction: column;
						gap: 6px;
						align-items: flex-end;

						.tag {
							margin-right: 0;
						}

						&:after {
							display: none;
						}
					}
				}

				&.twoLines {
					flex-direction: column;
					gap: 6px;
					align-items: flex-start;
				}
			}
		}

	}

	&.noPicture {
		.item-data {
			padding-right: 0;
		}
	}

	.item-image-container {
		display: flex;
		justify-content: center;
		align-items: center;

		.item-data-delete {
			width: 0;
		}

		.item-image {
			cursor: pointer;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			.item-image-sizer {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 80px;
					height: 80px;
					object-fit: cover;
					border-radius: var(--rounded-corner);
				}

				.item-counter {
					position: absolute;
					top: 10%;
					right: 10%;
					width: 80%;
					height: 80%;
					background-color: var(--business-color-alpha60);
					color: var(--business-text-color);
					backdrop-filter: blur(3px);
					-webkit-backdrop-filter: blur(3px);
					border-radius: var(--rounded-corner);
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: var(--font-size-xxl);
					line-height: var(--line-height-xxl);
					font-weight: 700;
					transition: opacity .2s ease-in-out;
					opacity: 0;

					&.active {
						opacity: 1;
					}
				}
			}
		}
	}

	&.inCart {
		padding-left: 0;

		&:before {
			width: 4px;
		}

		.item-data {
			.item-data-delete {
				width: 44px;
			}
			.item-data-content {
				width: calc(100% - 30px);
			}
		}
	}

	&.grid {
		--columns: 4;
		--gap: var(--padding-vertical);
		--image-size: 110px;
		flex-direction: column-reverse;
		width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));

		&.carouselItem {
			width: calc((95% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
		}

		@media (max-width: 820px) {
			--columns: 3;

			&.carouselItem {
				width: calc((92% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
			}
		}

		@media (max-width: 585px) {
			--columns: 2;

			&.carouselItem {
				width: calc((95% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
			}
		}

		@media (max-width: 370px) {
			--columns: 1;

            &.crossSellPopup {
                --columns: 2;
            }

			&.carouselItem {
				width: calc((85% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
			}
		}

        &.crossSellPopup {
            @media (max-width: 360px) {
                --columns: 1;
            }
        }

		.item-data {
			width: 100%;
			height: auto;
			padding-right: 0;
			padding-top: var(--item-padding-l);
			transition: padding 0.2s linear;
		}

		.item-image-container {
			width: 100%;

			.item-image {
				flex-grow: 1;

				.item-image-sizer {
					img {
						width: var(--image-size);
						height: var(--image-size);
					}
				}
			}
		}

		&:before {
			top: calc(var(--item-padding-l) + 5px);
			height: calc(var(--image-size) - 10px);
		}

		&.inCart {

			.item-data {
				padding-left: 0;

				.item-data-delete {
					display: none;
				}
				.item-data-content {
					width: 100%;
					padding-left: var(--item-padding-l);
				}
			}

			.item-image-container {
				.item-data-delete {
					width: 44px;
				}
			}
		}

		&.noPicture {
			.item-data {
				padding-top: 0;

				.item-data-delete {
					display: flex;
				}
			}

			&:before {
				top: calc(var(--item-padding-l));
				height: calc(100% - var(--item-padding-l) - var(--item-padding-l));
			}
		}

	}
}
</style>