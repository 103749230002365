import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import MenuView from "@/new-menu/views/MenuView.vue";


const routes = [
	{
		path: "/",
		component: MenuView,
	},
	{
		path: "/:menuId",
		component: MenuView,
		children: [
			{
				path: ":itemSlug",
				component: MenuView,
			}
		]
	},
];

const router = createRouter({
	history: import.meta.env.VITE_HISTORY_MODE === 'web' ? createWebHistory('/') : createWebHashHistory('/'),
	routes,
});

function hasQueryParams(route) {
	return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
	if(!hasQueryParams(to) && hasQueryParams(from)){
		next({...to, query: from.query});
	} else {
		next();
	}
});

export default router;