<template>
	<div class="menu-adders">
		<div class="menu-icon" v-html="getIcon('iconMinus')" @click.stop="$emit('change', -1)"></div>
		<div class="menu-icon" v-html="getIcon('iconPlus')" @click.stop="$emit('change', 1)"></div>
	</div>
</template>

<script>
import {useSvgStore} from "@/shared/stores";

export default {
	setup() {
		let svgStore = useSvgStore();
		return {getIcon: svgStore.get}
	},
	name: "MenuAdders",
	emits: ["change"],
}
</script>

<style scoped lang="scss">
.menu-adders {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	.menu-icon {
		width: 22px;
		height: 22px;
		cursor: pointer;
		padding: 5px;

		:deep(svg) {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.fill {
				fill: var(--business-color);
			}

			.stroke {
				stroke: var(--business-color);
			}
		}
	}
}
</style>