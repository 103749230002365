<template>
  <div class="product-details-bottom-sheet">
	  <AppBottomSheet id="product-details-bottom-sheet" :z-index="zIndex" :forceWidth="bottomSheetWidth" :active="menusStore.selectedItemId !== null" @toggling-popup="togglingPopup">
		  <div class="selected-product" v-if="sheetActiveDelayed" :style="itemStyle">
			  <div class="loaded-product" v-if="menusStore.selectedItem && menusStore.selectedItem.loadedExtras">
				  <div class="head-container">
					  <div class="details-head">
						  <div class="head-left">
							  <div class="title"><span class="quantity" :class="{show: itemQuantity > 0}">{{ "x"+itemQuantity }}</span><span class="title-span">{{ menusStore.selectedItem.nameTranslation }}</span></div>
						  </div>
						  <div class="head-right"></div>
					  </div>
					  <div class="details-head">
						  <div class="head-left">
							  <div class="category-tag" :style="'background-color:'+categoryTag.color+';color:'+categoryTag.textColor+';'">{{ categoryTag.nameTranslation }}</div>
						  </div>
						  <div class="head-right" v-if="!hidePrices">{{ languageStore.readableEurValue(menusStore.selectedItem.price/100) }}</div>
					  </div>
				  </div>
				  <div class="details-body">
					  <div class="carousel-container" v-if="carouselItemsNum > 0">
						  <Carousel :items-to-show="1" :autoplay="carouselItemsNum > 1 && !itemVideo ? 7500 : null" :wrap-around="carouselItemsNum > 1" :touch-drag="carouselItemsNum > 1" :mouse-drag="carouselItemsNum > 1" v-model="currentSlide">
							  <Slide v-if="itemVideo">
								  <div class="carousel-item">
									  <iframe :src="'https://www.youtube.com/embed/'+itemVideo+'?rel=0&modestbranding=1&controls=1'" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								  </div>
							  </Slide>

							  <Slide v-for="slide in itemImages" :key="slide.menu_item_image_id">
								  <div class="carousel-item">
									  <img :src="slide.url" :alt="menusStore.selectedItem.nameTranslation">
								  </div>
							  </Slide>

							  <template #addons>
								  <Pagination v-if="carouselItemsNum > 1" />
							  </template>
						  </Carousel>
					  </div>
					  <div class="descriptions-container" v-if="menusStore.selectedItem.ingredientsFull || menusStore.selectedItem.long_description || menusStore.selectedItem.materials">
						  <div class="description" v-if="menusStore.selectedItem.ingredientsFull">
							  <div class="description-title">{{ getString('description') }}</div>
							  <div class="description-value">{{ menusStore.selectedItem.ingredientsFullTranslation }}</div>
						  </div>
						  <div class="line-description" v-if="menusStore.selectedItem.ingredientsFull && menusStore.selectedItem.long_description"></div>
						  <div class="description" v-if="menusStore.selectedItem.long_description">
							  <div class="description-title">{{ getString('long_description') }}</div>
							  <div class="description-value">{{ menusStore.selectedItem.long_descriptionTranslation }}</div>
						  </div>
						  <div class="line-description" v-if="menusStore.selectedItem.long_description && menusStore.selectedItem.materials"></div>
						  <div class="description" v-if="menusStore.selectedItem.materials">
							  <div class="description-title">{{ getString(menusStore.business.type === 'other' ? 'materials' : 'ingredients') }}</div>
							  <div class="description-value">{{ menusStore.selectedItem.materialsTranslation }}</div>
						  </div>
					  </div>
					  <div class="lists-container" v-if="menusStore.selectedItem.lists.length > 0">
						  <ItemList :ref="'list-'+list.menu_item_list_id" :list="list" v-for="list in menusStore.selectedItem.lists" :key="list.menu_item_list_id" :hide-prices="hidePrices" />
					  </div>
					  <div class="notes-container" v-if="menusStore.selectedItem.note && productIsOrderable">
						  <div class="notes-title">{{ getString('product_notes') }}</div>
						  <div class="notes-value"><MenuTextAreaInput ref="product-notes" :placeholder="getString('product_notes')" /></div>
					  </div>
				  </div>
				  <div class="details-footer">
					  <div class="icon-edit-quantity" v-if="productIsOrderable" :class="{disabled: quantityModifier <= 1}" v-html="getIcon('iconMinus')" @click="quantityModifier > 1 ? quantityModifier-- : quantityModifier"></div>
					  <MenuButton class="quantity-button" :title="addBtnTitle" @click="addItem" />
					  <div class="icon-edit-quantity" v-if="productIsOrderable" :class="{disabled: quantityModifier >= maxQuantity}" v-html="getIcon('iconPlus')" @click="quantityModifier++"></div>
				  </div>
			  </div>
			  <div class="loading-product" v-else><AppLoader :color="'#'+menusStore.business.color" /></div>
		  </div>
	  </AppBottomSheet>
  </div>
</template>

<script>
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import {useCartStore, useMenusStore, useTranslationStore} from "@/new-menu/stores";
import AppLoader from "@/shared/components/AppLoader.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {isContrastEnoughForText, isTextColorDark} from "@/shared/helpers/ui";
import {useLanguageStore, useNotificationStore, useSvgStore} from "@/shared/stores";
import {Slide, Carousel, Pagination} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css';
import ItemList from "@/new-menu/components/modals/ItemList.vue";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import MenuTextAreaInput from "@/new-menu/components/generic/MenuTextAreaInput.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let languageStore = useLanguageStore();
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let translationStore = useTranslationStore();
		let notificationsStore = useNotificationStore();
		return {cartStore, languageStore, getString: languageStore.getString, getIcon: svgStore.get, menusStore, translationStore, notificationsStore}
	},
	name: "ProductDetailsBottomSheet",
	components: {MenuTextInput, MenuButton, ItemList, Slide, Carousel, Pagination, AppLoader, AppBottomSheet, MenuTextAreaInput},
	mixins: [WindowWidth],
	props: {
		zIndex: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			currentSlide: 0,
			quantityModifier: 1,
            sheetActiveDelayed: false,
			togglingPopupTimeout: null
		}
	},
	methods: {
		togglingPopup(isOpen) {
			if (!isOpen) {
				this.menusStore.selectedItemId = null;
				clearTimeout(this.togglingPopupTimeout);
                this.togglingPopupTimeout = setTimeout(() => {
                    this.sheetActiveDelayed = false;
					this.menusStore.selectedItemId = null;
                }, 500);
			} else {
                this.sheetActiveDelayed = true;
            }
		},
		addItem() {
			if(!this.productIsOrderable) {
				this.menusStore.selectedItemId = null;
				return;
			}
			let unsatisfiedLists = this.menusStore.selectedItem.lists.filter(list => {
				if(this.$refs["list-"+list.menu_item_list_id]) {
					return !this.$refs["list-"+list.menu_item_list_id][0].areConditionsMatched;
				} else {
					return false;
				}
			});
			if(unsatisfiedLists.length === 0) {
				let products = [];
				this.menusStore.selectedItem.lists.forEach(list => {
					if(this.$refs["list-"+list.menu_item_list_id]) {
						this.$refs["list-"+list.menu_item_list_id][0].selectedProducts.forEach(product => {
							products.push({
								menu_item_list_product_id: product.menu_item_list_product_id,
								quantity: product.quantity,
								product_name: product.product_name,
								product_nameTranslation: product.product_nameTranslation,
								product_price: product.product_price,
								warehouse_sku: product.warehouse ? product.sku : null,
							});
						});
					}
				});
				let notes = this.menusStore.selectedItem.note && this.$refs["product-notes"] ? this.$refs["product-notes"].currentValue : null;
				this.cartStore.addItemComposite(this.menusStore.selectedItem, this.menusStore.getProductCategory(this.menusStore.selectedItem), products, notes, this.quantityModifier);
				this.reset();
				this.menusStore.selectedItemId = null;
			} else {
				if(unsatisfiedLists.length === 1) {
					this.notificationsStore.showNotification("conditions_not_matched_list", [unsatisfiedLists[0].nameTranslation]);
				} else {
					this.notificationsStore.showNotification("conditions_not_matched_multiple_lists", [unsatisfiedLists.length]);
				}
				let firstList = this.$refs["list-"+unsatisfiedLists[0].menu_item_list_id][0];
				firstList.$el.scrollIntoView({behavior: "smooth", block: "start"});
				firstList.alertList();
			}
		},
		reset() {
			this.quantityModifier = 1;
			this.currentSlide = 0;
			clearTimeout(this.togglingPopupTimeout);
		}
	},
	computed: {
		hidePrices() {
			return !this.menusStore.business.options.hide_prices && this.menusStore.selectedItem && this.menusStore.selectedItem.orderability === 'no_price';
		},
		productIsOrderable() {
			return this.menusStore.orderMode !== 'none' && this.menusStore.selectedItem && this.menusStore.selectedItem.orderability !== "disabled" && this.menusStore.selectedItem.orderability !== "no_price";
		},
		addBtnTitle() {
			let quantityTitle = this.quantityModifier > 1 ? this.getString('add_x', [this.quantityModifier]) : this.getString('add');
			return this.productIsOrderable ? quantityTitle : this.getString(this.menusStore.business.type === 'other' ? 'back_to_catalog' : 'back_to_menu');
		},
		itemStyle() {
			if(this.menusStore.selectedItemId && this.menusStore.selectedItem.color) {
				let color = this.menusStore.selectedItem.color;
				let textColor = isTextColorDark(color) ? "#000000" : "#FFFFFF";
				return {
					"--business-color": "#"+color,
					"--business-color-alpha20": "#"+color+"33",
					"--business-color-alpha40": "#"+color+"66",
					"--business-color-alpha60": "#"+color+"99",
					"--business-color-alpha80": "#"+color+"CC",
					"--business-text-color": textColor,
				}
			} else if(this.menusStore.selectedMenu && this.menusStore.selectedMenu.color) {
				let color = this.menusStore.selectedMenu.color;
				let textColor = isTextColorDark(color) ? "#000000" : "#FFFFFF";
				return {
					"--business-color": "#"+color,
					"--business-color-alpha20": "#"+color+"33",
					"--business-color-alpha40": "#"+color+"66",
					"--business-color-alpha60": "#"+color+"99",
					"--business-color-alpha80": "#"+color+"CC",
					"--business-text-color": textColor,
				}
			} else {
				return "";
			}
		},
		maxQuantity() {
			let maxQuantity = 99;
			if(this.menusStore.selectedItem) {
				if(this.menusStore.selectedItem.availableQuantity !== null) {
					maxQuantity = Math.min(maxQuantity, this.menusStore.selectedItem.availableQuantity-this.itemQuantity);
				}
				let category = this.menusStore.getProductCategory(this.menusStore.selectedItem);
				if(category && category.activeQuantity !== null) {
					maxQuantity = Math.min(maxQuantity, category.activeQuantity-this.itemQuantity);
				}
			}
			return maxQuantity;
		},
		itemVideo() {
			return this.menusStore.selectedItem.video_link ? this.menusStore.selectedItem.video_link : null;
		},
		itemImages() {
			let result = [];
			if(!this.menusStore.selectedItem) {
				return result;
			}
			let imageFull = this.menusStore.selectedItem.picture ? this.menusStore.getFullImageUrl(this.menusStore.selectedItem.picture) : null;
			if(imageFull) {
				result.push({menu_item_image_id: 0, url: imageFull});
			}
			result = result.concat(this.menusStore.selectedItem.images);
			return result;
		},
		carouselItemsNum() {
			return this.itemImages.length + (this.itemVideo ? 1 : 0);
		},
		bottomSheetWidth() {
			if (this.windowWidth < 550) {
				return "100%";
			} else if (this.windowWidth < 768) {
				return "550px";
			} else {
				return "720px";
			}
		},
		categoryTag() {
			let category = this.menusStore.getProductCategory(this.menusStore.selectedItem);
			if(category) {
				let color = category.color ? category.color : this.menusStore.business.color;
				return {
					name: category.name,
					nameTranslation: category.nameTranslation,
					color: "#"+color,
					textColor: isTextColorDark(color) ? "#000000" : "#ffffff"
				};
			} else {
				return {
					name: "Other",
					nameTranslation: "Altro",
					color: this.menusStore.otherCategoryColor,
					textColor: isTextColorDark(this.menusStore.otherCategoryColor) ? "#000000" : "#ffffff"
				};
			}
		},
		itemQuantity() {
			return this.cartStore.getItemQuantity(this.menusStore.selectedItem);
		},
	},
	watch: {
		"menusStore.selectedItemId"(newVal, oldVal) {
			if(newVal !== oldVal) {
				if (newVal) {
					if(!this.menusStore.selectedItem.loadedExtras) {
						let selectedItemId = this.menusStore.selectedItemId;
						let originalItem = this.menusStore.getOriginalItemById(selectedItemId);
						if(originalItem) {
							this.menusStore.getItemExtras(originalItem, () => {
								if(!this.menusStore.selectedItemId || this.menusStore.selectedItemId !== selectedItemId) {
									return;
								}
								originalItem.loadedExtras = true;
								if(this.menusStore.shouldTranslate) {
									this.translationStore.translateItemExtras(this.menusStore.currentLanguage, originalItem, () => {
									});
								}
							});
						}
					}
					this.reset();
				} else {
                    if(this.$route.params.menuId) {
                        this.$router.push("/" + this.$route.params.menuId + "/");
                    } else {
                        this.$router.push("/");
                    }
				}
			}
		}
	}
}
</script>

<style lang="scss">
#product-details-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>

<style scoped lang="scss">
.selected-product {

	.loading-product {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: var(--padding-vertical) var(--padding-horizontal);
	}

	.loaded-product {
		padding: var(--padding-vertical) var(--padding-horizontal) 0;

		.head-container {
			display: flex;
			flex-direction: column;
			padding: var(--padding-vertical) var(--padding-horizontal);
			border-radius: var(--rounded-corner);
			background-color: var(--theme-bg2-color);

			.details-head {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;

				.head-left {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;

					.title {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						width: 100%;

						.quantity {
							font-size: var(--font-size-xl);
							line-height: var(--line-height-xl);
							color: var(--business-color);
							font-weight: 700;
							padding-right: 0;
							max-width: 0;
							transition: max-width 0.2s ease-in-out, padding-right 0.2s ease-in-out;
							display: inline-block;
							overflow: hidden;

							&.show {
								max-width: 50px;
								padding-right: 6px;
							}
						}

						.title-span {
							font-size: var(--font-size-xl);
							line-height: var(--line-height-xl);
							color: var(--theme-txt1-color);
							@include truncateString(3);
						}
					}

					.category-tag {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						padding: 2px 8px;
						border-radius: var(--rounded-corner);
						margin-top: 6px;
						max-width: 160px;
						@include truncateString();
					}
				}

				.head-right {
					font-size: 18px;
					line-height: 18px;
					color: var(--theme-txt1-color);
					font-weight: 700;
				}
			}
		}

		.details-body {
			display: flex;
			flex-direction: column;
			padding-top: var(--padding-vertical);
			padding-bottom: 65px;

			.carousel-container {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: var(--padding-vertical);
				min-height: 300px;

				--vc-pgn-height: 3px;
				--vc-pgn-active-color: var(--business-color);
				--vc-pgn-background-color: var(--theme-txt1-alpha20-color);

				.carousel {
					width: 100%;
				}

				.carousel-item {
					width: 100%;
					height: 300px;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
						border-radius: var(--rounded-corner);
						max-width: 600px;
					}

					iframe {
						width: 100%;
						height: 100%;
						border-radius: var(--rounded-corner);
						max-width: 600px;
						border: none;
					}

				}


			}

			.descriptions-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-bottom: var(--padding-vertical);
				background-color: var(--theme-bg2-color);
				border-radius: var(--rounded-corner);
				padding: var(--padding-vertical) var(--padding-horizontal);

				.description {
					display: flex;
					flex-direction: column;

					.description-title {
						font-size: var(--font-size);
						line-height: var(--line-height);
						color: var(--theme-txt1-color);
						font-weight: 700;
						@include truncateString(2);
					}

					.description-value {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--theme-txt2-color);
						margin-top: 4px;
						overflow: hidden;
						text-overflow: ellipsis;
					}

				}

				.line-description {
					width: 100%;
					height: 1px;
					background-color: var(--theme-txt1-alpha20-color);
					margin: var(--padding-vertical) 0;
				}

			}

			.notes-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-bottom: var(--padding-vertical);
				border-radius: var(--rounded-corner);

				.notes-title {
					font-size: var(--font-size);
					line-height: var(--line-height);
					color: var(--theme-txt1-color);
					font-weight: 700;
					@include truncateString(2);
					padding: var(--padding-vertical) var(--padding-horizontal);
					background-color: var(--theme-bg2-color);
					border-top-left-radius: var(--rounded-corner);
					border-top-right-radius: var(--rounded-corner);
				}

				.notes-value {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					border-bottom-left-radius: var(--rounded-corner);
					border-bottom-right-radius: var(--rounded-corner);

					&:deep(textarea) {
						padding: var(--padding-vertical) var(--padding-horizontal);
						background-color: var(--theme-bg2-alpha40-color);
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				}
			}

		}

		.details-footer {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: var(--padding-vertical) var(--padding-horizontal);
			background-color: var(--theme-bg2-color);
			border-top-right-radius: var(--rounded-corner);
			border-top-left-radius: var(--rounded-corner);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border: 2px solid var(--business-color);
			border-bottom: none;

			.icon-edit-quantity {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 6px;
				margin: 0 6px;
				cursor: pointer;

				&:deep(svg) {
					width: 14px;
					height: 14px;

					.fill {
						fill: var(--business-color);
					}

					.stroke {
						stroke: var(--business-color);
					}
				}

				&.disabled {
					pointer-events: none;
					opacity: .5;
				}
			}

			.quantity-button {
				min-width: 150px;
			}
		}

	}

}
</style>