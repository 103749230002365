<template>
	<div class="item-list-container" :class="{alert: alert}">
		 <div class="item-list-header">
			 <div class="item-list-title">{{ list.nameTranslation }}</div>
			 <div class="item-list-value">
				 <div class="list-warn" :class="{matched: minMatched}" v-if="list.select_min > 0">{{ minWord }}</div>
				 <div class="list-warn" :class="{matched: maxMatched}" v-if="list.select_max > 0">{{ maxWord }}</div>
			 </div>
		 </div>
		<div class="item-list-body">
			<ItemListProduct :ref="'product-'+product.menu_item_list_product_id" v-for="product in listProducts" :key="product.menu_item_list_product_id" :product="product" :quantity="getItemQuantity(product)" :checkbox="isCheckbox" @change="v => changeQuantity(product, v)" @toggle="on => toggleProduct(product, on)" :hide-prices="hidePrices" />
			<div class="no-products" v-if="listProducts.length === 0">{{ getString('no_choice_available') }}</div>
		</div>
	</div>
</template>

<script>
import ItemListProduct from "@/new-menu/components/modals/ItemListProduct.vue";
import {useLanguageStore, useNotificationStore} from "@/shared/stores";

export default {
	setup() {
		let notificationsStore = useNotificationStore();
		return {getString: useLanguageStore().getString, notificationsStore}
	},
	name: "ItemList",
	components: {ItemListProduct},
	data() {
		return {
			selectedProducts: [],
			alert: false,
		}
	},
	props: {
		list: {
			type: Object,
			required: true,
			validator(value) {
				return value && value.products && value.products.length > 0;
			}
		},
		hidePrices: {
			type: Boolean,
			default: false,
		}
	},
	computed: {
		minWord() {
			if(this.list.select_min === 1) {
				return this.getString('mandatory');
			} else {
				return this.getString('min_x', [this.list.select_min]);
			}
		},
		maxWord() {
			return this.getString('max_x', [this.list.select_max]);
		},
		isCheckbox() {
			return this.list.select_max === 1 || !this.list.select_multi;
		},
		minMatched() {
			return this.list.select_min === 0 || this.getTotalItemQuantity() >= this.list.select_min;
		},
		maxMatched() {
			return this.list.select_max === 0 || this.getTotalItemQuantity() <= this.list.select_max;
		},
		areConditionsMatched() {
			return this.minMatched && this.maxMatched;
		},
		listProducts() {
			return this.list.products.filter(p => p.available);
		}
	},
	methods: {
		alertList() {
			this.alert = true;
			setTimeout(() => {
				this.alert = false;
			}, 1000);
		},
		getTotalItemQuantity() {
			return this.selectedProducts.reduce((acc, product) => acc + product.quantity, 0);
		},
		getItemQuantity(product) {
			let index = this.selectedProducts.findIndex(p => p.menu_item_list_product_id === product.menu_item_list_product_id);
			if(index >= 0) {
				return this.selectedProducts[index].quantity;
			} else {
				return 0;
			}
		},
		changeQuantity(product, quantityDelta) {
			let index = this.selectedProducts.findIndex(p => p.menu_item_list_product_id === product.menu_item_list_product_id);
			if(quantityDelta > 0) {
				let quantity = this.getTotalItemQuantity();
				if(this.list.select_max > 0 && quantity >= this.list.select_max) {
					if(this.list.select_max === 1) {
						this.notificationsStore.showNotification('max_choice_list_single', [this.list.select_max]);
					} else {
						this.notificationsStore.showNotification('max_choice_list', [this.list.select_max, this.list.nameTranslation]);
					}
					return;
				}
			}
			if(index >= 0) {
				this.selectedProducts[index].quantity += quantityDelta;
				if(this.selectedProducts[index].quantity <= 0) {
					this.selectedProducts.splice(index, 1);
				}
			} else {
				if(quantityDelta <= 0) return;
				this.selectedProducts.push({...product, quantity: 1});
			}
		},
		toggleProduct(product, on) {
			if(this.isCheckbox && this.list.select_max === 1) {
				//deselect all other products
				this.list.products.forEach(p => {
					if(p.menu_item_list_product_id !== product.menu_item_list_product_id) {
						if(this.$refs['product-'+p.menu_item_list_product_id]) {
							this.$refs['product-'+p.menu_item_list_product_id][0].checked = false;
						}
					} else {
						if(this.$refs['product-'+p.menu_item_list_product_id]) {
							this.$refs['product-'+p.menu_item_list_product_id][0].checked = on;
						}
					}
				});
				this.selectedProducts = [];
			} else if(on) {
				let quantity = this.getTotalItemQuantity();
				let isProductChecked = this.selectedProducts.findIndex(p => p.menu_item_list_product_id === product.menu_item_list_product_id) >= 0;
				if(this.list.select_max > 0 && quantity >= this.list.select_max && !isProductChecked) {
					if(this.$refs['product-'+product.menu_item_list_product_id]) {
						this.$refs['product-'+product.menu_item_list_product_id][0].checked = false;
					}
					if(this.list.select_max === 1) {
						this.notificationsStore.showNotification('max_choice_list_single', [this.list.select_max]);
					} else {
						this.notificationsStore.showNotification('max_choice_list', [this.list.select_max, this.list.nameTranslation]);
					}
					return;
				}
			}
			if(on) {
				this.selectedProducts.push({...product, quantity: 1});
			} else {
				let index = this.selectedProducts.findIndex(p => p.menu_item_list_product_id === product.menu_item_list_product_id);
				if(index >= 0) {
					this.selectedProducts.splice(index, 1);
				}
			}
		},
	}
}
</script>

<style scoped lang="scss">
.item-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: var(--padding-vertical);
	position: relative;

	&:after {
		content: "";
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: var(--rounded-corner);
		background: var(--business-color);
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
		pointer-events: none;
	}

	&.alert {
		&:after {
			opacity: 0.15;
		}
	}

	.item-list-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: var(--padding-vertical) var(--padding-horizontal);
		background-color: var(--theme-bg2-color);
		border-top-left-radius: var(--rounded-corner);
		border-top-right-radius: var(--rounded-corner);

		.item-list-title {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			font-weight: 700;
			margin-right: 6px;
		}

		.item-list-value {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			gap: 6px;

			.list-warn {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				border-radius: var(--rounded-corner);
				color: var(--business-text-color);
				background-color: var(--business-color-alpha40);
				padding: 2px 8px;
				transition: background-color .3s ease;

				&:hover {
					background-color: var(--business-color-alpha60);
				}

				&.matched {
					background-color: var(--business-color);
				}
			}
		}
	}

	.item-list-body {
		background-color: var(--theme-bg2-alpha40-color);
		border-bottom-left-radius: var(--rounded-corner);
		border-bottom-right-radius: var(--rounded-corner);
		display: flex;
		flex-direction: column;
		width: 100%;

		.item-list-product {
			position: relative;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--theme-bg2-color);
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

		}

		.no-products {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			text-align: left;
			padding: var(--padding-vertical) var(--padding-horizontal);
		}
	}

}
</style>