<template>
	<div class="switch">
		<div ref="switch-container" :data-item-active="currentActiveItem" :class="containerClass" class="switch-container">
			<div class="switch-slider"></div>
			<div class="row centered switch-row-container">
				<div v-for="(item, index) in items" class="switch-selector" :class="'option-'+index" @click="clickItem(index)"><p>{{ item }}</p></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MenuTabs",
	emits: ["switched"],
	data() {
		return {
			currentActiveItem: Number(this.activeItem),
		}
	},
	props: {
		activeItem: {type: Number, default: 0},
		items: {type: Array, required: true, validator(value) { return value.length > 1 && value.length <= 2 && value.every(item => { return typeof item === 'string'; }); }},
	},
	methods: {
		clickItem(index) {
			if (this.currentActiveItem !== index) {
				this.currentActiveItem = index;
			} else {
				this.currentActiveItem = index === 0 ? 1 : 0;
			}
			this.$emit('switched', this.currentActiveItem);
		}
	},
	computed: {
		containerClass() {
			return 'elements'+this.items.length;
		}
	},
	watch: {
		activeItem(value) {
			this.currentActiveItem = value;
		}
	},
}
</script>

<style lang="scss" scoped>
.switch {
	@include centered;
	@include columnFlex;


	.switch-container {
		position: relative;
		display: inline-block;
		height: 40px;
		cursor: pointer;
		width: 100%;

		.switch-row-container {
			height: 100%;
			position: absolute;
			top: 0;
			padding: 0 4px;

			.switch-selector {
				display: flex;
				@include centered;
				height: 100%;
				width: 100px;

				p {
					@include truncateString;
					transition: color .3s $transition;
					font-size: var(--font-size);
					line-height: var(--line-height);
					padding: 0 10px;
					width: 100%;
					margin-right: auto;
					color: var(--theme-txt2-color);
					z-index: 1;
					text-align: center;
					margin-bottom: 0;
				}
			}
		}

		.switch-slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: var(--theme-bg1-color);
			border-radius: var(--rounded-corner);

			&:before {
				position: absolute;
				content: "";
				height: 32px;
				left: 4px;
				top: 50%;
				transform: translateY(-50%);
				background-color: var(--business-color);
				-webkit-transition: left .3s $transition;
				transition: left .3s $transition;
				border-radius: var(--rounded-corner);
				box-shadow: inset var(--theme-box-shadow);
			}
		}

		&[data-item-active="0"] {
			.row .switch-selector:nth-of-type(1) p {
				color: var(--business-text-color);
			}
		}

		&[data-item-active="1"] {
			.row .switch-selector:nth-of-type(2) p {
				color: var(--business-text-color);
			}
		}

		&.elements2 {
			.switch-slider:before {
				width: calc((100% - 8px) / 2);
			}

			.switch-selector {
				width: 50%;
			}

			&[data-item-active="1"] .switch-slider:before {
				left: 50%;
			}
		}

		@media (max-width: 460px) {

			.switch-selector p {
				padding: 0 6px;
			}
		}

		@media (max-width: 300px) {

			.switch-selector p {
				font-size: 10px;
				padding: 0 4px;
			}
		}
	}
}
</style>