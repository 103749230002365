<template>
	<div class="menu-header-rating" @click="activeSheet = true">
		<StarsRating :rating="menusStore.business.average_rating" not-editable horizontal />
		<AppBottomSheet id="bottom-sheet-reviews" :force-width="bottomSheetWidth" :active="activeSheet" @toggling-popup="toggleSheet">
			<div class="reviews-data-container" v-if="activeSheetDelayed">
				<div class="section-title"><div class="title">{{ getString('reviews') }}</div></div>
				<div class="loading" v-if="loadingReviews"><AppLoader /></div>
				<div class="reviews-data" v-else>
					<div class="rating-container">
						<div class="rating-title">{{ getString('quality') }}</div>
						<div class="rating">
							<StarsRating :rating="qualityRating" not-editable horizontal number-only />
						</div>
					</div>
					<div class="rating-container">
						<div class="rating-title">{{ getString('service') }}</div>
						<div class="rating">
							<StarsRating :rating="serviceRating" not-editable horizontal number-only />
						</div>
					</div>
					<div class="rating-container">
						<div class="rating-title">{{ getString('price') }}</div>
						<div class="rating">
							<StarsRating :rating="pricingRating" not-editable horizontal number-only />
						</div>
					</div>
					<div class="rating-container">
						<div class="rating-title">{{ getString('location') }}</div>
						<div class="rating">
							<StarsRating :rating="locationRating" not-editable horizontal number-only />
						</div>
					</div>
					<div class="reviews-count">{{ getString('reviews_x', [reviewsCount]) }}</div>
					<div class="reviews-help">{{ getString('leave_review_after_order') }}</div>
				</div>
			</div>
		</AppBottomSheet>
	</div>
</template>

<script>
import StarsRating from "@/order/components/StarsRating.vue";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import AppLoader from "@/shared/components/AppLoader.vue";
import {useMenusStore} from "@/new-menu/stores";
import API from "@/shared/helpers/API";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {useLanguageStore} from "@/shared/stores";

export default {
	setup() {
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		return {menusStore, getString: languageStore.getString}
	},
	name: "MenuHeaderRating",
	components: {AppLoader, AppBottomSheet, StarsRating},
	mixins: [WindowWidth],
	data() {
		return {
			activeSheet: false,
            activeSheetDelayed: false,
			loadingReviews: false,
			qualityRating: 0,
			locationRating: 0,
			serviceRating: 0,
			pricingRating: 0,
			reviewsCount: 0,
		}
	},
	computed: {
		bottomSheetWidth() {
			if (this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
	},
	methods: {
		toggleSheet(isOpen) {
			if(isOpen) {
				if(this.reviewsCount === 0) {
					this.loadReviews();
				}
                this.activeSheetDelayed = true;
			} else {
				this.activeSheet = false;
                setTimeout(() => {
                    this.activeSheetDelayed = false;
                }, 500);
			}
		},
		loadReviews() {
			this.loadingReviews = true;
			API.init().getBusinessFeedbacks(this.menusStore.business.business_id, (r) => {
				this.loadingReviews = false;
				if(r && r.result && r.result === "OK" && r.feedbacks) {
					this.qualityRating = Number(r.feedbacks.quality);
					this.locationRating = Number(r.feedbacks.location);
					this.serviceRating = Number(r.feedbacks.service);
					this.pricingRating = Number(r.feedbacks.pricing);
					this.reviewsCount = Number(r.feedbacks.count);
				} else {
					this.activeSheet = false;
				}
			});
		}
	},
}
</script>

<style lang="scss">
#bottom-sheet-reviews {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>

<style scoped lang="scss">
:deep(.rating) {
	font-size: var(--font-size-s) !important;
	line-height: var(--line-height-s) !important;
	color: var(--theme-txt2-color) !important;
	opacity: 1 !important;

	span {
		font-size: var(--font-size-s) !important;
		line-height: var(--line-height-s) !important;
		color: var(--theme-txt2-color) !important;
	}
}

.menu-header-rating {
	cursor: pointer;
}

.reviews-data-container {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal) 0;


	.section-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		margin-bottom: var(--padding-vertical);

		.title {
			font-family: var(--font-family);
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			@include truncateString();
		}

	}

	.loading {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: var(--padding-vertical) var(--padding-horizontal);
	}

	.reviews-data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: var(--padding-vertical);

		.rating-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: var(--padding-vertical);

			.rating-title {
				font-family: var(--font-family);
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt1-color);
				font-weight: 700;
			}

			.rating {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
			}
		}

		.reviews-count {
			font-family: var(--font-family);
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
		}

		.reviews-help {
			font-family: var(--font-family);
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			margin-top: var(--padding-vertical);
			text-align: left;
		}
	}

}
</style>