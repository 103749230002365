<template>
  <div class="image-zoom-popup" :class="{isOpen}" v-if="isOpenDelayed" :style="itemStyle">
	  <div class="image-zoom-popup-content" @click.self="clickClose">
		  <div class="image-zoom-popup-image">
			  <AppLoader v-if="!imageLoaded" :color="'#'+menusStore.business.color" />
			  <img :class="{loading: !imageLoaded, loadedDelay: imageLoadedDelay}" v-if="imageFull && !fullImageFailed" :src="imageFull" :alt="itemName" @error="fullImageFailed = true" @load="setImageLoaded()" />
			  <img :class="{loading: !imageLoaded, loadedDelay: imageLoadedDelay}" v-if="imageThumbnail && fullImageFailed" :src="imageThumbnail" :alt="itemName" @load="setImageLoaded()" />
		  </div>
		  <div class="image-zoom-popup-actions">
			  <div class="actions-container">
				  <div class="icon-edit-quantity" v-if="productIsOrderable" :class="{disabled: quantityModifier <= 1}" v-html="getIcon('iconMinus')" @click="quantityModifier > 1 ? quantityModifier-- : quantityModifier"></div>
				  <div class="add-to-cart-btn"><MenuButton :title="addBtnTitle" @click="addItem" /></div>
				  <div class="icon-edit-quantity" v-if="productIsOrderable" :class="{disabled: quantityModifier >= maxQuantity}" v-html="getIcon('iconPlus')" @click="quantityModifier++"></div>
			  </div>
		  </div>
		  <div class="image-zoom-popup-close" v-html="getIcon('iconX')" @click="clickClose"></div>
	  </div>
  </div>
</template>

<script>
import {useLanguageStore, useSvgStore} from "@/shared/stores";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import AppLoader from "@/shared/components/AppLoader.vue";
import {isContrastEnoughForText, isTextColorDark} from "@/shared/helpers/ui";

export default {
	components: {AppLoader, MenuButton},
	setup() {
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let cartStore = useCartStore();
		let languageStore = useLanguageStore();
		return {getIcon: svgStore.get, menusStore, cartStore, getString: languageStore.getString};
	},
	name: "ImageZoomPopup",
	data() {
		return {
			isOpen: false,
			isOpenDelayed: false,
			fullImageFailed: false,
			quantityModifier: 1,
			imageLoaded: false,
			imageLoadedDelay: false
		}
	},
	computed: {
		itemStyle() {
			if(this.menusStore.imageZoomItem && this.menusStore.imageZoomItem.color) {
				let color = this.menusStore.imageZoomItem.color;
				let textColor = isTextColorDark(color) ? "#000000" : "#FFFFFF";
				return {
					"--business-color": "#"+color,
					"--business-color-alpha20": "#"+color+"33",
					"--business-color-alpha40": "#"+color+"66",
					"--business-color-alpha60": "#"+color+"99",
					"--business-color-alpha80": "#"+color+"CC",
					"--business-text-color": textColor,
				}
			} else if(this.menusStore.selectedMenu && this.menusStore.selectedMenu.color) {
				let color = this.menusStore.selectedMenu.color;
				let textColor = isTextColorDark(color) ? "#000000" : "#FFFFFF";
				return {
					"--business-color": "#"+color,
					"--business-color-alpha20": "#"+color+"33",
					"--business-color-alpha40": "#"+color+"66",
					"--business-color-alpha60": "#"+color+"99",
					"--business-color-alpha80": "#"+color+"CC",
					"--business-text-color": textColor,
				}
			} else {
				return "";
			}
		},
		imageThumbnail() {
			let thumbnail = this.menusStore.imageZoomItem ? this.menusStore.imageZoomItem.picture : null;
			if(thumbnail === null) return null;
			return thumbnail;
		},
		itemName() {
			return this.menusStore.imageZoomItem ? this.menusStore.imageZoomItem.name : null;
		},
		imageFull() {
			let fullImage = this.menusStore.getFullImageUrl(this.imageThumbnail);
			return fullImage ? fullImage : this.imageThumbnail;
		},
		addBtnTitle() {
			let quantityTitle = this.quantityModifier > 1 ? this.getString('add_x', [this.quantityModifier]) : this.getString('add');
			return this.productIsOrderable ? quantityTitle : this.getString(this.menusStore.business.type === 'other' ? 'back_to_catalog' : 'back_to_menu');
		},
		maxQuantity() {
			let maxQuantity = 99;
			if(this.menusStore.imageZoomItem) {
				if(this.menusStore.imageZoomItem.availableQuantity !== null) {
					let cartQuantity = this.cartStore.getItemQuantity(this.menusStore.imageZoomItem);
					maxQuantity = Math.min(maxQuantity, this.menusStore.imageZoomItem.availableQuantity-cartQuantity);
				}
				let category = this.menusStore.getProductCategory(this.menusStore.imageZoomItem);
				if(category && category.activeQuantity !== null) {
					let cartQuantity = this.cartStore.getCategoryQuantity(category);
					maxQuantity = Math.min(maxQuantity, category.activeQuantity-cartQuantity);
				}
			}
			return maxQuantity;
		},
		productIsOrderable() {
			return this.menusStore.orderMode !== 'none' && this.menusStore.imageZoomItem && this.menusStore.imageZoomItem.orderability !== "disabled" && this.menusStore.imageZoomItem.orderability !== "no_price";
		},
	},
	methods: {
		setImageLoaded() {
			this.imageLoaded = true;
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.imageLoadedDelay = true;
				});
			});
		},
		openPopup() {
			this.quantityModifier = 1;
			this.isOpenDelayed = true;
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					if(!this.isOpen) {
						this.isOpen = true;
						this.isOpenDelayed = this.isOpen;
					}
				});
			});
		},
		closePopup(callback = null) {
			this.isOpen = false;
			setTimeout(() => {
				if(!this.isOpen) {
					this.isOpenDelayed = this.isOpen;
					this.menusStore.imageZoomItem = null;
					if(callback) callback();
				}
			}, 550);
		},
		clickClose() {
			this.closePopup();
		},
		addItem() {
			if(this.productIsOrderable) {
				if(this.menusStore.imageZoomItem) {
					this.cartStore.addItemQuantity(this.menusStore.imageZoomItem, this.menusStore.getProductCategory(this.menusStore.imageZoomItem), this.quantityModifier);
					this.closePopup();
				}
			} else {
				this.closePopup();
			}
		}
	},
	watch: {
		"menusStore.imageZoomItem": {
			handler(newVal, oldVal) {
				if(newVal) {
					this.imageLoaded = false;
					this.imageLoadedDelay = false;
					if(this.menusStore.imageZoomItem && this.menusStore.imageZoomItem.picture) {
						this.openPopup();
					}
				} else {
					this.closePopup();
				}
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.image-zoom-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--theme-txt1-alpha60-color);
	z-index: 500;
	opacity: 0;
	backdrop-filter: blur(0px);
	-webkit-backdrop-filter: blur(0px);
	transition: opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;

	.image-zoom-popup-content {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100%;

		.image-zoom-popup-image {
			width: 80%;
			height: calc(100% - 60px);
			max-width: 1080px;
			max-height: 768px;
			min-height: 200px;
			border-radius: var(--rounded-corner);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			flex-grow: 1;
			padding: calc(var(--padding-vertical) + 26px) var(--padding-horizontal);

			@media (max-width: 768px) {
				width: 92%;
			}

			@media (max-width: 420px) {
				width: 96%;
			}

			picture, img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: var(--rounded-corner);
				opacity: 0;
				transition: opacity 0.2s ease-in-out;

				&.loading {
					display: none;
				}

				&.loadedDelay {
					opacity: 1;
				}
			}
		}

		.image-zoom-popup-actions {
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: translateY(80px);
			transition: transform 0.2s ease-in-out 0.3s;

			.actions-container {
				width: 100%;
				height: 100%;
				max-width: 350px;
				background-color: var(--theme-bg1-color);
				border-top-right-radius: var(--rounded-corner);
				border-top-left-radius: var(--rounded-corner);
				border: 2px solid var(--business-color);
				border-bottom: none;
				display: flex;
				align-items: center;
				justify-content: center;

				.icon-edit-quantity {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 6px;
					margin: 0 6px;
					cursor: pointer;

					&:deep(svg) {
						width: 14px;
						height: 14px;

						.fill {
							fill: var(--business-color);
						}

						.stroke {
							stroke: var(--business-color);
						}
					}

					&.disabled {
						pointer-events: none;
						opacity: .5;
					}
				}

				.add-to-cart-btn {
					display: block;
					min-width: 150px;
				}

			}
		}

		.image-zoom-popup-close {
			position: absolute;
			top: 0;
			right: 0;
			width: 22px;
			height: 22px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding: var(--padding-vertical) var(--padding-horizontal-s);
			box-sizing: content-box;

			:deep(svg) {
				width: 100%;
				height: 100%;

				.fill {
					fill: var(--business-text-color);
				}

				.stroke {
					stroke: var(--business-text-color);
				}
			}
		}
	}

	&.isOpen {
		pointer-events: all;
		opacity: 1;
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);

		.image-zoom-popup-content {
			.image-zoom-popup-actions {
				transform: translateY(0);
			}
		}
	}

}
</style>