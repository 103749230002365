<template>
	<div class="allergens-filter">
		<AppBottomSheet id="allergens-filter-bottom-sheet" :forceWidth="bottomSheetWidth" :active="menusStore.allergensOpen" @toggling-popup="togglingPopup">
			<div class="allergens-filter-content" v-if="openDelayed">
				<div class="section-title"><div class="title">{{ infoMode ? getString('allergens_info') : getString('allergens_filter') }}</div></div>
				<div class="section-description">
					<div class="description" v-if="allergensLink">{{ getString('more_info_allergens_link') }} <a :href="allergensLink" target="_blank">{{ getString('click_here') }}</a></div>
					<div class="description" v-if="!infoMode">{{ getString('allergens_filter_line_1') }}</div>
					<div class="description">{{ getString('allergens_filter_line_2') }}</div>
				</div>
				<div class="section-body">
					<div class="categories-container">
						<div class="category-container" v-for="(allergens, index) in categories" :key="index">
							<div class="category-head">
								<div class="category-title">{{ allergens.length > 0 ? getString(allergens[0].category) : '-' }}</div>
								<MenuSwitch v-if="!infoMode" :active="isCategoryActive(allergens)" @toggle="active => toggleCategory(allergens, active)" />
							</div>
							<div class="category-body">
								<div class="allergens-container">
									<div class="allergen" v-for="allergen in allergens" :key="allergen.allergen_id" :style="'--allergen-color: #'+allergen.color+';'" :class="{withProducts: allergen.itemsIds.length > 0, off: !allergen.active}" @click="toggleAllergen(allergen)">
										<div class="allergen-head">
											<div class="allergen-image"><img :src="allergen.image_link" :alt="getString(allergen.name_key)"></div>
											<div class="allergen-name">{{ getString(allergen.name_key) }}</div>
										</div>
										<div class="allergen-body" v-if="allergen.itemsIds.length > 0">
											<div class="allergen-products">{{ allergen.itemsIds.length === 1 ? getString('product_single') : getString('products_x', [allergen.itemsIds.length]) }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="details-footer">
					<MenuButton class="quantity-button" :title="getString(this.menusStore.business.type === 'other' ? 'back_to_catalog' : 'back_to_menu')" @click="menusStore.allergensOpen = false" />
				</div>
			</div>
		</AppBottomSheet>
		<MenuPopup
			ref="popup-confirm-cart-allergens"
			:title="getString('allergens_in_cart')"
			:body-text="getString('allergens_in_cart_confirm')"
			:buttons="[{text: getString('no'), negative: true, callback: this.removeProductsWithAllergens}, {text: getString('yes'), callback: this.dontRemoveProducts}]" />
	</div>
</template>

<script>
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore, useSvgStore} from "@/shared/stores";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import MenuSwitch from "@/new-menu/components/generic/MenuSwitch.vue";
import MenuPopup from "@/new-menu/components/generic/MenuPopup.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let languageStore = useLanguageStore();
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		return {cartStore, languageStore, getIcon: svgStore.get, getString: languageStore.getString, menusStore}
	},
	name: "AllergensFilter",
	components: {MenuButton, AppBottomSheet, MenuSwitch, MenuPopup},
	mixins: [WindowWidth],
	data() {
		return {
			removingAllergens: [],
			categories: [],
            openDelayed: this.menusStore.allergensOpen,
		}
	},
	computed: {
		bottomSheetWidth() {
			if(this.windowWidth < 550) {
				return "100%";
			} else if(this.windowWidth < 768) {
				return "550px";
			} else if(this.windowWidth < 1080) {
				return "720px";
			} else if(this.windowWidth < 1440) {
				return "960px";
			} else {
				return "1280px";
			}
		},
		infoMode() {
			return !this.menusStore.business.options.allergens_filter;
		},
		allergensLink() {
			return this.menusStore.business.options.allergens_url;
		}
	},
	methods: {
		togglingPopup(isOpen) {
			if (isOpen) {
                this.openDelayed = true;
				if(this.categories.length === 0) {
					this.divideAllergens();
				}
			} else {
                this.menusStore.allergensOpen = false;
                setTimeout(() => {
                    this.openDelayed = false;
                }, 500);
			}
		},
		divideAllergens() {
			let allergens = this.menusStore.allergens;
			let categories = {};
			for (let i = 0; i < allergens.length; i++) {
				let allergen = allergens[i];
				if(["allergens_no_allergens", "allergens_gluten_free"].includes(allergen.name_key)) {
					continue;
				}
				let category = allergen.category;
				if (!categories.hasOwnProperty(category)) {
					categories[category] = [];
				}
				categories[category].push(allergen);
			}
			categories = Object.values(categories);
			categories.forEach((category) => {
				category.sort((a, b) => {
					return b.itemsIds.length - a.itemsIds.length;
				});
			});
			this.categories = categories;
		},
		isCategoryActive(allergens) {
			let active = true;
			for (let i = 0; i < allergens.length; i++) {
				if(!allergens[i].active) {
					active = false;
				}
			}
			return active;
		},
		removeProductsWithAllergens() {
			this.$refs['popup-confirm-cart-allergens'].toggleStatus(false);
			this.cartStore.removeProductsWithAllergens(this.removingAllergens);
			this.removingAllergens = [];
		},
		dontRemoveProducts() {
			this.removingAllergens = [];
			this.$refs['popup-confirm-cart-allergens'].toggleStatus(false);
		},
		toggleCategory(allergens, active) {
			if(this.infoMode) {
				return;
			}
			for (let i = 0; i < allergens.length; i++) {
				allergens[i].active = active;
			}
			this.menusStore.saveAllergensFilter();

			if(!active) {
				let disabledAllergens = allergens.map((a) => a.allergen_id);
				if(this.cartStore.cart.some((item) => {
					let menuItem = this.menusStore.getItemById(item.menu_item_id);
					return menuItem.allergens.find((a) => disabledAllergens.includes(a.allergen_id));
				})) {
					this.removingAllergens = disabledAllergens;
					this.$refs['popup-confirm-cart-allergens'].toggleStatus(true);
				}
			}
		},
		toggleAllergen(allergen) {
			if(this.infoMode) {
				return;
			}
			allergen.active = !allergen.active;
			this.menusStore.saveAllergensFilter();

			if(!allergen.active) {
				if(this.cartStore.cart.some((item) => {
					let menuItem = this.menusStore.getItemById(item.menu_item_id);
					return menuItem.allergens.find((a) => a.allergen_id === allergen.allergen_id);
				})) {
					this.removingAllergens = [allergen.allergen_id];
					this.$refs['popup-confirm-cart-allergens'].toggleStatus(true);
				}
			}
		}
	},
}
</script>

<style lang="scss">
#allergens-filter-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>

<style scoped lang="scss">
.allergens-filter-content {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal) 0;

	.section-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		margin-bottom: var(--padding-vertical);

		.title {
			font-family: var(--font-family);
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			@include truncateString();
		}

	}

	.section-description {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.description {
			font-family: var(--font-family);
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt2-color);
			text-align: center;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}
		}

	}

	.section-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 65px;
		margin-top: var(--padding-vertical);

		.categories-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: stretch;
			width: 100%;
			margin-bottom: 20px;

			.category-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				width: 25%;
				border: 1px solid var(--theme-txt1-alpha20-color);
				border-right: none;
				padding: var(--padding-vertical) var(--padding-horizontal-s);

				&:nth-child(4n+1) {
					border-left: none;
				}

				@media (max-width: 1024px) {
					width: 50%;

					&:nth-child(4n+1) {
						border-left: 1px solid var(--theme-txt1-alpha20-color);
					}

					&:nth-child(2n+1) {
						border-left: none;
					}

					&:nth-child(1),
					&:nth-child(2) {
						border-bottom: none;
					}

				}

				@media (max-width: 350px) {
					width: 100%;
					border: none;
				}

				.category-head {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					margin-bottom: 10px;

					.category-title {
						font-family: var(--font-family);
						font-size: var(--font-size);
						line-height: var(--line-height);
						color: var(--theme-txt1-color);
						font-weight: 700;
						font-style: normal;
						margin-right: 4px;
					}

				}

				.category-body {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;

					.allergens-container {
						display: flex;
						flex-direction: column;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: flex-start;
						width: 100%;
						margin-bottom: 10px;

						.allergen {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
							margin-bottom: 8px;
							transition: opacity 0.2s ease-in-out;
							cursor: pointer;

							.allergen-head {
								display: flex;
								flex-direction: row;
								justify-content: flex-start;
								align-items: center;
								border: 2px solid var(--allergen-color);
								border-radius: var(--rounded-corner-l);
								padding: 3px 6px 3px 3px;
								position: relative;

								.allergen-image {
									width: 22px;
									height: 22px;
									margin-right: 6px;
									background: var(--allergen-color);
									border-radius: var(--rounded-corner);

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
										border-radius: var(--rounded-corner);
									}
								}

								.allergen-name {
									font-family: var(--font-family);
									font-size: var(--font-size-s);
									line-height: var(--line-height-s);
									color: var(--theme-txt1-color);
									font-style: normal;
								}

								&::after {
									content: "";
									position: absolute;
									top: 0;
									left: 0;
									width: calc(100% - 5px);
									height: calc(100% - 5px);
									background: linear-gradient(to bottom right, transparent 48%, var(--allergen-color) 48%, var(--allergen-color) 52%, transparent 52%);
									opacity: 0;
									transition: opacity 0.2s ease-in-out;
								}
							}

							&.withProducts {
								.allergen-head {
									border-bottom-left-radius: 0;

									&::after {
										height: 100%;
									}
								}
							}

							&.off {
								opacity: 0.5;

								.allergen-head {
									&::after {
										opacity: 1;
									}
								}
							}

							.allergen-body {
								display: flex;
								flex-direction: row;
								justify-content: flex-start;
								align-items: center;
								margin-top: 3px;
								margin-left: 8px;
								position: relative;

								.allergen-products {
									font-family: var(--font-family);
									font-size: var(--font-size-s);
									line-height: var(--line-height-s);
									color: var(--theme-txt2-color);
									font-style: normal;
									@include truncateString;
								}

								&::after {
									content: "";
									position: absolute;
									top: -3px;
									left: -8px;
									width: calc(100% + 16px);
									height: calc(100% + 6px);
									background-color: var(--allergen-color);
									opacity: 0.2;
									border-radius: var(--rounded-corner-s);
									border-top-left-radius: 0;
								}

							}
						}
					}
				}
			}
		}
	}

	.details-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: var(--padding-vertical) var(--padding-horizontal);
		background-color: var(--theme-bg2-color);
		border-top-right-radius: var(--rounded-corner);
		border-top-left-radius: var(--rounded-corner);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border: 2px solid var(--business-color);
		border-bottom: none;
		z-index: 10;
	}
}
</style>