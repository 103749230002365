<template>
	<main :class="{menuOnly: menusStore.orderMode === 'none'}">
		<router-view />
		<div class="order-container-spacer" v-if="menusStore.orderMode !== 'none' && windowWidth > 1024"></div>
		<div class="order-container" v-if="menusStore.orderMode !== 'none' && windowWidth > 1024">
			<OrderContainer />
		</div>
		<ImageZoomPopup />
		<ProductDetailsBottomSheet :z-index="1010" />
		<AllergensFilter />
		<BannerCookie />
	</main>
	<NotificationManager />
</template>

<script>
import langObject from "@/new-menu/assets/json/langs.json";
import langObjectAuto from "@/new-menu/assets/json/auto.json";

import {adjustColorForContrast, isContrastEnoughForText, isTextColorDark} from "@/shared/helpers/ui";
import {useLanguageStore, useNotificationStore, useScriptStore} from "@/shared/stores";
import MenuHeader from "@/new-menu/components/MenuHeader.vue";
import MenuBody from "@/new-menu/components/MenuBody.vue";
import MenuFooter from "@/new-menu/components/MenuFooter.vue";
import BannerRoundedCorners from "@/new-menu/components/BannerRoundedCorners.vue";
import ImageZoomPopup from "@/new-menu/components/modals/ImageZoomPopup.vue";
import MenuView from "@/new-menu/views/MenuView.vue";
import {useCartStore, useEvsStore, useMenusStore, useTranslationStore} from "@/new-menu/stores";
import OrderContainer from "@/new-menu/components/order/OrderContainer.vue";
import ProductDetailsBottomSheet from "@/new-menu/components/modals/ProductDetailsBottomSheet.vue";
import API from "@/shared/helpers/API";
import AllergensFilter from "@/new-menu/components/modals/AllergensFilter.vue";
import NotificationManager from "@/new-menu/components/NotificationManager.vue";
import MenuPopup from "@/new-menu/components/generic/MenuPopup.vue";
import BannerCookie from "@/new-menu/components/modals/BannerCookie.vue";
import OrderCtas from "@/new-menu/components/order/OrderCtas.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {clusterizeData} from "@/new-menu/helpers/heatmap";

export default {
	setup() {
		const languageStore = useLanguageStore();
		const menusStore = useMenusStore();
		const translationStore = useTranslationStore();
		const notificationStore = useNotificationStore();
		const cartStore = useCartStore();
		const scriptStore = useScriptStore();
		const evsStore = useEvsStore();
		return {languageStore, cartStore, menusStore, translationStore, notificationStore, scriptStore, evsStore}
	},
	mixins: [WindowWidth],
	name: "App",
	components: {
		OrderCtas,
		BannerCookie,
		MenuPopup,
		AllergensFilter, OrderContainer, MenuView, ImageZoomPopup, BannerRoundedCorners, MenuFooter, MenuBody, MenuHeader, ProductDetailsBottomSheet, NotificationManager},
	data() {
		return {
			subdomain: subdomain,
		}
	},
	created() {

		let protocol = ((TEST && LOCAL) ? "http://" : "https://");
		API.init(protocol+window.location.host, URL_HTML+"/console/login/");

		this.languageStore.automaticTranslation = true;
		this.languageStore.addStrings(langObject, "menu", langObjectAuto);
		this.languageStore.setLang(null,() => {
			this.translationStore.selectLanguage();
		});
		this.menusStore.loadAllergensFilter();
		this.menusStore.loadSavedData();
		this.menusStore.performTimesCheck(() => {
			this.cartStore.loadCart();
		});
		this.cartStore.startOrdersQueuePolling();
		this.evsStore.initEvents();

		this.initStyle();
		document.addEventListener('dblclick', function(event) {
			event.preventDefault();
		}, { passive: false });

		checkLocalStorageSupport();

	},
	methods: {
		initStyle() {

			if(!isContrastEnoughForText(this.menusStore.business.color, this.menusStore.theme.bg_2)) {
				console.warn("Contrast between business color and theme is not enough for text readability, forcing business color to darker/lighter color.")
				this.menusStore.business.color = adjustColorForContrast(this.menusStore.business.color, this.menusStore.theme.bg_2);
			}

			let colorShadowLight = 'rgba(0,0,0,0.4)';
			let colorShadowStandard = 'rgba(0,0,0,0.6)';
			let colorShadowStrong = 'rgba(0,0,0,0.8)';
			if(this.menusStore.theme.light === "1") {
				colorShadowLight = 'rgba(0,0,0,0.15)';
				colorShadowStandard = 'rgba(0,0,0,0.25)';
				colorShadowStrong = 'rgba(0,0,0,0.4)';
			}

			let roundS = 6;
			let round = 13;
			let roundL = 26;
			switch (this.menusStore.business.options.round_level) {
				case "squared":
					roundS = 0;
					round = 0;
					roundL = 0;
					break;
				case "light":
					roundS = 4;
					round = 7;
					roundL = 13;
					break;
				default:
					break;
			}

			if(this.menusStore.business.options.read_mode) {
				if(localStorage.getItem("readMode") === "1") {
					this.menusStore.toggleReadMode(true);
				} else {
					this.menusStore.toggleReadMode(false);
				}
			} else {
				this.menusStore.toggleReadMode(false);
			}

			document.documentElement.style.setProperty('--rounded-corner', round+"px");
			document.documentElement.style.setProperty('--rounded-corner-s', roundS+"px");
			document.documentElement.style.setProperty('--rounded-corner-l', roundL+"px");
			document.documentElement.style.setProperty('--font-family', this.menusStore.fontFamily);
			document.documentElement.style.setProperty('--theme-bg1-color', "#"+this.menusStore.theme.bg_1);
			document.documentElement.style.setProperty('--theme-bg1-alpha80-color', "#"+this.menusStore.theme.bg_1+"CC");
			document.documentElement.style.setProperty('--theme-bg1-alpha40-color', "#"+this.menusStore.theme.bg_1+"64");
			document.documentElement.style.setProperty('--theme-bg2-color', "#"+this.menusStore.theme.bg_2);
			document.documentElement.style.setProperty('--theme-bg2-alpha40-color', "#"+this.menusStore.theme.bg_2+"64");
			document.documentElement.style.setProperty('--theme-txt1-color', "#"+this.menusStore.theme.txt_1);
			document.documentElement.style.setProperty('--theme-txt1-alpha20-color', "#"+this.menusStore.theme.txt_1+"32");
			document.documentElement.style.setProperty('--theme-txt1-alpha40-color', "#"+this.menusStore.theme.txt_1+"64");
			document.documentElement.style.setProperty('--theme-txt1-alpha60-color', "#"+this.menusStore.theme.txt_1+"96");
			document.documentElement.style.setProperty('--theme-txt1-alpha80-color', "#"+this.menusStore.theme.txt_1+"CC");
			document.documentElement.style.setProperty('--theme-txt1-alpha0-color', "#"+this.menusStore.theme.txt_1+"00");
			document.documentElement.style.setProperty('--theme-txt2-color', "#"+this.menusStore.theme.txt_2);
			document.documentElement.style.setProperty('--theme-txt2-alpha60-color', "#"+this.menusStore.theme.txt_2+"96");
			document.documentElement.style.setProperty('--theme-box-shadow-xlight', "3px 3px 7.5px -3px "+colorShadowLight);
			document.documentElement.style.setProperty('--theme-box-shadow-light', "4px 4px 10px -3px "+colorShadowLight);
			document.documentElement.style.setProperty('--theme-box-shadow', "4px 4px 10px -3px "+colorShadowStandard);
			document.documentElement.style.setProperty('--theme-box-shadow-strong', "4px 4px 10px -3px "+colorShadowStrong);
			document.documentElement.style.setProperty('--theme-box-shadow-null', "4px 4px 10px -3px var(--theme-txt1-alpha0-color)");
			document.documentElement.style.setProperty('--business-color', "#"+this.menusStore.business.color);
			document.documentElement.style.setProperty('--business-color-alpha20', "#"+this.menusStore.business.color+"32");
			document.documentElement.style.setProperty('--business-color-alpha40', "#"+this.menusStore.business.color+"64");
			document.documentElement.style.setProperty('--business-color-alpha60', "#"+this.menusStore.business.color+"96");
			document.documentElement.style.setProperty('--business-color-alpha80', "#"+this.menusStore.business.color+"CC");
			document.documentElement.style.setProperty('--business-text-color', isTextColorDark(this.menusStore.business.color) ? "#000000" : "#FFFFFF");
			document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#' + this.menusStore.theme.bg_1);
		},
		addMetas() {

			let data = this.updateMetas();
			let pageTitle = data.title;
			let menuWord = data.menuWord;

			let pageDesc = this.menusStore.business.description ? this.menusStore.business.descriptionTranslation : this.languageStore.getString('generic_page_description', [menuWord, this.menusStore.business.name]);
			let metaTags = [
				{name: "description", content: pageDesc},
				{property: "og:title", content: pageTitle},
				{property: "og:description", content: pageDesc},
				{name: "twitter:card", content: "summary"},
				{name: "twitter:description", content: pageDesc},
				{name: "robots", content: "index, follow"}
			];

			metaTags.forEach((tagData) => {
				let meta = document.querySelector("meta[property='"+tagData.property+"']");
				if(!meta) {
					meta = document.querySelector("meta[name='"+tagData.name+"']");
				}
				if(meta) {
					meta.setAttribute("content", tagData.content);
				} else {
					meta = document.createElement('meta');
					for (let key in tagData) {
						meta.setAttribute(key, tagData[key]);
					}
					document.head.appendChild(meta);
				}
			});

			if(this.languageStore.lang) {
				document.documentElement.lang = this.languageStore.lang;
			}

			if(this.menusStore.heatMap) {
				window.addEventListener("message", (message) => {
					if(message.data && message.data.hasOwnProperty("type") && message.data.type === "heatmap" && message.data.data) {
						this.menusStore.heatMapData = clusterizeData(message.data.data, 80);
					}
				}, false);
			}

		},
		updateMetas() {

			let menuWord = this.menusStore.business.type === 'other' ? this.languageStore.getString('catalog') : this.languageStore.getString('menu');
			let pageTitle = this.menusStore.business.name+" - "+menuWord;
			let canonical = window.location.protocol+"//"+window.location.host;
			let picture = this.menusStore.business.picture ? this.menusStore.business.picture : null;
			if(this.$route.params.menuId && this.menusStore.selectedMenu) {
				pageTitle = this.menusStore.business.name+" - "+this.menusStore.selectedMenu.name;
				canonical += "/"+this.$route.params.menuId+"/";
				picture = this.menusStore.selectedMenu.picture ? this.menusStore.selectedMenu.picture : picture;
				if(this.$route.params.itemSlug) {
					canonical += this.$route.params.itemSlug+"/";
					//TODO update picture and title for item
				}
			}
			document.title = pageTitle;

			let metaTagsToUpdate = [
				{property: "og:image", content: picture},
				{property: "og:url", content: canonical},
				{name: "twitter:title", content: pageTitle},
				{name: "twitter:image", content: picture},
			];

			metaTagsToUpdate.forEach((tagData) => {
				let meta = document.querySelector("meta[property='"+tagData.property+"']");
				if(!meta) {
					meta = document.querySelector("meta[name='"+tagData.name+"']");
				}
				if(meta) {
					meta.setAttribute("content", tagData.content);
				} else {
					meta = document.createElement('meta');
					for (let key in tagData) {
						meta.setAttribute(key, tagData[key]);
					}
					document.head.appendChild(meta);
				}
			});

			let link = document.querySelector('link[rel="canonical"]');
			if(link) {
				link.setAttribute("href", canonical);
			} else {
				link = document.createElement('link');
				link.setAttribute("rel", "canonical");
				link.setAttribute("href", canonical);
				document.head.appendChild(link);
			}

			return {
				title: pageTitle,
				menuWord: menuWord,
				canonical: canonical,
				picture: picture,
			}
		}
	},
	mounted() {
		this.addMetas();
	},
	watch: {
		$route(to, from) {
			setTimeout(() => {
				this.updateMetas();
			}, 100);
		},
		'languageStore.lang': {
			handler: function (val, oldVal) {
				if(val) {
					document.documentElement.lang = val;
				}
			},
			deep: true
		},
	}
}

function checkLocalStorageSupport() {
	let hasLocalStorageSupport;
	try {
		hasLocalStorageSupport = 'localStorage' in window && window.localStorage !== null;
		let testKey = 'storageTest';
		window.localStorage.setItem(testKey, 'foo');
		window.localStorage.removeItem(testKey);
	} catch (e) {
		hasLocalStorageSupport = false;
	}

	if (!hasLocalStorageSupport) {
		delete window.localStorage;
		window.localStorage = {};
		window.localStorage.items = {};
		window.localStorage.getItem = key => {
			return window.localStorage.items[key] || null;
		};
		window.localStorage.removeItem = key => {
			delete window.localStorage.items[key];
		};
		window.localStorage.setItem = (key, value) => {
			window.localStorage.items[key] = value;
		}
	}

	let hasSessionStorageSupport;
	try {
		hasSessionStorageSupport = 'sessionStorage' in window && window.sessionStorage !== null;
		let testKey = 'storageTest';
		window.sessionStorage.setItem(testKey, 'foo');
		window.sessionStorage.removeItem(testKey);
	} catch (e) {
		hasSessionStorageSupport = false;
	}

	if (!hasSessionStorageSupport) {
		delete window.sessionStorage;
		window.sessionStorage = {};
		window.sessionStorage.items = {};
		window.sessionStorage.getItem = key => {
			return window.sessionStorage.items[key] || null;
		};
		window.sessionStorage.removeItem = key => {
			delete window.sessionStorage.items[key];
		};
		window.sessionStorage.setItem = (key, value) => {
			window.sessionStorage.items[key] = value;
		}
	}
}

</script>


<style lang="scss">
:root {

	--padding-vertical: 12px;
	--padding-horizontal: 20px;
	--padding-horizontal-s: 12px;

	@media (max-width: 500px) {
		--padding-horizontal: 12px;
	}

}
button, [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
	background-color: transparent;
	background-image: none;
}
div, span, p, h1, h2, h3, h4, h5, h6, a, button, label, select {
	@include noSelect();
	font-family: var(--font-family) !important;
}
html, body {
	background-color: var(--theme-bg1-color) !important;
	@include hideScrollbar();
}
a {
	color: var(--theme-txt1-color) !important;

	&::after {
		background: var(--business-color) !important;
	}
}

p, span, h1, input, textarea, select, option, li, label, a, b, i, [type="button"], [type="reset"], [type="submit"] {

	&::selection {
		background: var(--business-color-alpha20);
	}

	&::-moz-selection {
		background: var(--business-color-alpha20);
	}

	&::placeholder {
		font-family: var(--font-family) !important;
	}

}

input, select, textarea, button {
	font-family: var(--font-family) !important;
}

img {
	font-size: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.grecaptcha-badge {
	display: none;
}

.heatmapOpacity {
	opacity: 0.3;
}
</style>

<style lang="scss" scoped>
main {
	width: 100%;
	position: relative;
	background-color: var(--theme-bg1-color);
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;

	&.menuOnly {
		justify-content: center;
	}

	.order-container {
		width: calc(100% / 3);
		max-width: 480px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		position: fixed;
		left: calc((100% / 3) * 2);

		@media (max-width: 1024px) {
			display: none;
			position: relative;
			left: 0;
		}
	}

	.order-container-spacer {
		width: calc((100% / 3));

		@media (max-width: 1024px) {
			display: none;
		}
	}

}
</style>