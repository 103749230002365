<template>
	<div class="item-container">
		<div class="item-container-header">
			<div class="item-name"><div class="quantity">x{{ item.quantity }}</div><p> {{ menuItem ? menuItem.nameTranslation : '' }}</p></div>
			<div class="item-data">
				<div class="item-price" v-if="!menusStore.business.options.hide_prices">{{ readableEurValue((getItemPrice(item)/100)*item.quantity) }}</div>
				<div class="item-actions"><MenuAdders @change="v => changeQuantity(item, v)" /></div>
			</div>
		</div>
		<div class="product-body" v-if="item.products || item.notes">
			<OrderItemProduct :product="product" v-for="product in item.products" :key="product.menu_item_list_product_id" :product-translation="getProductTranslation(product)" />
			<div class="notes" v-if="item.notes">
				<p><span class="notes-title">{{ getString("notes")+": " }}</span>{{ item.notes }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import MenuAdders from "@/new-menu/components/order/MenuAdders.vue";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore} from "@/shared/stores";
import OrderItemProduct from "@/new-menu/components/order/OrderItemProduct.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		return {cartStore, languageStore, menusStore, readableEurValue: languageStore.readableEurValue, getString: languageStore.getString};
	},
	name: "OrderItem",
	components: {OrderItemProduct, MenuAdders},
	props: {
		item: {
			type: Object,
			required: true,
		}
	},
	computed: {
		menuItem() {
			return this.menusStore.getItemById(this.item.menu_item_id);
		},
	},
	methods: {
		getItemPrice(item) {
			let price = item.price;
			if(item.products) {
				item.products.forEach(product => {
					price += product.product_price * product.quantity;
				});
			}
			return price;
		},
		getProductTranslation(product) {
			let menuItemProduct = null;
			for(let i = 0; i < this.menuItem.lists.length; i++) {
				let list = this.menuItem.lists[i];
				for(let j = 0; j < list.products.length; j++) {
					let p = list.products[j];
					if(p.menu_item_list_product_id === product.menu_item_list_product_id) {
						menuItemProduct = p;
						break;
					}
				}
			}
			return menuItemProduct ? menuItemProduct.product_nameTranslation : product.product_nameTranslation;
		},
		changeQuantity(item, v) {
			if(v > 0) {
				this.cartStore.addSpecificCartItem(item.temp_id, this.menuItem, this.menusStore.getProductCategory(this.menuItem), v);
			} else {
				this.cartStore.removeSpecificCartItem(item.temp_id);
			}
		},
	}
}
</script>

<style scoped lang="scss">
.item-container {
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	border-bottom: 1px solid var(--theme-bg2-color);

	.item-container-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.item-name {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			@include truncateString();

			.quantity {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--business-color);
				font-weight: 700;
				margin-right: 6px;
			}

			p {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--theme-txt1-color);
				@include truncateString();
				padding-right: 4px;
			}

		}

		.item-data {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			.item-price {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				margin-right: 8px;
			}
		}
	}

	.product-body {
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner-s);
		margin-top: 4px;
		display: flex;
		flex-direction: column;
		padding: 0 var(--padding-horizontal);

		.notes {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 6px 0;

			.notes-title {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				font-weight: 700;
			}

			p {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				@include truncateString();
			}
		}

	}

}
</style>